import { FC, useContext, useEffect, useState } from "react";
import {
  ServiceInterface,
  ServicePageInterface,
} from "../../../../../utils/interfaces/pages/app/set-up/service-page-interface";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SocketContext } from "../../../../../utils/hooks/context/socket-context-hooks";
import { UserContext } from "../../../../../utils/hooks/context/user-context-hook";
import { loaded } from "../../../../../utils/functions/loaded-function";
import { getSocketId } from "../../../../../utils/functions/upt-function";
import { redirect } from "../../../../../utils/functions/redirect";
import Pagination from "react-js-pagination";
import { useApiService } from "../../../../../utils/hooks/custom/api-with-token-http-hook";
import { blockUi, unBlockUi } from "../../../../../utils/functions/block-ui";
import {
  closeModal,
  notify,
} from "../../../../../utils/functions/notification";
import { ModalComponent } from "../../../../../components/shared/modal-component";
import { ActionComponent } from "../../../../../components/shared/action-component";

const Modal: FC<any> = ({ state, action }) => {
  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      <ActionComponent actionFunction={() => action()} text="Delete Service" />
    </ModalComponent>
  );
};

export const SubServicePage: FC<ServicePageInterface> = ({ callback }) => {
  const { replace } = useHistory(),
    { pathname } = useLocation(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { url } = useRouteMatch(),
    { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<ServicePageInterface>({
      loading: true,
      data: [],
      pageNumber: 1,
      records: 0,
      total: 0,
      text: "",
      searchText: "",
      timeOut: {},
    }),
    setState = (newState: ServicePageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = (pageNumber = 1) => {
      setState({ loading: true });
      get("sub-service", userState().token!, {
        filter: true,
        sort: "asc",
        sortName: "name",
        pageSize: 20,
        pageNumber,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
            {
              field: "name",
              option: "cn",
              type: "string",
              data: state.searchText,
            },
          ],
        },
      });
    },
    featured = (id: string, featured: boolean) => {
      blockUi();
      put(`sub-service/update/${id}`, userState().token!, {
        featured,
      })
        .then((res) => {
          unBlockUi();

          notify(res.type, `${res.message}`);

          if (res.type === "success") getData(state.pageNumber);
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    },
    action = () => {
      closeModal();
      blockUi();

      put(`sub-service/update/${`${state.service!._id}`}`, userState().token!, {
        deleted: state.service!.deleted,
      })
        .then((res) => {
          unBlockUi();
          notify(res.type, `${res.message}`);

          if (res.type === "success") getData();
        })
        .catch((err) => {
          unBlockUi();
          notify(err, `${err}`);
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Services" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.services?.canRead)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-sub-service`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.loading = false;
    }

    if (res.url.length > 0) setState(state);
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.service ? (
        <Modal state={JSON.parse(JSON.stringify(state))} action={action} />
      ) : (
        <></>
      )}

      <h4 className="h">
        Services
        <button
          type="button"
          className="btn btn-outline-secondary mb-2 ml-3"
          onClick={() => {
            state.searchText = "";

            setState(state);
            getData();
          }}
        >
          <i data-feather="refresh-cw" />
        </button>
        {userState().role?.admin || userState().role?.services?.canCreate ? (
          <Link
            to={`/app/sub-services/add`}
            type="button"
            className="btn btn-primary mb-2 ml-3"
          >
            <i data-feather="plus-circle" />
          </Link>
        ) : (
          <></>
        )}
      </h4>

      <input
        type="text"
        className="form-control mt-5"
        placeholder="search using service name"
        autoComplete="off"
        value={state.searchText}
        onChange={(e) => {
          if (typeof state.timeOut === "number") {
            clearTimeout(state.timeOut);
          }
          state.searchText = e.target.value;

          state.timeOut = setTimeout(() => {
            getData();
          }, 1000);

          setState(state);
        }}
      />

      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="table-responsive mt-3">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Name</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data!.filter((p: ServiceInterface) => !p.deleted)
                  .length > 0 ? (
                  state
                    .data!.filter((p: ServiceInterface) => !p.deleted)
                    .map((p: ServiceInterface, i) => (
                      <tr>
                        <td className="text-center">
                          {i + 1 + 20 * state.pageNumber! - 20}
                        </td>
                        <td id="td2">{p.name}</td>
                        <td className="text-center">
                          <ul className="table-controls">
                            <li>
                              <Link
                                to={`${url}/view-details/${p._id!}`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View Details"
                              >
                                <i data-feather="eye" />
                              </Link>
                            </li>
                            {userState().role?.admin ||
                            userState().role?.services?.canUpdate ? (
                              <>
                                <li>
                                  <Link
                                    to={`${url}/edit/${p._id!}`}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                  >
                                    <i data-feather="edit" />
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={
                                      p.featured ? "Not Featured" : "Featured"
                                    }
                                    onClick={() =>
                                      featured(p._id!, !p.featured!)
                                    }
                                  >
                                    <i
                                      data-feather={
                                        p.featured ? "check" : "slash"
                                      }
                                    />
                                  </a>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}

                            {userState().role?.admin ||
                            userState().role?.services?.canDelete ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      state.text = "Delete";
                                      state.service = {
                                        ...p,
                                        deleted: true,
                                      };

                                      setState(state);
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="trash" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div
            className="pagination-custom_outline d-none d-lg-block"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={10}
              onChange={(pageNumber: number) => getData(pageNumber)}
              prevPageText={<i data-feather="chevron-left" />}
              nextPageText={<i data-feather="chevron-right" />}
              firstPageText={<i data-feather="chevrons-left" />}
              lastPageText={<i data-feather="chevrons-right" />}
              itemClassFirst={"prev"}
              itemClassLast={"next"}
              itemClassNext={"next"}
              itemClassPrev={"prev"}
            />
          </div>
          <div
            className="pagination-custom_outline d-block d-lg-none"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={3}
              onChange={(pageNumber: number) => getData(pageNumber)}
            />
          </div>
        </>
      )}
    </div>
  );
};
