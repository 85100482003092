import { FC, useState } from "react";
import { VerifyEmailInterface } from "../../utils/interfaces/pages/auth/confirm-email-interface";
import { UseUserService } from "../../utils/hooks/custom/user";
import { notify } from "../../utils/functions/notification";
import { useHistory, Link, useLocation } from "react-router-dom";
import { parse } from "query-string";

export const ConfirmEmailPage: FC = () => {
  const { verifyEmail, verifyForgotPassword } = UseUserService(),
    { push } = useHistory(),
    { search, pathname } = useLocation(),
    { returnUrl } = parse(search),
    [togglePassword, setTogglePassword] = useState(false),
    [state, setActualState] = useState<VerifyEmailInterface>({
      code: "",
      email: "",
      password: "",
      text: "Confirm!",
      error: [],
    }),
    setState = (newState: VerifyEmailInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      state.error = [];

      if (state.code!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "code",
          msg: "Your code is required",
        });
      if (state.code!.replace(/ /g, "").length > 5)
        state.error.push({
          field: "code",
          msg: "Your code is NOT valid",
        });

      if (state.email!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "email",
          msg: "Your Email is required",
        });
      else if (
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (
        pathname.includes("forgot-password") &&
        state.password!.replace(/ /g, "").length === 0
      )
        state.error.push({
          field: "password",
          msg: "Your Password is required",
        });

      if (state.error.length === 0 && state.text === "Confirm!") {
        setState({ text: "Processing..." });
        if (pathname.includes("forgot-password")) {
          verifyForgotPassword(
            state.email!.trim(),
            state.code!.trim(),
            state.password!
          )
            .then((res) => {
              if (res.type === "error") {
                setState({ text: "Confirm!" });

                notify(res.type, `${res.message}`);
              } else {
                notify("success", `${res.message}`);
                push(`/sign-in${returnUrl ? `?${returnUrl}` : ""}`);
              }
            })
            .catch((err) => {
              setState({ text: "Confirm!" });

              notify(err, `${err}`);
            });
        } else {
          verifyEmail(state.email!.trim(), state.code!.trim())
            .then((res) => {
              if (res.type === "error") {
                setState({ text: "Confirm!" });

                notify(res.type, `${res.message}`);
              } else {
                notify("success", `${res.message}`);
                push(`/sign-in${returnUrl ? `?${returnUrl}` : ""}`);
              }
            })
            .catch((err) => {
              setState({ text: "Confirm!" });

              notify(err, `${err}`);
            });
        }
      } else if (state.error.length > 0) setState(state);
    };

  return (
    <div className="form">
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1 className="">Confirmation</h1>
                <p className="signup-link register">
                  Already have an account?{" "}
                  <Link to={`/sign-in${returnUrl ? `?${returnUrl}` : ""}`}>
                    sign in
                  </Link>
                </p>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <label htmlFor="code">Code</label>
                      <i data-feather="user" />
                      <input
                        name="code"
                        type="text"
                        className="form-control"
                        placeholder="Code"
                        value={state.code}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "code"
                            ),
                            code: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "code" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}
                    </div>

                    <div id="email-field" className="field-wrapper input">
                      <label htmlFor="email">EMAIL</label>
                      <i data-feather="at-sign" />
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={state.email}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "email"
                            ),
                            email: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "email" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}
                    </div>

                    {pathname.includes("password") ? (
                      <div
                        id="password-field"
                        className="field-wrapper input mb-2"
                      >
                        <div className="d-flex justify-content-between">
                          <label htmlFor="password">PASSWORD</label>
                        </div>
                        <i data-feather="lock" />
                        <input
                          id="password"
                          name="password"
                          autoComplete="off"
                          autoCapitalize="off"
                          type={!togglePassword ? "password" : "text"}
                          className="form-control"
                          placeholder="Password"
                          value={state.password}
                          onChange={(e) =>
                            setState({
                              error: state.error!.filter(
                                (e) => e.field !== "password"
                              ),
                              password: e.target.value,
                            })
                          }
                        />

                        {state.error!.map((e) => (
                          <div
                            className="invalid-feedback"
                            style={{
                              display:
                                e.field === "password" ? "block" : "none",
                            }}
                          >
                            {e.msg}
                          </div>
                        ))}

                        <div
                          id="toggle-password"
                          onClick={() => {
                            setTogglePassword(!togglePassword);
                          }}
                        >
                          <i data-feather="eye" />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => submit()}
                        >
                          {state.text !== "Confirm!" ? (
                            <div className="spinner-grow text-info align-self-center">
                              {state.text}
                            </div>
                          ) : (
                            <>{state.text}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
