import { FC } from "react";
import { closeModal } from "../../utils/functions/notification";
import { ActionComponentInterface } from "../../utils/interfaces/components/delete-component-interface";

export const ActionComponent: FC<ActionComponentInterface> = ({
  text,
  actionFunction,
}) => {
  return (
    <>
      <div className="modal-body">
        <div className="card component-card_1" style={{ width: "100%" }}>
          <div className="card-body">
            <div className="icon-svg">
              <i data-feather="info" />
            </div>
            <p className="card-text">
              Are you sure?{" "}
              {text === "Delete" ? "Note this cannot be on done" : ""}.
            </p>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className={`btn ${
            text === "Activate" ? "btn-success" : "btn-danger"
          }`}
          onClick={() => actionFunction()}
        >
          {text}
        </button>
      </div>
    </>
  );
};
