import { FC, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApiService } from "../utils/hooks/custom/api-with-token-http-hook";
import {
  UserComponentInterface,
  UserServicesComponentInterface,
} from "../utils/interfaces/pages/app/users/user-page-interface";
import { UserContext } from "../utils/hooks/context/user-context-hook";
import { closeModal, notify } from "../utils/functions/notification";
import { uploadFile } from "../utils/functions/firebase-file-upload";
import { ApiResponseInterface } from "../utils/interfaces/shared-interface";

export const UserComponent: FC<UserComponentInterface> = ({
  _id,
  firstName,
  lastName,
  email,
  password,
  businessAddress,
  phoneNumbers,
  documents,
  callback,
}) => {
  const { businessId } = useParams<any>(),
    { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<UserComponentInterface>({
      _id,
      firstName,
      lastName,
      email,
      password,
      businessAddress,
      phoneNumbers,
      documents,
      callback,
      docs: [],
      text: "Save",
      error: [],
    }),
    setState = (newState: UserComponentInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      state.error = [];

      if (
        state.firstName!.replace(/ /g, "").length === 0 ||
        !state.firstName!.match(/^[a-zA-Z\s]*$/)
      )
        state.error.push({
          field: "firstName",
          msg: "Name is required and can only contain alphabet a - z",
        });
      if (
        state.lastName!.replace(/ /g, "").length === 0 ||
        !state.lastName!.match(/^[a-zA-Z\s]*$/)
      )
        state.error.push({
          field: "lastName",
          msg: "Name is required and can only contain alphabet a - z",
        });

      if (
        state.email!.replace(/ /g, "").length > 0 &&
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (!_id) {
        if (state.password!.replace(/ /g, "").length === 0)
          state.error.push({
            field: "password",
            msg: "Your Password is required",
          });
        // else if (
        //   !state.password!.match(
        //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~]).{8,}$/
        //   ) // eslint-disable-line
        // )
        //   state.error.push({
        //     field: "password",
        //     msg: "Your password is not strong enough",
        //   });
      }

      if (state.phoneNumbers!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "phoneNumbers",
          msg: "Your phone number is required and you can add multiple separated comma(,)",
        });

      if (
        state.businessAddress!.replace(/ /g, "").length === 0 ||
        !state.businessAddress!.match(/^[0-9a-zA-Z\s]*$/)
      )
        state.error.push({
          field: "businessAddress",
          msg: "Address is required and can only contain alphabet a - z and numbers 0 - 9",
        });

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });

        const uploadedDocs: any[] = [],
          resolve = () => {
            if (uploadedDocs.length === state.docs!.length) {
              const data = {
                firstName: state.firstName,
                lastName: state.lastName,
                ...(!_id && {
                  password: state.password,
                }),
                businessAddress: state.businessAddress,
                phoneNumbers: state.phoneNumbers,
                ...(state.email!.replace(/ /g, "").length > 0 && {
                  email: state.email!.replace(/ /g, ""),
                }),
                documents: [...state.documents!, ...uploadedDocs],
              };

              if (_id)
                put(`user/update/${_id}`, userState().token!, data, null, false)
                  .then((res) => {
                    response(res);
                  })
                  .catch((err) => {
                    setState({ text: "Save" });

                    notify(err, `${err}`);
                  });
              else
                post(
                  `user/register/${businessId}`,
                  userState().token!,
                  data,
                  null,
                  false
                )
                  .then((res) => {
                    response(res);
                  })
                  .catch((err) => {
                    setState({ text: "Save" });

                    notify(err, `${err}`);
                  });
            }
          };

        if (state.docs!.length > 0)
          for (const _file of state.docs!) {
            const file: any = _file;
            uploadFile(file, ({ type, data }) => {
              if (type === "success") {
                uploadedDocs.push(data!);

                resolve();
              } else if (type === "error") {
                setState({ text: "Save" });
                notify("error", "Unable to upload files");
              }
            });
          }
        else resolve();
      } else if (state.error.length > 0) setState(state);
    },
    response = (res: ApiResponseInterface) => {
      if (res.type === "error") {
        setState({ text: "Save" });

        notify(res.type, `${res.message}`);

        if (
          res.message.includes("section has expired") ||
          res.message.toLowerCase().includes("access denied")
        ) {
          closeModal();
          setTimeout(() => {
            if (callback)
              callback({
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                businessAddress: state.businessAddress,
                phoneNumbers: state.phoneNumbers,
                documents: state.documents,
              });
          }, 100);
        }
      } else {
        notify(res.type, `${res.message}`);
        closeModal();
        if (callback) callback();
      }
    };

  useEffect(() => {
    if (document.getElementById("imagePreview"))
      new window.FileUploadWithPreview("imagePreview");
  }, []);

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={state.firstName}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "firstName"),
                  firstName: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "firstName" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={state.lastName}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "lastName"),
                  lastName: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "lastName" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          {!state._id || state.email!.includes("fake") ? (
            <div className="form-group">
              <label>Email (optional)</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={state.email}
                onChange={(e) =>
                  setState({
                    error: state.error!.filter((e) => e.field !== "email"),
                    email: e.target.value,
                  })
                }
              />

              {state.error!.map((e) => (
                <div
                  className="invalid-feedback"
                  style={{
                    display: e.field === "email" ? "block" : "none",
                  }}
                >
                  {e.msg}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="businessAddress"
              value={state.businessAddress}
              onChange={(e) =>
                setState({
                  error: state.error!.filter(
                    (e) => e.field !== "businessAddress"
                  ),
                  businessAddress: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "businessAddress" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>
              Phone Numbers (you can add multiple separating them with coma(,))
            </label>
            <input
              type="text"
              className="form-control"
              name="phoneNumbers"
              value={state.phoneNumbers}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "phoneNumbers"),
                  phoneNumbers: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "phoneNumbers" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          {!state._id ? (
            <div className="form-group">
              <label>Password</label>

              <input
                id="password"
                name="password"
                autoComplete="off"
                autoCapitalize="off"
                type="text"
                className="form-control"
                placeholder="Password"
                value={state.password}
                onChange={(e) =>
                  setState({
                    error: state.error!.filter((e) => e.field !== "password"),
                    password: e.target.value,
                  })
                }
              />

              {state.error!.map((e) => (
                <div
                  className="invalid-feedback"
                  style={{
                    display: e.field === "password" ? "block" : "none",
                  }}
                >
                  {e.msg}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          <div className="form-group">
            <div
              id="imagePreview"
              className="custom-file-container"
              data-upload-id="imagePreview"
            >
              <div className="row mb-3">
                {state.documents!.map((p) => (
                  <div className="col-lg-4">
                    <div
                      style={{
                        height: 150,
                        width: 150,
                        backgroundImage: `url("${p})"`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />

                    <br />

                    <button
                      className="btn btn-sm btn-danger mt-3 mb-3"
                      onClick={() =>
                        setState({
                          documents: state.documents!.filter((_p) => p !== _p),
                        })
                      }
                    >
                      <i data-feather="trash" />
                    </button>
                  </div>
                ))}
              </div>
              <label>
                Staff Documents <br /> (optional - You Can Upload Multiple)
                <a
                  href="javascript:void(0)"
                  className="custom-file-container__image-clear btn btn-outline-success btn-sm ml-3"
                  title="Clear Image"
                >
                  <i data-feather="x-octagon" />
                </a>
              </label>
              <label className="custom-file-container__custom-file">
                <input
                  type="file"
                  accept="image/*"
                  className="custom-file-container__custom-file__custom-file-input"
                  multiple
                  onChange={(e) => {
                    if (e.target.files) state.docs = e.target.files;
                    setState(state);
                  }}
                />
                <span className="custom-file-container__custom-file__custom-file-control"></span>
              </label>
              <div className="custom-file-container__image-preview"></div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};

export const UserServicesComponent: FC<UserServicesComponentInterface> = ({
  _id,
  services,
  allServices,
  callback,
}) => {
  const { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<UserServicesComponentInterface>({
      _id,
      services,
      allServices,
      text: "Save",
    }),
    setState = (newState: UserComponentInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      if (state.text === "Save") {
        setState({ text: "Processing..." });

        const services: any[] = [];

        const d: any = document,
          options = d.getElementById("service")?.options;

        for (const o of options) if (o.selected) services!.push(o.value);

        put(
          `user/update/${_id}`,
          userState().token!,
          {
            services: services?.map((serviceId) => {
              return {
                serviceId,
              };
            }),
          },
          null,
          false
        )
          .then((res) => {
            if (res.type === "error") {
              setState({ text: "Save" });

              notify(res.type, `${res.message}`);

              if (
                res.message.includes("section has expired") ||
                res.message.toLowerCase().includes("access denied")
              ) {
                closeModal();
                setTimeout(() => {
                  if (callback)
                    callback({
                      services: services.map((s) => {
                        return allServices!.find((as) => as._id === s)!;
                      }),
                    });
                }, 100);
              }
            } else {
              notify(res.type, `${res.message}`);
              closeModal();
              if (callback) callback();
            }
          })
          .catch((err) => {
            setState({ text: "Save" });

            notify(err, `${err}`);
          });
      }
    };

  useEffect(() => {
    if (document.getElementById("service")) {
      window.$("#service").select2();
    }
  }, []);

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Select Services You Offer</label>
            <select id="service" className="form-control" multiple={true}>
              {state.allServices?.map((s) => (
                <option
                  value={s._id}
                  selected={
                    state.services!.find((ss) => ss._id === s._id)
                      ? true
                      : false
                  }
                >
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};

export const UserOrganizationComponent: FC<any> = ({
  _id,
  organizations,
  allOrganizations,
  callback,
}) => {
  const { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<any>({
      _id,
      organizations,
      allOrganizations,
      text: "Save",
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      if (state.text === "Save") {
        setState({ text: "Processing..." });

        const organizations: any[] = [];

        const d: any = document,
          options = d.getElementById("organization")?.options;

        for (const o of options) if (o.selected) organizations!.push(o.value);

        put(
          `user/update/${_id}`,
          userState().token!,
          {
            organizations: organizations?.map((organizationId: any) => {
              return {
                organizationId,
              };
            }),
          },
          null,
          false
        )
          .then((res) => {
            if (res.type === "error") {
              setState({ text: "Save" });

              notify(res.type, `${res.message}`);

              if (
                res.message.includes("section has expired") ||
                res.message.toLowerCase().includes("access denied")
              ) {
                closeModal();
                setTimeout(() => {
                  if (callback)
                    callback({
                      organizations: organizations.map((o) => {
                        return allOrganizations.find(
                          (al: any) => al._id === o
                        )!;
                      }),
                    });
                }, 100);
              }
            } else {
              notify(res.type, `${res.message}`);
              closeModal();
              if (callback) callback();
            }
          })
          .catch((err) => {
            setState({ text: "Save" });

            notify(err, `${err}`);
          });
      }
    };

  useEffect(() => {
    if (document.getElementById("organization")) {
      window.$("#organization").select2();
    }
  }, []);

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>
              Select Organizations You Belong To
              <br />
              {userState().role?.admin ||
              userState().role?.organizations.canCreate ? (
                <Link onClick={() => closeModal()} to="/app/organization">
                  (click here to add new organization if you not here)
                </Link>
              ) : (
                <></>
              )}
            </label>
            <select id="organization" className="form-control" multiple={true}>
              {state.allOrganizations?.map((o: any) => (
                <option
                  value={o._id}
                  selected={
                    state.organizations!.find((oo: any) => oo._id === o._id)
                      ? true
                      : false
                  }
                >
                  {o.name}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};
