import { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { SignUpPage } from "./auth/sign-up";
import { SignInPage } from "./auth/sign-in";
import { ConfirmEmailPage } from "./auth/confirm-email";
import { VerifyEmailPage } from "./auth/verify-email";
import { Helmet } from "react-helmet";
import { PageNotFound } from "./page-not-found";
import { AppRoute } from "./app/index";

const $ = window.$;

export const Index = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const sBar = document.getElementsByClassName("sbar-open")[0];

    if (sBar) {
      $(".main-container").toggleClass("sidebar-closed");
      $(".header.navbar").toggleClass("expand-header");
      $(".main-container").toggleClass("sbar-open");
      $(".overlay").toggleClass("show");
      $("html,body").toggleClass("sidebar-noneoverflow");
    }
  }, [pathname]);

  return (
    <>
      {pathname.includes("page-not-found") ? (
        <Helmet>
          <link
            href={`${process.env.PUBLIC_URL}/assets/css/pages/error/style-400.css`}
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>
      ) : (
        <></>
      )}

      <Switch>
        <Route exact path="/" component={SignUpPage} />
        <Route exact path="/sign-in" component={SignInPage} />
        <Route exact path="/verify-email" component={VerifyEmailPage} />
        <Route
          exact
          path="/verify-email-for-forgot-password"
          component={VerifyEmailPage}
        />
        <Route exact path="/confirm-email" component={ConfirmEmailPage} />
        <Route
          exact
          path="/confirm-email-for-forgot-password"
          component={ConfirmEmailPage}
        />
        <Route path="/app" component={AppRoute} />
        <Route path="/page-not-found" component={PageNotFound} />
        <Redirect to="/page-not-found" exact />
      </Switch>
    </>
  );
};
