import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { notify } from "../../../utils/functions/notification";
import { redirect } from "../../../utils/functions/redirect";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";

export const SettingsPage: FC<any> = ({ callback }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { post } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState({
      loading: true,
      charge: "0",
      percent: "0",
      address: "",
      accountName: "",
      accountNumber: "",
      bankName: "",
      branchCode: "",
      swiftCode: "",
      supportEmail: "",
      supportPhoneNumber: "",
      about: "",
      terms: "",
      privacy: "",
      faqs: "",
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      get("settings/id", userState().token!, null, "1");
    },
    submit = () => {
      blockUi();

      post(`settings/add`, userState().token!, {
        charge: isNaN(parseFloat(state.charge!)) ? 0 : parseFloat(state.charge),
        percent: isNaN(parseFloat(state.percent!))
          ? 0
          : parseFloat(state.percent),
        address: state.address,
        accountNumber: state.accountNumber,
        accountName: state.accountName,
        bankName: state.bankName,
        branchCode: state.branchCode,
        swiftCode: state.swiftCode,
        supportEmail: state.supportEmail,
        supportPhoneNumber: state.supportPhoneNumber,
        about: `${
          document.getElementsByClassName("ql-editor")[0]
            ? `${document.getElementsByClassName("ql-editor")[0].innerHTML}`
            : ""
        }`,
        terms: `${
          document.getElementsByClassName("ql-editor")[1]
            ? `${document.getElementsByClassName("ql-editor")[1].innerHTML}`
            : ""
        }`,
        privacy: `${
          document.getElementsByClassName("ql-editor")[2]
            ? `${document.getElementsByClassName("ql-editor")[2].innerHTML}`
            : ""
        }`,
        faqs: `${
          document.getElementsByClassName("ql-editor")[3]
            ? `${document.getElementsByClassName("ql-editor")[3].innerHTML}`
            : ""
        }`,
      })
        .then((res) => {
          unBlockUi();

          notify(res.type, `${res.message}`);

          if (
            res?.message?.includes("section has expired") ||
            res?.message?.toLowerCase().includes("access denied")
          ) {
            redirect(replace, pathname);
          }
        })
        .catch((err) => {
          setState({ text: "Save" });
          unBlockUi();
          notify(err, `${err}`);
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Settings" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.settings) {
        getData();
      } else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (!state.loading)
      for (const e of document.getElementsByClassName("ql-toolbar")) e.remove();

    if (document.getElementById("about"))
      new window.Quill("#about", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blocksubmission"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "About Us goes here...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("terms"))
      new window.Quill("#terms", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blocksubmission"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Terms & Conditions goes here...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("terms"))
      new window.Quill("#privacy", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blocksubmission"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Privacy Policy goes here...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("faqs"))
      new window.Quill("#faqs", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blocksubmission"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Faqs goes here...",
        theme: "snow", // or 'bubble'
      });
  }, [state.loading]);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-settings/1`) {
      setState({
        ...state,
        ...res.data,
        loading: false,
      });
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      <h4 className="h"> Settings</h4>
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="form-group">
            <label>Whats Your Fixed Transaction Fee</label>
            <input
              type="text"
              className="form-control"
              value={state.charge}
              onChange={(e) => {
                state.charge = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Percentage (%) Transaction Fee</label>
            <input
              type="text"
              className="form-control"
              value={state.percent}
              onChange={(e) => {
                state.percent = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Address</label>
            <input
              type="text"
              className="form-control"
              value={state.address}
              onChange={(e) => {
                state.address = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Account Name</label>
            <input
              type="text"
              className="form-control"
              value={state.accountName}
              onChange={(e) => {
                state.accountName = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Account Number</label>
            <input
              type="text"
              className="form-control"
              value={state.accountNumber}
              onChange={(e) => {
                state.accountNumber = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Bank Name</label>
            <input
              type="text"
              className="form-control"
              value={state.bankName}
              onChange={(e) => {
                state.bankName = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Branch Code</label>
            <input
              type="text"
              className="form-control"
              value={state.branchCode}
              onChange={(e) => {
                state.branchCode = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Swift Code</label>
            <input
              type="text"
              className="form-control"
              value={state.swiftCode}
              onChange={(e) => {
                state.swiftCode = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Support Email</label>
            <input
              type="text"
              className="form-control"
              value={state.supportEmail}
              onChange={(e) => {
                state.supportEmail = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>Whats Your Company's Support Phone Number</label>
            <input
              type="text"
              className="form-control"
              value={state.supportPhoneNumber}
              onChange={(e) => {
                state.supportPhoneNumber = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group">
            <label>About US</label>
            <div
              id="about"
              dangerouslySetInnerHTML={{ __html: state.about! }}
            />
          </div>

          <div className="form-group">
            <label>Terms & Conditions</label>
            <div
              id="terms"
              dangerouslySetInnerHTML={{ __html: state.terms! }}
            />
          </div>

          <div className="form-group">
            <label>Privacy Policy</label>
            <div
              id="privacy"
              dangerouslySetInnerHTML={{ __html: state.privacy! }}
            />
          </div>

          <div className="form-group">
            <label>Faqs</label>
            <div id="faqs" dangerouslySetInnerHTML={{ __html: state.faqs! }} />
          </div>

          <button
            type="button"
            className="btn btn-primary mt-5"
            onClick={() => submit()}
          >
            <i data-feather="check-circle" />
          </button>
        </>
      )}
    </div>
  );
};
