import { FC, useContext, useEffect, useState } from "react";
import {
  CityComponentInterface,
  CityPageInterface,
} from "../../../utils/interfaces/pages/app/set-up/city-page-interface";
import { useLocation, useHistory } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { redirect } from "../../../utils/functions/redirect";
import { CityComponent } from "../../../components/city-component";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import Pagination from "react-js-pagination";

const Modal: FC<any> = ({ state, setState, getData, action }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory();

  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      {state.text === "Delete City" ||
      state.text === "Suspend City" ||
      state.text === "Activate City" ? (
        <ActionComponent
          actionFunction={() => action()}
          text={state.text.split(" ")[0]}
        />
      ) : (
        <CityComponent
          {...state.city}
          callback={(city?: CityComponentInterface) => {
            if (city) {
              redirect(replace, pathname);
            } else {
              setState({ loading: true });
              getData();
            }
          }}
        />
      )}
    </ModalComponent>
  );
};

export const CityPage: FC<CityPageInterface> = ({ callback }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<CityPageInterface>({
      loading: true,
      data: [],
      city: undefined,
      states: [],
      countries: [],
      stateId: "",
      countryId: "",
      text: undefined,
      searchText: "",
      timeOut: {},
    }),
    setState = (newState: CityPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = (pageNumber = 1) => {
      setState({ loading: true });

      get(
        "city",
        userState().token!,
        {
          filter: true,
          sort: "asc",
          sortName: "name",
          pageSize: 20,
          pageNumber,
          searchFilters: {
            searchOption: "AND",
            rules: [
              {
                field: "countryId",
                option: "cn",
                type: "string",
                data: state.countryId,
              },
              {
                field: "name",
                option: "cn",
                type: "string",
                data: state.searchText,
              },
              {
                field: "stateId",
                option: "cn",
                type: "string",
                data: state.stateId,
              },
              {
                field: "deleted",
                option: "eq",
                type: "boolean",
                data: false,
              },
            ],
          },
        },
        null,
        false
      );
    },
    action = () => {
      closeModal();

      blockUi();
      put(`city/update/${state.city!._id}`, userState().token!, {
        ...(typeof state.city!.deleted !== "undefined" &&
          (state.city!.deleted! === true || state.city!.deleted! === false) && {
            deleted: state.city!.deleted,
          }),
      })
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();

            window.$("#modal").modal("show");

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            ) {
              closeModal();
              redirect(replace, pathname);
            }
          } else {
            notify("success", `${res.message}`);

            unBlockUi();
            getData();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "City" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.city?.canRead)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }

      getData();
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-city`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.countries = res.data.countries;
      state.states = res.data.states;
      state.loading = false;

      setState(state);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <>
      {state.text ? (
        <Modal
          state={JSON.parse(JSON.stringify(state))}
          setState={setState}
          getData={getData}
          action={action}
        />
      ) : (
        <></>
      )}

      <div className="container pt-5 pb-5">
        <h4 className="h">
          Cities
          <button
            type="button"
            className="btn btn-outline-secondary mb-2 ml-3"
            onClick={() => {
              state.searchText = "";

              setState(state);

              getData();
            }}
          >
            <i data-feather="refresh-cw" />
          </button>
          {!state.loading &&
          (userState().role?.admin || userState().role?.city?.canCreate) ? (
            <button
              type="button"
              className="btn btn-primary mb-2 ml-3"
              onClick={() => {
                setState({ text: undefined });
                setTimeout(() => {
                  state.text = "Add";
                  state.city = {
                    name: "",
                    stateId: "",
                    slug: "",
                    states: state.states,
                  };

                  setState(state);

                  setTimeout(() => {
                    window.$("#modal").modal("show");
                  }, 100);
                }, 100);
              }}
            >
              <i data-feather="plus-circle" />
            </button>
          ) : (
            <></>
          )}
        </h4>

        <div className="row mt-5">
          <div className="mb-3 col-lg-6">
            <input
              type="text"
              className="form-control"
              placeholder="search..."
              autoComplete="off"
              value={state.searchText}
              onChange={(e) => {
                if (typeof state.timeOut === "number") {
                  clearTimeout(state.timeOut);
                }
                state.searchText = e.target.value;

                state.timeOut = setTimeout(() => {
                  getData();
                }, 1000);

                setState(state);
              }}
            />
          </div>
          <div className="mb-3 col-lg">
            <select
              className="form-control"
              onChange={(e) => {
                state.countryId = e.target.value;
                setState(state);

                getData();
              }}
            >
              <option value="" selected={state.countryId === ""}>
                Choose...
              </option>
              {state.countries!.map((s) => (
                <option
                  selected={state.countryId!.includes(s._id!)}
                  value={s._id}
                >
                  {s.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 col-lg">
            <select
              className="form-control"
              onChange={(e) => {
                state.stateId = e.target.value;
                setState(state);

                getData();
              }}
            >
              <option value="" selected={state.stateId === ""}>
                Choose...
              </option>
              {state.states!.map((s) => (
                <option
                  selected={state.stateId!.includes(s._id!)}
                  value={s._id}
                >
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {state.loading ? (
          <div className="d-flex justify-content-center mx-5 mt-3">
            <div className="spinner-grow text-info align-self-center loader-lg" />
          </div>
        ) : (
          <div className="table-responsive mt-5">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data!.length > 0 ? (
                  state.data!.map((c, i) => (
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td id="td2">{c.name}</td>
                      <td id="td2">{JSON.parse(c.stateId!).name}</td>
                      <td id="td2">{JSON.parse(c.countryId!).name}</td>
                      {userState().role?.admin ||
                      userState().role?.city?.canUpdate ||
                      userState().role?.city?.canDelete ? (
                        <td id="td" className="text-center">
                          <ul className="table-controls">
                            {userState().role?.city?.canUpdate ||
                            userState().role?.admin ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      setState({
                                        text: "Edit City",
                                        city: { ...c, states: state.states },
                                      });
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="edit" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}

                            {userState().role?.city?.canDelete ||
                            userState().role?.admin ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      state.text = "Delete City";
                                      state.city = {
                                        ...c,
                                        deleted: true,
                                      };

                                      setState(state);
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="trash" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div
          className="pagination-custom_outline d-none d-lg-block"
          style={{ justifyContent: "left" }}
        >
          <Pagination
            activePage={state.pageNumber!}
            itemsCountPerPage={20}
            totalItemsCount={state.records!}
            pageRangeDisplayed={10}
            onChange={(pageNumber: number) => getData(pageNumber)}
            prevPageText={<i data-feather="chevron-left" />}
            nextPageText={<i data-feather="chevron-right" />}
            firstPageText={<i data-feather="chevrons-left" />}
            lastPageText={<i data-feather="chevrons-right" />}
            itemClassFirst={"prev"}
            itemClassLast={"next"}
            itemClassNext={"next"}
            itemClassPrev={"prev"}
          />
        </div>
        <div
          className="pagination-custom_outline d-block d-lg-none"
          style={{ justifyContent: "left" }}
        >
          <Pagination
            activePage={state.pageNumber!}
            itemsCountPerPage={20}
            totalItemsCount={state.records!}
            pageRangeDisplayed={3}
            onChange={(pageNumber: number) => getData(pageNumber)}
          />
        </div>
      </div>
    </>
  );
};
