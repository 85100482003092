import { useState, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { UserContext } from "../context/user-context-hook";
import {
  ApiResponseInterface,
  FilterInterface,
} from "../../interfaces/shared-interface";
import { UserInterface } from "../../interfaces/user-interface";
import { uPT } from "../../functions/upt-function";

const AUTHENTICATION_API_URL = `${BASE_URL}/auth`;

export const UseUserService = () => {
  const { userState } = useContext(UserContext);
  const [_response, setResponse] = useState<ApiResponseInterface>({
    data: {},
    type: "",
    message: "",
  });

  const register = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });
    await axios
      .post(`${BASE_URL}/user/register/`, {
        firstName,
        lastName,
        email,
        password,
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const verifyEmail = async (email: string, code: string) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });
    await axios
      .put(`${BASE_URL}/user/verify-user-with-code/`, {
        email,
        code,
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const resendCodeForVerifyEmail = async (email: string) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .post(`${BASE_URL}/user/verify-user-with-code/`, {
        email,
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const login = async (email: string, password: string, params?: any) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .post(
        `${AUTHENTICATION_API_URL}/login`,
        {
          email,
          password,
        },
        {
          ...(params && {
            params,
          }),
        }
      )
      .then((response) => {
        uPT(response, userState());

        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const geAllUser = async (token: string, filter: FilterInterface) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    if (filter.filter) {
      JSON.stringify(filter.searchFilters);
    }

    await axios
      .get(`${BASE_URL}/user/`, {
        params: filter,
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const getUser = async (id: string, token: string) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .get(`${BASE_URL}/user/${id}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((response) => {
        uPT(response, userState());
        _response.data.token = response.data.token;
        _response.data.user = response.data.user;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const forgotPassword = async (email: string) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .post(`${BASE_URL}/user/forgot-password-with-code/`, {
        email,
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";
      })
      .catch((error) => {
        _response.type = "error";
        _response.message = error.response.data.message;
        _response.data = error.response.data.data;
      });

    return _response;
  };

  const verifyForgotPassword = async (
    email: string,
    code: string,
    password: string
  ) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .put(`${BASE_URL}/user/forgot-password-with-code/`, {
        email,
        code,
        password,
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";
      })
      .catch((error) => {
        _response.type = "error";
        _response.message = error.response.data.message;
        _response.data = error.response.data.data;
      });

    return _response;
  };

  const updateEmail = async (email: string) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .post(`${BASE_URL}/user/update-email/`, {
        email,
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.user;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  const update = async (token: string, _id: string, user: UserInterface) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });
    let data = user;

    delete data._id;

    await axios
      .put(`${BASE_URL}/user/update/${_id}`, data, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((response) => {
        uPT(response, userState());
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });
    return _response;
  };

  return {
    register,
    verifyEmail,
    resendCodeForVerifyEmail,
    login,
    geAllUser,
    getUser,
    forgotPassword,
    verifyForgotPassword,
    updateEmail,
    update,
  };
};
