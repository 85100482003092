import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { FileUploadCallbackInterface } from "../interfaces/firebase-file-upload-interface";
import { firebaseApp } from "../../App";
import imageCompression from "browser-image-compression";

export const uploadFile = async (
  file: File,
  callback: FileUploadCallbackInterface,
  compress = true
) => {
  const storage = getStorage(firebaseApp),
    storageRef = ref(storage, "files/" + file.name),
    uploadTask = uploadBytesResumable(
      storageRef,
      compress
        ? await imageCompression(file, {
            maxSizeMB: 0.05,
            maxWidthOrHeight: 512,
            useWebWorker: true,
          })
        : file
    );

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      callback({
        progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      });
      switch (snapshot.state) {
        case "paused":
          callback({ paused: true, canceled: false, running: false });
          break;
        case "running":
          callback({ running: true, paused: false, canceled: false });
          break;
      }
    },
    (error) => {
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          callback({ type: "error", message: "unauthorized" });
          break;
        case "storage/canceled":
          callback({ canceled: true, paused: false, running: false });
          break;

        case "storage/unknown":
          callback({ type: "error", message: "unknown error" });
          break;
      }
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        callback({
          type: "success",
          data: downloadURL,
        });
      });
    }
  );
};
