import { FC, useContext, useEffect, useState } from "react";
import { useApiService } from "../utils/hooks/custom/api-with-token-http-hook";
import { TransactionComponentInterface } from "../utils/interfaces/pages/app/transaction/transaction-interface";
import { ApiResponseInterface } from "../utils/interfaces/shared-interface";
import { UserContext } from "../utils/hooks/context/user-context-hook";
import { closeModal, notify } from "../utils/functions/notification";

export const TransactionComponent: FC<TransactionComponentInterface> = ({
  _id,
  userId,
  invoiceId,
  quoteId,
  submissionId,
  amount,
  balance,
  callback,
}) => {
  const { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<TransactionComponentInterface>({
      _id,
      userId,
      invoiceId,
      quoteId,
      submissionId,
      amount,
      balance,
      text: "Save",
      error: [],
    }),
    setState = (newState: TransactionComponentInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    resolve = (res: ApiResponseInterface) => {
      if (res.type === "error") {
        setState({ text: "Save" });

        notify(res.type, `${res.message}`);

        if (
          res.message.includes("section has expired") ||
          res.message.toLowerCase().includes("access denied")
        ) {
          closeModal();
          setTimeout(() => {
            if (callback)
              callback({
                _id: state._id,
                userId: state.userId,
                invoiceId: state.invoiceId,
                quoteId: state.quoteId,
                submissionId: state.submissionId,
                amount: state.amount,
              });
          }, 100);
        }
      } else {
        notify(res.type, `${res.message}`);
        closeModal();
        if (callback) callback();
      }
    },
    submit = () => {
      state.error = [];

      if (isNaN(parseFloat(state.amount!)))
        state.error.push({
          field: "amount",
          msg: "Amount is required.",
        });

      if (parseFloat(state.amount!) > state.balance! && !_id)
        state.error.push({
          field: "amount",
          msg: `Amount cannot be greater than ${state.balance}.`,
        });

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });

        const data = {
          ...(!_id && {
            userId: state.userId,
            quoteId: state.quoteId,
            invoiceId: state.invoiceId,
            submissionId: state.submissionId,
            paid: true,
          }),
          amount: state.amount,
        };

        if (!_id)
          post(`transaction/add`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
        else
          put(`transaction/update/${_id}`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
      } else if (state.error.length > 0) setState(state);
    };

  useEffect(() => {
    if (document.getElementById("imagePreview"))
      new window.FileUploadWithPreview("imagePreview");
  }, []);

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="text"
              className="form-control"
              name="amount"
              value={state.amount}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "amount"),
                  amount: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "amount" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};
