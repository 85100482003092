import { FC, useState } from "react";
import { VerifyEmailInterface } from "../../utils/interfaces/pages/auth/verify-email-interface";
import { UseUserService } from "../../utils/hooks/custom/user";
import { notify } from "../../utils/functions/notification";
import { useHistory, Link, useLocation } from "react-router-dom";
import { parse } from "query-string";

export const VerifyEmailPage: FC = () => {
  const { resendCodeForVerifyEmail } = UseUserService(),
    { push } = useHistory(),
    { search, pathname } = useLocation(),
    { returnUrl } = parse(search),
    [state, setActualState] = useState<VerifyEmailInterface>({
      email: "",
      text: "Verify!",
      error: [],
    }),
    setState = (newState: VerifyEmailInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      state.error = [];

      if (state.email!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "email",
          msg: "Your Email is required",
        });
      else if (
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (state.error.length === 0 && state.text === "Verify!") {
        setState({ text: "Processing..." });
        resendCodeForVerifyEmail(state.email!.trim())
          .then((res) => {
            if (res.type === "error") {
              setState({ text: "Verify!" });

              notify(res.type, `${res.message}`);
            } else {
              notify("success", `${res.message}`);
              push(
                `${
                  pathname.includes("forgot-password")
                    ? `/confirm-email-for-forgot-password${
                        returnUrl ? `?${returnUrl}` : ""
                      }`
                    : `/confirm-email${returnUrl ? `?${returnUrl}` : ""}`
                }`
              );
            }
          })
          .catch((err) => {
            setState({ text: "Verify!" });

            notify(err, `${err}`);
          });
      } else if (state.error.length > 0) setState(state);
    };

  return (
    <div className="form">
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1 className="">Verify Email</h1>
                <p className="signup-link register">
                  <Link to={`/sign-in${returnUrl ? `?${returnUrl}` : ""}`}>
                    sign in
                  </Link>
                </p>
                <form className="text-left">
                  <div className="form">
                    <div id="email-field" className="field-wrapper input">
                      <label htmlFor="email">EMAIL</label>
                      <i data-feather="at-sign" />
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={state.email}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "email"
                            ),
                            email: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "email" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}
                    </div>

                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => submit()}
                        >
                          {state.text !== "Verify!" ? (
                            <div className="spinner-grow text-info align-self-center">
                              {state.text}
                            </div>
                          ) : (
                            <>{state.text}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
