import { FC, useContext, useEffect, useState } from "react";
import { SignInInterface } from "../../utils/interfaces/pages/auth/auth-interface";
import { UseUserService } from "../../utils/hooks/custom/user";
import { notify } from "../../utils/functions/notification";
import { Link, useLocation } from "react-router-dom";
import {
  defaultUser,
  UserContext,
} from "../../utils/hooks/context/user-context-hook";
import { capitalizeFirstLetter } from "../../utils/functions/capitalize-text";
import { parse } from "query-string";

export const SignInPage: FC = () => {
  const { changeUserState } = useContext(UserContext),
    { login } = UseUserService(),
    { search } = useLocation(),
    { returnUrl } = parse(search),
    [togglePassword, setTogglePassword] = useState(false),
    [state, setActualState] = useState<SignInInterface>({
      email: "",
      password: "",
      text: "Done!",
      error: [],
    }),
    setState = (newState: SignInInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      state.error = [];

      if (state.email!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "email",
          msg: "Your Email is required",
        });
      else if (
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (state.password!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "password",
          msg: "Your Password is required",
        });
      // else if (
      //   !state.password?.match(
      //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~]).{8,}$/
      //   ) // eslint-disable-line
      // )
      //   state.error.push({
      //     field: "password",
      //     msg: "Your password is not strong enough",
      //   });

      if (state.error.length === 0 && state.text === "Done!") {
        setState({ text: "Processing" });
        login(state.email!.trim(), state.password!)
          .then((res) => {
            if (res.type === "error") {
              setState({ text: "Done!" });

              notify(res.type, `${res.message}`);
            } else {
              delete res.data.user.expi;
              delete res.data.user.iat;
              delete res.data.user.exp;

              notify(
                "success",
                `Welcome ${capitalizeFirstLetter(
                  `${res.data.user.firstName} ${res.data.user.lastName}`
                )}`
              );

              changeUserState({ ...res.data.user, token: res.data.token });
              setState({ text: "Done!" });

              window.location.replace(
                returnUrl && typeof returnUrl === "string"
                  ? `${returnUrl}`
                  : res.data.user.role?.dashboard || res.data.user.role?.admin
                  ? `/app/dashboard`
                  : `/app/users/${res.data.user._id}`
              );
            }
          })
          .catch((err) => {
            setState({ text: "Done!" });

            notify(err, `${err}`);
          });
      } else if (state.error.length > 0) setState(state);
    };

  useEffect(() => changeUserState(defaultUser), []);

  return (
    <div className="form">
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1 className="">Sign In</h1>
                <p className="signup-link register">
                  Don't have an account? <Link to="/">sign up</Link>
                </p>
                <form className="text-left">
                  <div className="form">
                    <div id="email-field" className="field-wrapper input">
                      <label htmlFor="email">EMAIL</label>
                      <i data-feather="at-sign" />
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={state.email}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "email"
                            ),
                            email: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "email" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}
                    </div>

                    <div
                      id="password-field"
                      className="field-wrapper input mb-2"
                    >
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password">PASSWORD</label>
                        <Link
                          to={`/verify-email-for-forgot-password${
                            returnUrl ? `?${returnUrl}` : ""
                          }`}
                          className="forgot-pass-link"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <i data-feather="lock" />
                      <input
                        id="password"
                        name="password"
                        autoComplete="off"
                        autoCapitalize="off"
                        type={!togglePassword ? "password" : "text"}
                        className="form-control"
                        placeholder="Password"
                        value={state.password}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "password"
                            ),
                            password: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "password" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}

                      <div
                        id="toggle-password"
                        onClick={() => {
                          setTogglePassword(!togglePassword);
                        }}
                      >
                        <i data-feather="eye" />
                      </div>
                    </div>

                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => submit()}
                        >
                          {state.text !== "Done!" ? (
                            <div className="spinner-grow text-info align-self-center">
                              {state.text}
                            </div>
                          ) : (
                            <>{state.text}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
