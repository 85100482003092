import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ManipulateBlogPageInterface } from "../../../../utils/interfaces/pages/app/administrative/blog-page-interface";
import { SocketContext } from "../../../../utils/hooks/context/socket-context-hooks";
import { useApiService } from "../../../../utils/hooks/custom/api-with-token-http-hook";
import { UserContext } from "../../../../utils/hooks/context/user-context-hook";
import { blockUi, unBlockUi } from "../../../../utils/functions/block-ui";
import { ApiResponseInterface } from "../../../../utils/interfaces/shared-interface";
import { notify } from "../../../../utils/functions/notification";
import { redirect } from "../../../../utils/functions/redirect";
import { uploadFile } from "../../../../utils/functions/firebase-file-upload";
import { loaded } from "../../../../utils/functions/loaded-function";
import { getSocketId } from "../../../../utils/functions/upt-function";

export const ManipulateBlogPage: FC<ManipulateBlogPageInterface> = ({
  callback,
}) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { id } = useParams<any>(),
    { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<ManipulateBlogPageInterface>({
      loading: id ? true : false,
      name: "",
      summary: "",
      description: "",
      link: "",
      type: "",
      pictures: [],
      docs: [],
      text: "Save",
      error: [],
    }),
    setState = (newState: ManipulateBlogPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      get("blog/id", userState().token!, null, id);
    },
    submit = () => {
      state.error = [];

      if (state.name!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "name",
          msg: "Name is required and can only contain alphabet a - z",
        });

      if (state.link!.replace(/ /g, "").length > 0 && state.type!.length === 0)
        state.error!.push({
          field: "type",
          msg: "Kindly select an option",
        });

      if (document.getElementsByClassName("ql-editor")[0].innerHTML.length < 10)
        state.error!.push({
          field: "summary",
          msg: "Summary is required.",
        });

      if (document.getElementsByClassName("ql-editor")[1].innerHTML.length < 10)
        state.error!.push({
          field: "description",
          msg: "Description is required.",
        });

      if (!id && state.docs!.length === 0)
        state.error!.push({
          field: "images",
          msg: "Images is required.",
        });

      if (state.docs!.length === 0 && state.pictures!.length === 0)
        state.error!.push({
          field: "images",
          msg: "Images is required.",
        });

      setState(state);

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });
        blockUi();

        let uploadedPictures: string[] = [];

        const done = (res: ApiResponseInterface) => {
            unBlockUi();
            if (res.type === "error") {
              setState({ text: "Save" });

              notify(res.type, `${res.message}`);

              if (
                res?.message?.includes("section has expired") ||
                res?.message?.toLowerCase().includes("access denied")
              ) {
                redirect(replace, pathname);
              }
            } else {
              setState({ text: "Save" });
              notify(res.type, `${res.message}`);
            }
          },
          resolve = () => {
            if (uploadedPictures.length === state.docs!.length) {
              const data = {
                name: state.name,
                link: state.link,
                type: state.type,
                summary: `${
                  document.getElementsByClassName("ql-editor").length > 0
                    ? `${
                        document.getElementsByClassName("ql-editor")[0]
                          .innerHTML
                      }`
                    : ""
                }`,
                description: `${
                  document.getElementsByClassName("ql-editor").length > 0
                    ? `${
                        document.getElementsByClassName("ql-editor")[1]
                          .innerHTML
                      }`
                    : ""
                }`,
                pictures: [...state.pictures!, ...uploadedPictures],
              };

              if (id)
                put(`blog/update/${`${id}`}`, userState().token!, data)
                  .then((res) => {
                    done(res);
                  })
                  .catch((err) => {
                    setState({ text: "Save" });
                    unBlockUi();
                    notify(err, `${err}`);
                  });
              else
                post(`blog/add`, userState().token!, data)
                  .then((res) => {
                    done(res);
                  })
                  .catch((err) => {
                    setState({ text: "Save" });
                    unBlockUi();
                    notify(err, `${err}`);
                  });
            }
          };

        if (state.docs!.length > 0)
          for (const _file of state.docs!) {
            const file: any = _file;
            uploadFile(
              file,
              ({ type, data }) => {
                if (type === "success") {
                  uploadedPictures.push(data!);

                  resolve();
                } else if (type === "error") {
                  setState({ text: "Save" });
                  notify("error", "Unable to upload files");
                  unBlockUi();
                }
              },
              false
            );
          }
        else resolve();
      }
    };

  useEffect(() => {
    if (callback) callback({ page: "Add Post" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.admin ||
        (!id && userState().role?.blog?.canCreate) ||
        (id && userState().role?.blog?.canUpdate)
      ) {
        if (id) getData();
      } else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (document.getElementById("imagePreview"))
      new window.FileUploadWithPreview("imagePreview");

    for (const e of document.getElementsByClassName("ql-toolbar")) e.remove();

    if (document.getElementById("description"))
      new window.Quill("#description", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Describe this blog...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("summary"))
      new window.Quill("#summary", {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],

            ["clean"],
          ],
        },
        placeholder: "Summarize this blog...",
        theme: "snow", // or 'bubble'
      });
  }, [state.loading]);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-blog/${id}`) {
      setState({ ...state, ...res.data, loading: false });
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      <h4 className="h">{id ? "Edit" : "Add"} Blog</h4>
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              value={state.name}
              onChange={(e) => {
                state.error = state.error!.filter((e) => e.field !== "name");
                state.name = e.target.value;
                setState(state);
              }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "name" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Link video/audio link (optional)</label>
            <input
              type="text"
              className="form-control"
              value={state.link}
              onChange={(e) => {
                state.link = e.target.value;
                setState(state);
              }}
            />
          </div>

          {state.link!.length > 0 ? (
            <div className="form-group">
              <label>Is this link a video or an audio</label>
              <select
                className="form-control"
                onChange={(e) =>
                  setState({
                    error: state.error!.filter((e) => e.field !== "type"),
                    type: e.target.value,
                  })
                }
              >
                <option value="" selected={state.type === ""}>
                  Choose...
                </option>
                <option value="video" selected={state.type === "video"}>
                  Video
                </option>
                <option value="audio" selected={state.type === "audio"}>
                  Audio
                </option>
              </select>

              {state.error!.map((e) => (
                <div
                  className="invalid-feedback"
                  style={{
                    display: e.field === "type" ? "block" : "none",
                  }}
                >
                  {e.msg}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          <div className="form-group">
            <label>Summary</label>
            <div
              id="summary"
              dangerouslySetInnerHTML={{ __html: state.summary! }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "summary" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Description</label>
            <div
              id="description"
              dangerouslySetInnerHTML={{ __html: state.description! }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "description" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group statbox widget box box-shadow">
            <div
              id="imagePreview"
              className="custom-file-container"
              data-upload-id="imagePreview"
            >
              <div className="row mb-3">
                {state.pictures!.map((p) => (
                  <div className="col-lg-4">
                    <div
                      style={{
                        height: 150,
                        width: 150,
                        backgroundImage: `url("${p}")`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />

                    <br />

                    <button
                      className="btn btn-sm btn-danger mt-3 mb-3"
                      onClick={() =>
                        setState({
                          pictures: state.pictures!.filter((_p) => p !== _p),
                        })
                      }
                    >
                      <i data-feather="trash" />
                    </button>
                  </div>
                ))}
              </div>

              <label>
                Cover Image
                <a
                  href="javascript:void(0)"
                  className="custom-file-container__image-clear btn btn-outline-success btn-sm ml-3"
                  title="Clear Image"
                >
                  <i data-feather="x-octagon" />
                </a>
              </label>
              <label className="custom-file-container__custom-file">
                <input
                  type="file"
                  accept="image/*"
                  className="custom-file-container__custom-file__custom-file-input"
                  onChange={(e) => {
                    if (e.target.files) state.docs = e.target.files;
                    setState(state);
                  }}
                />
                <span className="custom-file-container__custom-file__custom-file-control"></span>
              </label>
              <div className="custom-file-container__image-preview"></div>

              {state.error!.map((e) => (
                <div
                  className="invalid-feedback"
                  style={{
                    display: e.field === "images" ? "block" : "none",
                  }}
                >
                  {e.msg}
                </div>
              ))}
            </div>
          </div>

          <button
            type="button"
            className="btn btn-primary mt-5"
            onClick={() => submit()}
          >
            <i data-feather="check-circle" />
          </button>
        </>
      )}
    </div>
  );
};
