import { FC, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { loaded } from "../../../utils/functions/loaded-function";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { redirect } from "../../../utils/functions/redirect";
import { getSocketId } from "../../../utils/functions/upt-function";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { CountryComponent } from "../../../components/country-component";
import {
  CountryComponentInterface,
  CountryPageInterface,
} from "../../../utils/interfaces/pages/app/set-up/country-interface";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";

const Modal: FC<any> = ({ state, setState, getData, action }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory();

  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      {state.text === "Delete Country" ||
      state.text === "Suspend Country" ||
      state.text === "Activate Country" ? (
        <ActionComponent
          actionFunction={() => action()}
          text={state.text.split(" ")[0]}
        />
      ) : (
        <CountryComponent
          {...state.country}
          callback={(country?: CountryComponentInterface) => {
            if (country) {
              redirect(replace, pathname);
            } else {
              setState({ loading: true });
              getData();
            }
          }}
        />
      )}
    </ModalComponent>
  );
};

export const CountryPage: FC<CountryPageInterface> = ({ callback }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<CountryPageInterface>({
      loading: true,
      data: [],
      country: undefined,
      text: undefined,
      searchText: "",
      timeOut: {},
    }),
    setState = (newState: CountryPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true, text: undefined });
      get(
        "country",
        userState().token!,
        {
          filter: true,
          sort: "asc",
          sortName: "name",
          pageSize: 1000000,
          pageNumber: 1,
          searchFilters: {
            searchOption: "AND",
            rules: [
              {
                field: "name",
                option: "cn",
                type: "string",
                data: state.searchText,
              },
              {
                field: "deleted",
                option: "eq",
                type: "boolean",
                data: false,
              },
            ],
          },
        },
        null,
        false
      );
    },
    action = () => {
      closeModal();

      blockUi();
      put(`country/update/${state.country!._id}`, userState().token!, {
        ...(typeof state.country!.deleted !== "undefined" &&
          (state.country!.deleted! === true ||
            state.country!.deleted! === false) && {
            deleted: state.country!.deleted,
          }),
      })
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();

            window.$("#modal").modal("show");

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            ) {
              closeModal();
              redirect(replace, pathname);
            }
          } else {
            notify("success", `${res.message}`);

            unBlockUi();
            getData();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Country" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.country?.canRead || userState().role?.admin)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
      getData();
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-country`) {
      state.data = res.data.data;
      state.loading = false;

      setState(state);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <>
      {state.text ? (
        <Modal
          state={JSON.parse(JSON.stringify(state))}
          setState={setState}
          getData={getData}
          action={action}
        />
      ) : (
        <></>
      )}

      <div className="container pt-5 pb-5">
        <h4 className="h">
          Country
          <button
            type="button"
            className="btn btn-outline-secondary mb-2 ml-3"
            onClick={() => {
              state.searchText = "";

              setState(state);

              getData();
            }}
          >
            <i data-feather="refresh-cw" />
          </button>
          {!state.loading &&
          (userState().role?.admin || userState().role?.country?.canCreate) ? (
            <button
              type="button"
              className="btn btn-primary mb-2 ml-3"
              onClick={() => {
                setState({ text: undefined });
                setTimeout(() => {
                  state.text = "Add";
                  state.country = {
                    name: "",
                    timeZone: "",
                    countryCode: "",
                    currency: "",
                    slug: "",
                    flag: "",
                  };

                  setState(state);

                  setTimeout(() => {
                    window.$("#modal").modal("show");
                  }, 100);
                }, 100);
              }}
            >
              <i data-feather="plus-circle" />
            </button>
          ) : (
            <></>
          )}
        </h4>

        <input
          type="text"
          className="form-control mt-5"
          placeholder="search..."
          autoComplete="off"
          value={state.searchText}
          onChange={(e) => {
            if (typeof state.timeOut === "number") {
              clearTimeout(state.timeOut);
            }
            state.searchText = e.target.value;

            state.timeOut = setTimeout(() => {
              getData();
            }, 1000);

            setState(state);
          }}
        />

        {state.loading ? (
          <div className="d-flex justify-content-center mx-5 mt-3">
            <div className="spinner-grow text-info align-self-center loader-lg" />
          </div>
        ) : (
          <div className="table-responsive mt-5">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>flag</th>
                  <th>Name</th>
                  <th>TimeZone</th>
                  <th>Country Code</th>
                  {userState().role?.country?.canUpdate ||
                  userState().role?.country?.canDelete ||
                  userState().role?.admin ? (
                    <th className="text-center">Action</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {state.data!.length > 0 ? (
                  state.data!.map((c, i) => (
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td>
                        <img
                          src={c.flag}
                          alt="country-flag"
                          style={{ width: 50, height: 50 }}
                        />
                      </td>
                      <td>{c.name}</td>
                      <td>{c.timeZone}</td>
                      <td>{c.countryCode}</td>
                      {userState().role?.country?.canUpdate ||
                      userState().role?.country?.canDelete ||
                      userState().role?.admin ? (
                        <td id="td" className="text-center">
                          <ul className="table-controls">
                            {userState().role?.country?.canUpdate ||
                            userState().role?.admin ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      setState({
                                        text: "Edit Country",
                                        country: c,
                                      });
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="edit" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}

                            {userState().role?.country?.canDelete ||
                            userState().role?.admin ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      state.text = "Delete Country";
                                      state.country = {
                                        ...c,
                                        deleted: true,
                                      };

                                      setState(state);
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="trash" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
