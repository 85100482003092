import { FC, useContext, useState } from "react";
import { useApiService } from "../utils/hooks/custom/api-with-token-http-hook";
import { CityComponentInterface } from "../utils/interfaces/pages/app/set-up/city-page-interface";
import { UserContext } from "../utils/hooks/context/user-context-hook";
import { ApiResponseInterface } from "../utils/interfaces/shared-interface";
import { closeModal, notify } from "../utils/functions/notification";
import { validateJsonString } from "../utils/functions/validate-json-string";

export const CityComponent: FC<CityComponentInterface> = ({
  _id,
  name,
  slug,
  countryId,
  stateId,
  states,
  callback,
}) => {
  const { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<CityComponentInterface>({
      _id,
      name,
      slug,
      countryId,
      stateId,
      states,
      text: "Save",
      error: [],
    }),
    setState = (newState: CityComponentInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    resolve = (res: ApiResponseInterface) => {
      if (res.type === "error") {
        setState({ text: "Save" });

        notify(res.type, `${res.message}`);

        if (
          res.message.includes("section has expired") ||
          res.message.toLowerCase().includes("access denied")
        ) {
          closeModal();
          setTimeout(() => {
            if (callback)
              callback({
                _id: state._id,
                name: state.name,
                slug: state.slug,
                countryId: state.countryId,
                stateId: state.stateId,
                states: state.states,
              });
          }, 100);
        }
      } else {
        notify(res.type, `${res.message}`);
        closeModal();
        if (callback) callback();
      }
    },
    submit = () => {
      state.error = [];

      if (
        state.name!.replace(/ /g, "").length === 0 ||
        !state.name!.match(/^[a-zA-Z0-9\s]*$/)
      )
        state.error.push({
          field: "name",
          msg: "Name is required and can only contain alphabet a - z and numbers",
        });

      if (`${state.stateId!}`.replace(/ /g, "").length === 0)
        state.error.push({
          field: "stateId",
          msg: "Kindly select an option is required.",
        });

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });

        const data = {
          name: state.name,
          slug: state.slug,
          countryId: validateJsonString(state.countryId!)
            ? JSON.parse(state.countryId!)._id
            : state.countryId,
          stateId: validateJsonString(state.stateId!)
            ? JSON.parse(state.stateId!)._id
            : state.stateId,
        };

        if (_id)
          put(`city/update/${_id}`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
        else
          post(`city/add`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
      } else if (state.error.length > 0) setState(state);
    };

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={state.name}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "name"),
                  name: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "name" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>State </label>
            <select
              className="form-control"
              onChange={(e) => {
                console.log(e.target.value);
                setState({
                  error: state.error!.filter((e) => e.field !== "state"),
                  stateId: JSON.parse(e.target.value)._id,
                  countryId: JSON.parse(e.target.value).countryId,
                });
              }}
            >
              <option value="" selected={state.stateId === ""}>
                Choose...
              </option>
              {state.states?.map((s) => (
                <option
                  selected={state.stateId!.includes(s._id!)}
                  value={JSON.stringify(s)}
                >
                  {s.name}
                </option>
              ))}
            </select>

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "stateId" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Slug</label>
            <input
              type="text"
              className="form-control"
              name="slug"
              value={state.slug}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "slug"),
                  slug: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "slug" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};
