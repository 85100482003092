import { FC, useContext, useEffect, useState } from "react";
import { QuoteDetailsPageInterface } from "../../../utils/interfaces/pages/app/quote/quote-page-interface";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import { formatNumbers } from "../../../utils/functions/format-function";
import { SubmissionInterface } from "../../../utils/interfaces/pages/app/submission/submission-page-interface";
import moment from "moment-timezone";
import { notify } from "../../../utils/functions/notification";

export const QuoteDetailsPage: FC<QuoteDetailsPageInterface> = ({
  callback,
}) => {
  const { userState } = useContext(UserContext),
    { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { id } = useParams<any>(),
    [state, setActualState] = useState<QuoteDetailsPageInterface>({
      loading: true,
      loading2: true,
      type: "",
      name: "",
      phoneNumber: "",
      description: "",
      address: "",
      email: "",
      date: "",
      biddingEndDate: "",
      closed: false,
      status: "",
      services: [],
      beeLevel: 0,
      submissions: [],
      allServices: [],
    }),
    setState = (newState: QuoteDetailsPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get("quote/id", userState().token!, null, `${id}`);
    },
    getServices = () => {
      setState({ loading: true });
      get("sub-service", userState().token!, {
        filter: false,
        sort: "asc",
        sortName: "name",
        pageSize: 99999999,
        pageNumber: 1,
      });

      getSubmissions();
    },
    getSubmissions = () => {
      setState({ loading2: true });
      get("submission", userState().token!, {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 99999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "quoteId",
              option: "eq",
              type: "string",
              data: id,
            },
            // {
            //   field: "deleted",
            //   option: "eq",
            //   type: "boolean",
            //   data: false,
            // },
          ],
        },
      });
    };

  useEffect(() => {
    if (callback) callback({ page: "Quote Details " });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.quote?.canRead) {
        getServices();
      } else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-sub-service`) {
      setState({
        allServices: res.data.data,
      });

      getData();
    }

    if (res.url === `${userState()._id}-${getSocketId()}-quote/${id}`) {
      setState({
        ...res.data,
        loading: false,
      });
    }

    if (res.url === `${userState()._id}-${getSocketId()}-submission`) {
      setState({
        submissions: res.data.data,
        loading2: false,
      });
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-11">
              <h4 className="mb-3">Quote Details</h4>
            </div>
            <div className="col">
              {(userState().role?.admin ||
                userState().role?.quote?.canUpdate) &&
              state.status !== "closed" ? (
                <Link
                  to={`/app/quote/edit/${id!}`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                >
                  <i data-feather="edit" />
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>

          <h6 className="h text-capitalize mt-3">
            Status:{" "}
            {state.type === "personal" ? (
              <i data-feather="user" />
            ) : (
              <i data-feather="briefcase" />
            )}{" "}
            {state.status === "suspended" ? (
              <span
                className="badge badge-warning"
                style={{
                  width: "fit-content",
                }}
              >
                pending
              </span>
            ) : state.status === "bidding" ? (
              <span
                className="badge badge-info"
                style={{
                  width: "fit-content",
                }}
              >
                ongoing
              </span>
            ) : state.status === "approved" ? (
              <span
                className="badge badge-primary"
                style={{
                  width: "fit-content",
                }}
              >
                waiting
              </span>
            ) : state.status === "reached out to service provider" ? (
              <span
                className="badge badge-success"
                style={{
                  width: "fit-content",
                }}
              >
                Reached out to service provider
              </span>
            ) : (
              <span
                className="badge badge-secondary"
                style={{
                  width: "fit-content",
                }}
              >
                closed
              </span>
            )}
          </h6>

          {userState().role?.admin ||
          state.viewers?.find((u) => u === userState()._id) ? (
            <>
              <h6 className="h text-capitalize mt-3">Name: {state.name}</h6>

              <h6 className="h mt-3">Email: {state.email}</h6>

              <h6 className="h mt-3">Phone Number: {state.phoneNumber}</h6>

              <h6 className="h text-capitalize mt-3">
                Address: {state.address}
              </h6>

              <h6 className="h text-capitalize mt-3">Due Date: {state.date}</h6>
            </>
          ) : (
            <></>
          )}

          <h6 className="h text-capitalize mt-3">
            Submissions Due Date: {state.biddingEndDate}
          </h6>

          <h6 className="h text-capitalize mt-3">Services</h6>
          <div className="owl-carousel owl-theme mt-3">
            {state.services!.map((s) => (
              <div className="item" style={{ minWidth: "fit-content" }}>
                <span
                  className="badge badge-info text-capitalize"
                  style={{
                    width: "fit-content",
                  }}
                >
                  {state.allServices!.find((al) => al._id === s)?.name}{" "}
                  {state.allServices!.find((al) => al._id === s)?.deleted
                    ? "This service has been deleted"
                    : ""}
                </span>
              </div>
            ))}
          </div>
          <hr />
          <h6 className="h text-capitalize mt-3">Description</h6>
          <div dangerouslySetInnerHTML={{ __html: state.description! }} />

          {state.submissions!.filter(
            (p: SubmissionInterface) =>
              (!p.deleted &&
                userState()?.role?.vendor &&
                p.userId?.includes(userState()._id!)) ||
              (!p.deleted && userState()?.role?.admin)
          ).length == 0 ? (
            <>
              {state.status === "bidding" &&
              userState().role?.vendor &&
              userState().role?.submissions.canCreate &&
              (parseInt(`${state.beeLevel}`) == 0 ||
                (parseInt(`${userState().beeLevel}`) != 0 &&
                  parseInt(`${userState().beeLevel}`)! <=
                    parseInt(`${state.beeLevel}`)!)) &&
              new Date() <= new Date(state.biddingEndDate!) ? (
                <Link
                  to={`/app/submission/${id}/add`}
                  className="btn btn-primary mt-5"
                >
                  Create Submission <i data-feather="send" />
                </Link>
              ) : state.status === "bidding" &&
                userState().role?.vendor &&
                userState().role?.submissions.canCreate &&
                (userState().beeLevel == 0 ||
                  userState().beeLevel! > state.beeLevel!) ? (
                <strong style={{ color: "red" }}>
                  Only those of BEE Level{" "}
                  {state.beeLevel! > 1
                    ? `${state.beeLevel! + 1 - state.beeLevel!} - `
                    : ""}{" "}
                  {state.beeLevel} can apply!
                </strong>
              ) : (
                <></>
              )}
            </>
          ) : userState().role?.vendor ? (
            <strong style={{ color: "green" }}>
              You've already created a submission on this quote
            </strong>
          ) : (
            <></>
          )}

          {state.loading2 ? (
            <div className="d-flex justify-content-center mx-5 mt-3">
              <div className="spinner-grow text-info align-self-center loader-lg" />
            </div>
          ) : (
            <>
              <h4 className="mb-3 mt-5">Submissions</h4>

              <div className="table-responsive mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Date</th>
                      <th>User</th>
                      <th>Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.submissions!.filter(
                      (p: SubmissionInterface) =>
                        (!p.deleted &&
                          userState()?.role?.vendor &&
                          p.userId?.includes(userState()._id!)) ||
                        (!p.deleted && userState()?.role?.admin)
                    ).length > 0 ? (
                      state
                        .submissions!.filter(
                          (p: SubmissionInterface) =>
                            (!p.deleted &&
                              userState()?.role?.vendor &&
                              p.userId?.includes(userState()._id!)) ||
                            (!p.deleted && userState()?.role?.admin)
                        )
                        .map((p: SubmissionInterface, i) => (
                          <tr>
                            <td className="text-center">{i + 1}</td>

                            <td>
                              {moment(new Date(p.createdAt!))
                                .tz("Africa/Maputo")
                                .format("MM/DD/YYYY, h:mm:ss a")}
                            </td>
                            <td className="text-capitalize">
                              {JSON.parse(p.userId!).firstName}{" "}
                              {JSON.parse(p.userId!).lastName}{" "}
                              {p.approved ? (
                                <span
                                  className="badge badge-warning"
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  Approved
                                </span>
                              ) : (
                                <></>
                              )}{" "}
                              {p.selected ? (
                                <span
                                  className="badge badge-secondary"
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  Finalized
                                </span>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              R{formatNumbers(parseFloat(`${p.minAmount}`))} - R
                              {formatNumbers(parseFloat(`${p.maxAmount}`))}
                            </td>
                            <td className="text-center">
                              <ul className="table-controls">
                                <li>
                                  <Link
                                    to={`/app/submission/view-details/${p._id!}`}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View Details"
                                  >
                                    <i data-feather="eye" />
                                  </Link>
                                </li>
                                {(!p.approved || p.suspended) &&
                                state.status !== "closed" ? (
                                  <li>
                                    <Link
                                      to={`/app/submission/edit/${p._id!}`}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i data-feather="edit" />
                                    </Link>
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td />
                        <td />
                        <td>
                          <div className="d-flex justify-content-center mx-5 mt-3">
                            <p className="h">No Record Found</p>
                          </div>
                        </td>
                        <td />
                        <td />
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
