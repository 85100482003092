import { FC, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { Link, useHistory, useLocation } from "react-router-dom";
import { loaded } from "../../../utils/functions/loaded-function";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { redirect } from "../../../utils/functions/redirect";
import { getSocketId } from "../../../utils/functions/upt-function";
import { ModalComponent } from "../../../components/shared/modal-component";
import { DashboardPageInterface } from "../../../utils/interfaces/pages/app/dashboard/dashboard-interface";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { chartData } from "../../../utils/functions/chart-data-function";
import { displayCharts } from "../../../utils/functions/jQuery-functions";
import { ActionComponent } from "../../../components/shared/action-component";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import {
  formatDate,
  formatNumbers,
} from "../../../utils/functions/format-function";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";

const Modal: FC<any> = ({ state, action, setState }) => {
  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      <ActionComponent
        actionFunction={() => action()}
        text={state.text.split(" ")[0]}
      />
    </ModalComponent>
  );
};

export const DashboardPage: FC<DashboardPageInterface> = ({ callback }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<DashboardPageInterface>({
      loading: true,
      chart: JSON.parse(JSON.stringify(chartData)),
      data: [],
      pageNumber: 1,
      records: 0,
      total: 0,
      text: undefined,
      searchText: "",
      from: formatDate(new Date(new Date()).setDate(new Date().getDate() - 7)),
      to: formatDate(),
      timeOut: {},
    }),
    setState = (newState: DashboardPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true, text: undefined });
      get(
        "dashboard/id",
        userState().token!,
        null,
        `${state.from}|${state.to}`
      );
    },
    getTransaction = (pageNumber = 1) => {
      setState({ loading: true, text: undefined });

      let rules: any[] = [
        {
          field: "deleted",
          option: "eq",
          type: "boolean",
          data: false,
        },
      ];

      if (state.from!.length > 0 && state.to!.length > 0)
        rules.push({
          field: "createdAt",
          type: "range",
          data: [
            {
              option: "gte",
              type: "date",
              data: `${formatDate(new Date(state.from!))}, 00:00:00`,
            },
            {
              option: "lte",
              type: "date",
              data: `${formatDate(new Date(state.to!))}, 23:59:59`,
            },
          ],
        });
      get(
        "transaction",
        userState().token!,
        {
          filter: true,
          sort: "desc",
          sortName: "createdAt",
          pageSize: 20,
          pageNumber,
          searchFilters: {
            searchOption: "AND",
            rules,
          },
        },
        null,
        false
      );
    },
    action = () => {
      closeModal();

      blockUi();
      put(`transaction/update/${state.transaction!._id}`, userState().token!, {
        deleted: state.transaction!.deleted,
      })
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();

            window.$("#modal").modal("show");

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            ) {
              closeModal();
              redirect(replace, pathname);
            }
          } else {
            notify("success", `${res.message}`);

            unBlockUi();
            getData();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Dashboard" });

    window.flatpickr(document.getElementById("calendarRange"), {
      mode: "range",
      defaultDate: `${state.from} to ${state.to}`,
      onClose: (_: any, dateStr: string) => {
        if (dateStr.split("to")[0])
          state.from = dateStr.split("to")[0].replace(/ /g, "");

        if (dateStr.split("to")[1])
          state.to = dateStr.split("to")[1].replace(/ /g, "");

        setState(state);

        if (dateStr.split("to").length === 2) {
          getData();
          document.getElementById("calendarRange")?.blur();
        }
      },
    });

    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.dashboard)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (
      res.url ===
      `${userState()._id}-${getSocketId()}-dashboard/${state.from}|${state.to}`
    ) {
      state.chart.xaxis.categories = res.data.chart.categories;
      state.chart.series = res.data.chart.series;
      setState(state);

      getTransaction();
    }

    if (res.url === `${userState()._id}-${getSocketId()}-transaction`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.loading = false;

      setState(state);

      setTimeout(() => {
        if (state.chart.xaxis.categories.length > 0)
          displayCharts(state.chart, "chart");
      }, 100);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <>
      {state.text ? (
        <Modal state={JSON.parse(JSON.stringify(state))} action={action} />
      ) : (
        <></>
      )}

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg">
            <h4 className="h">
              Dashboard
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 ml-3"
                onClick={() => {
                  state.searchText = "";

                  setState(state);

                  getData();
                }}
              >
                <i data-feather="refresh-cw" />
              </button>
              <Link to="/app/invoices" className="btn btn-primary mb-2 ml-3">
                Invoices
              </Link>
            </h4>
          </div>
          <div className="col-lg-4 justify-content-end">
            <input
              id="calendarRange"
              className="form-control flatpickr flatpickr-input"
              type="text"
              value={
                state.from!.length > 0 && state.to!.length > 0
                  ? `${state.from} to ${state.to}`
                  : ""
              }
              placeholder="Select a range of date"
            />
          </div>
        </div>

        {state.loading ? (
          <div className="d-flex justify-content-center mx-5 mt-3">
            <div className="spinner-grow text-info align-self-center loader-lg" />
          </div>
        ) : (
          <>
            {state.chart.xaxis.categories.length > 0 ? (
              <div className="layout-spacing d-none d-lg-block mt-5 mb-5">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="p-3">
                        <h4>Statistics</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div id="chart"></div>
                  </div>
                </div>
                <hr />
              </div>
            ) : (
              <></>
            )}

            <h4 className="mt-5">Recent Transactions</h4>

            <div className="table-responsive mt-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Date</th>
                    <th>Service Provider</th>
                    <th>Amount</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.data!.length > 0 ? (
                    state.data!.map((t: any, i) => (
                      <tr>
                        <td>{i + 1 + 20 * state.pageNumber! - 20}</td>
                        <td>
                          {moment(new Date(t.createdAt!))
                            .tz("Africa/Maputo")
                            .format("MM/DD/YYYY, h:mm:ss a")}
                        </td>
                        <td>
                          {t.userId!.firstName} {t.userId!.lastName}
                        </td>
                        <td>R{formatNumbers(t.amount)}</td>

                        <td id="td" className="text-center">
                          <ul className="table-controls">
                            <li>
                              <Link
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View"
                                to={`/app/invoices/${t.invoiceId}`}
                              >
                                <i data-feather="eye" />
                              </Link>
                            </li>

                            {/* <li>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => {
                                  setState({ text: undefined });
                                  setTimeout(() => {
                                    state.text = "Delete Transaction";
                                    state.transaction = {
                                      ...t,
                                      deleted: true,
                                    };

                                    setState(state);
                                    setTimeout(() => {
                                      window.$("#modal").modal("show");
                                    }, 500);
                                  }, 100);
                                }}
                              >
                                <i data-feather="trash" />
                              </a>
                            </li> */}
                          </ul>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td />
                      <td />
                      <td>
                        <div className="d-flex justify-content-center mx-5 mt-3">
                          <p className="h">No Record Found</p>
                        </div>
                      </td>
                      <td />
                      <td />
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="pagination-custom_outline d-none d-lg-block"
              style={{ justifyContent: "left" }}
            >
              <Pagination
                activePage={state.pageNumber!}
                itemsCountPerPage={20}
                totalItemsCount={state.records!}
                pageRangeDisplayed={10}
                onChange={(pageNumber: number) => getTransaction(pageNumber)}
                prevPageText={<i data-feather="chevron-left" />}
                nextPageText={<i data-feather="chevron-right" />}
                firstPageText={<i data-feather="chevrons-left" />}
                lastPageText={<i data-feather="chevrons-right" />}
                itemClassFirst={"prev"}
                itemClassLast={"next"}
                itemClassNext={"next"}
                itemClassPrev={"prev"}
              />
            </div>
            <div
              className="pagination-custom_outline d-block d-lg-none"
              style={{ justifyContent: "left" }}
            >
              <Pagination
                activePage={state.pageNumber!}
                itemsCountPerPage={20}
                totalItemsCount={state.records!}
                pageRangeDisplayed={3}
                onChange={(pageNumber: number) => getTransaction(pageNumber)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
