import { FC, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../utils/hooks/context/user-context-hook";

export const PageNotFound: FC = () => {
  const { userState } = useContext(UserContext),
    { goBack } = useHistory();

  return (
    <div className="error404 text-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 mr-auto mt-5 text-md-left text-center">
            <Link to="/welcome" className="ml-md-5">
              <img
                alt="logo"
                src={`${process.env.PUBLIC_URL}/assets/img/logo-plain.png`}
                style={{ width: 60, height: 60 }}
                className="theme-logo"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid error-content">
        <div className="">
          <h1 className="error-number">404</h1>
          <p className="mini-text">👀 Ooops!</p>
          <p className="error-text mb-4 mt-1">
            The page you requested was not found!
          </p>
          <button
            className="btn btn-outline-secondary mt-5 mr-3"
            onClick={() => goBack()}
          >
            Go Back
          </button>
          <a
            onClick={() =>
              window.location.replace(
                userState()?.role?.dashboard || userState()?.role?.admin
                  ? `/app/dashboard`
                  : `/app/users/${userState()?._id}`
              )
            }
            className="btn btn-primary mt-5"
          >
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};
