import { FC, useContext, useEffect, useState } from "react";
import {
  QuoteInterface,
  QuotePageInterface,
} from "../../../utils/interfaces/pages/app/quote/quote-page-interface";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { closeModal, notify } from "../../../utils/functions/notification";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { formatDate } from "../../../utils/functions/format-function";

const Modal: FC<any> = ({ state, action }) => {
  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      <ActionComponent actionFunction={() => action()} text="Delete Quote" />
    </ModalComponent>
  );
};

export const QuotePage: FC<QuotePageInterface> = ({ callback }) => {
  const { replace } = useHistory(),
    { pathname } = useLocation(),
    { put } = useApiService(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { url } = useRouteMatch(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<QuotePageInterface>({
      loading: true,
      data: [],
      pageNumber: 1,
      records: 0,
      total: 0,
      text: "",
      from: "",
      to: "",
      searchText: "",
      referenceNoSearchText: "",
      emailSearchText: "",
      quote: undefined,
      timeOut: {},
    }),
    setState = (newState: QuotePageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = (pageNumber = 1) => {
      setState({ loading: true, quote: undefined });

      let rules: any[] = [
        {
          field: "deleted",
          option: "eq",
          type: "boolean",
          data: false,
        },
      ];

      if (state.searchText!.length > 0)
        rules.push({
          field: "name",
          option: "cn",
          type: "string",
          data: state.searchText,
        });

      if (state.referenceNoSearchText!.length > 0)
        rules.push({
          field: "referenceNo",
          option: "cn",
          type: "string",
          data: state.referenceNoSearchText,
        });

      if (state.emailSearchText!.length > 0)
        rules.push({
          field: "email",
          option: "cn",
          type: "string",
          data: state.emailSearchText,
        });

      if (userState().role?.vendor) {
        rules.push({
          field: "services",
          option: "in",
          type: "plainArray",
          data: userState().services?.map((s) => {
            return s.serviceId;
          }),
        });

        rules.push({
          field: "status",
          option: "eq",
          type: "string",
          data: "bidding",
        });
      }

      if (state.from!.length > 0 && state.to!.length > 0)
        rules.push({
          field: "createdAt",
          type: "range",
          data: [
            {
              option: "gte",
              type: "date",
              data: `${formatDate(new Date(state.from!))}, 00:00:00`,
            },
            {
              option: "lte",
              type: "date",
              data: `${formatDate(new Date(state.to!))}, 23:59:59`,
            },
          ],
        });

      get("quote", userState().token!, {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 20,
        pageNumber,
        searchFilters: {
          searchOption: "AND",
          rules,
        },
      });
    },
    action = () => {
      closeModal();
      blockUi();

      put(`quote/update/${`${state.quote!._id}`}`, userState().token!, {
        deleted: state.quote!.deleted,
      })
        .then((res) => {
          unBlockUi();
          notify(res.type, `${res.message}`);

          if (res.type === "success") getData();
        })
        .catch((err) => {
          unBlockUi();
          notify(err, `${err}`);
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Quote" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.quote?.canRead)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (document.getElementById("calendarRange"))
      window.flatpickr(document.getElementById("calendarRange"), {
        mode: "range",
        defaultDate: `${state.from} to ${state.to}`,
        onClose: (_: any, dateStr: string) => {
          if (dateStr.split("to")[0])
            state.from = dateStr.split("to")[0].replace(/ /g, "");

          if (dateStr.split("to")[1])
            state.to = dateStr.split("to")[1].replace(/ /g, "");

          setState(state);

          if (dateStr.split("to").length === 2) {
            document.getElementById("calendarRange")?.blur();
            getData();
          }
        },
      });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-quote`)
      setState({
        data: res.data.data,
        pageNumber: res.data.pageNumber,
        records: res.data.records,
        total: res.data.total,
        loading: false,
      });
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.quote ? (
        <Modal state={JSON.parse(JSON.stringify(state))} action={action} />
      ) : (
        <></>
      )}

      <h4 className="h">
        Quotes
        <button
          type="button"
          className="btn btn-outline-secondary mb-2 ml-3"
          onClick={() => {
            state.searchText = "";
            state.emailSearchText = "";

            setState(state);
            getData();
          }}
        >
          <i data-feather="refresh-cw" />
        </button>
        <Link
          to={`/app/quote/add`}
          type="button"
          className="btn btn-primary mb-2 ml-3"
        >
          <i data-feather="plus-circle" />
        </Link>
      </h4>

      <div className="row mt-5">
        <div className="mb-3 col-lg">
          <input
            id="calendarRange"
            className="form-control flatpickr flatpickr-input"
            type="text"
            value={
              state.from!.length > 0 && state.to!.length > 0
                ? `${state.from} to ${state.to}`
                : ""
            }
            placeholder="Select a range of date"
          />
        </div>
        <div className="mb-3 col-lg">
          <input
            type="text"
            className="form-control"
            placeholder="search using reference no"
            autoComplete="off"
            value={state.referenceNoSearchText}
            onChange={(e) => {
              if (typeof state.timeOut === "number") {
                clearTimeout(state.timeOut);
              }
              state.referenceNoSearchText = e.target.value;

              state.timeOut = setTimeout(() => {
                getData();
              }, 1000);

              setState(state);
            }}
          />
        </div>
        <div className="mb-3 col-lg">
          <input
            type="text"
            className="form-control"
            placeholder="search using name"
            autoComplete="off"
            value={state.searchText}
            onChange={(e) => {
              if (typeof state.timeOut === "number") {
                clearTimeout(state.timeOut);
              }
              state.searchText = e.target.value;

              state.timeOut = setTimeout(() => {
                getData();
              }, 1000);

              setState(state);
            }}
          />
        </div>
        <div className="mb-3 col-lg">
          <input
            type="text"
            className="form-control"
            placeholder="search using email"
            autoComplete="off"
            value={state.emailSearchText}
            onChange={(e) => {
              if (typeof state.timeOut === "number") {
                clearTimeout(state.timeOut);
              }
              state.emailSearchText = e.target.value;

              state.timeOut = setTimeout(() => {
                getData();
              }, 1000);

              setState(state);
            }}
          />
        </div>
      </div>

      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="table-responsive mt-3">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Type/Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data!.filter((p: QuoteInterface) => !p.deleted).length >
                0 ? (
                  state
                    .data!.filter((p: QuoteInterface) => !p.deleted)
                    .map((p: QuoteInterface, i) => (
                      <tr>
                        <td className="text-center">
                          {i + 1 + 20 * state.pageNumber! - 20}
                        </td>
                        <td id="td2">
                          {p.type === "personal" ? (
                            <i data-feather="user" />
                          ) : (
                            <i data-feather="briefcase" />
                          )}{" "}
                          {p.status === "suspended" ? (
                            <span
                              className="badge badge-warning"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              pending
                            </span>
                          ) : p.status === "bidding" ? (
                            <span
                              className="badge badge-info"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              ongoing
                            </span>
                          ) : p.status === "approved" ? (
                            <span
                              className="badge badge-primary"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              waiting
                            </span>
                          ) : p.status === "reached out to service provider" ? (
                            <span
                              className="badge badge-success"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              Reached out
                            </span>
                          ) : (
                            <span
                              className="badge badge-secondary"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              closed
                            </span>
                          )}{" "}
                          {p.status === "bidding"
                            ? moment(new Date(p.biddingEndDate!))
                                .tz("Africa/Maputo")
                                .format("MM/DD/YYYY")
                            : p.date!}
                        </td>
                        <td id="td2" className="text-capitalize">
                          {p.name}
                        </td>

                        <td id="td2" className="text-lowercase">
                          {userState().role?.admin ||
                          p.viewers?.find((u) => u === userState()._id)
                            ? p.email
                            : "******************"}
                        </td>
                        <td>
                          {userState().role?.admin ||
                          p.viewers?.find((u) => u === userState()._id)
                            ? p.phoneNumber
                            : "******************"}
                        </td>

                        <td id="td2" className="text-center">
                          <ul className="table-controls">
                            <li>
                              <Link
                                to={`${url}/view-details/${p._id!}`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View Details"
                              >
                                <i data-feather="eye" />
                              </Link>
                            </li>
                            {p.status !== "closed" ? (
                              <>
                                {userState().role?.admin ||
                                userState().role?.quote?.canUpdate ? (
                                  <li>
                                    <Link
                                      to={`${url}/edit/${p._id!}`}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i data-feather="edit" />
                                    </Link>
                                  </li>
                                ) : (
                                  <></>
                                )}

                                {userState().role?.admin ||
                                userState().role?.quote?.canDelete ? (
                                  <li>
                                    <a
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => {
                                        setState({ text: undefined });
                                        setTimeout(() => {
                                          state.text = "Delete";
                                          state.quote = {
                                            ...p,
                                            deleted: true,
                                          };

                                          setState(state);
                                          setTimeout(() => {
                                            window.$("#modal").modal("show");
                                          }, 500);
                                        }, 100);
                                      }}
                                    >
                                      <i data-feather="trash" />
                                    </a>
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div
            className="pagination-custom_outline d-none d-lg-block"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={10}
              onChange={(pageNumber: number) => getData(pageNumber)}
              prevPageText={<i data-feather="chevron-left" />}
              nextPageText={<i data-feather="chevron-right" />}
              firstPageText={<i data-feather="chevrons-left" />}
              lastPageText={<i data-feather="chevrons-right" />}
              itemClassFirst={"prev"}
              itemClassLast={"next"}
              itemClassNext={"next"}
              itemClassPrev={"prev"}
            />
          </div>
          <div
            className="pagination-custom_outline d-block d-lg-none"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={3}
              onChange={(pageNumber: number) => getData(pageNumber)}
            />
          </div>
        </>
      )}
    </div>
  );
};
