import { FC, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import moment from "moment-timezone";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { TransactionComponent } from "../../../components/transaction-component";
import { TransactionInterface } from "../../../utils/interfaces/pages/app/transaction/transaction-interface";
import { formatNumbers } from "../../../utils/functions/format-function";
import { InvoiceComponent } from "../../../components/invoice-component";
import { SettingsContext } from "../../../utils/hooks/context/settings-context-hooks";
import { useReactToPrint } from "react-to-print";

const Modal: FC<any> = ({ state, setState, getData, action }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory();

  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      {state.text === "Delete Transaction" ? (
        <ActionComponent
          actionFunction={() => action()}
          text={state.text.split(" ")[0]}
        />
      ) : state.text == "Edit Invoice" ? (
        <InvoiceComponent
          {...state.invoice}
          callback={(country?: TransactionInterface) => {
            if (country) {
              redirect(replace, pathname);
            } else {
              setState({ loading: true });
              getData();
            }
          }}
        />
      ) : (
        <TransactionComponent
          {...state.transaction}
          callback={(country?: TransactionInterface) => {
            if (country) {
              redirect(replace, pathname);
            } else {
              setState({ loading: true });
              getData();
            }
          }}
        />
      )}
    </ModalComponent>
  );
};

export const InvoiceDetailsPage: FC<any> = ({ callback }) => {
  const { userState } = useContext(UserContext),
    { pathname } = useLocation(),
    { replace } = useHistory(),
    { put } = useApiService(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { settingsState } = useContext(SettingsContext),
    { id } = useParams<any>(),
    [state, setActualState] = useState<any>({
      loading: true,
      user: undefined,
      transaction: undefined,
      invoice: undefined,
      discount: 0,
      paid: 0,
      transactions: [],
      invoiceNo: "",
      userId: "",
      description: "",
      quoteId: "",
      quote: undefined,
      canView: false,
      submissionId: "",
      text: undefined,
      print: false,
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true, invoice: undefined, transaction: undefined });
      get("invoice/id", userState().token!, null, `${id}`);
    },
    paid = (id: string, paid: boolean) => {
      blockUi();
      put(`transaction/update/${id}`, userState().token!, {
        paid,
      })
        .then((res) => {
          unBlockUi();

          notify(res.type, `${res.message}`);

          if (res.type === "success") getData();
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    },
    action = () => {
      closeModal();

      blockUi();
      put(`transaction/update/${state.transaction!._id}`, userState().token!, {
        deleted: state.transaction!.deleted,
      })
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();

            window.$("#modal").modal("show");

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            ) {
              closeModal();
              redirect(replace, pathname);
            }
          } else {
            notify("success", `${res.message}`);

            unBlockUi();
            getData();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    },
    mail = (type: string, data: any) => {
      blockUi();
      put(
        `invoice/update/mail-${type.toLowerCase()}/${id}`,
        userState().token!,
        data
      )
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            ) {
              redirect(replace, pathname);
            }
          } else {
            notify("success", `${type} Sent!`);

            unBlockUi();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    },
    print = useReactToPrint({
      content: () => document.getElementById("receipt-to-print"),
      onAfterPrint: () => {
        state.print = false;
        setState(state);
      },
    });

  useEffect(() => {
    if (callback) callback({ page: "Invoice Details " });
    loaded(() => {
      if (userState()._id!.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.admin ||
        userState().role?.dashboard ||
        userState().role?.vendor
      ) {
        getData();
      } else if (userState()._id!.length > 0) {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-invoice/${id}`) {
      if (userState().role?.vendor && res.data.userId !== userState()?._id) {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      } else {
        let paid = 0;

        for (const a of res.data.transactions) if (a.paid) paid += a.amount;

        const d = {
          ...state,
          ...res.data,
          quote: {
            ...res.data.quote,
            viewers: Array.isArray(res.data.quote.viewers)
              ? res.data.quote.viewers
              : [],
          },
          paid,
          loading: false,
        };

        d.canView = d.quote.viewers.find((u: string) => u === d.userId)
          ? true
          : false;

        setState(d);
      }
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.text ? (
        <Modal
          state={JSON.parse(JSON.stringify(state))}
          setState={setState}
          getData={getData}
          action={action}
        />
      ) : (
        <></>
      )}

      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-11">
              <h4 className="mb-3">
                Invoice Details{" "}
                {!state.loading &&
                userState().role?.dashboard &&
                state.amount - state.paid - state.discount > 0 ? (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={() => {
                      setState({ text: undefined });
                      setTimeout(() => {
                        state.text = "Add Transaction";
                        state.transaction = {
                          userId: state.user._id,
                          invoiceId: id,
                          quoteId: state.quoteId,
                          submissionId: state.submissionId,
                          amount: "0",
                          balance: parseFloat(`${state.amount - state.paid}`),
                        };

                        setState(state);

                        setTimeout(() => {
                          window.$("#modal").modal("show");
                        }, 100);
                      }, 100);
                    }}
                  >
                    <i data-feather="plus-circle" />
                  </button>
                ) : (
                  <></>
                )}
                <Link
                  className="btn btn-outline-info"
                  to={`/app/quote/view-details/${state.quoteId}`}
                >
                  View Quote
                </Link>
              </h4>
            </div>
            {userState()?.role?.admin || userState()?.role?.dashboard ? (
              <div className="col">
                <a
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    state.canView
                      ? "Prevent vendor from viewing customer info"
                      : `Allow vendor view customer info`
                  }
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    blockUi();
                    put(`quote/update/${state.quoteId}`, userState().token!, {
                      viewers: state.canView
                        ? state.quote.viewers.filter(
                            (u: string) => u !== state.userId
                          )
                        : [...state.quote.viewers, ...[state.userId]],
                      newViewer: state.userId,
                    })
                      .then((res) => {
                        notify(res.type, `${res.message}`);
                        if (res.type === "error") {
                          unBlockUi();

                          if (
                            res.message.includes("section has expired") ||
                            res.message.toLowerCase().includes("access denied")
                          ) {
                            redirect(replace, pathname);
                          }
                        } else {
                          unBlockUi();
                          getData();
                        }
                      })
                      .catch((err) => {
                        notify(err, `${err}`);

                        unBlockUi();
                      });
                  }}
                >
                  <i data-feather={state.canView ? "eye-off" : "eye"} />
                </a>
                <a
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit Invoice"
                  onClick={() => {
                    setState({
                      text: undefined,
                    });
                    setTimeout(() => {
                      state.text = "Edit Invoice";
                      state.invoice = {
                        _id: id,
                        discount: state.discount,
                        amount: state.amount,
                        dueDate: state.dueDate,
                        description: state.description,
                      };

                      setState(state);
                      setTimeout(() => {
                        window.$("#modal").modal("show");
                      }, 500);
                    }, 100);
                  }}
                >
                  <i data-feather="edit" />
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="doc-container invoice mb-4" id="receipt-to-print">
            <div className="invoice-container">
              <div className="invoice-inbox">
                <div id="ct">
                  <div className="invoice-00001">
                    <div className="content-section">
                      <div className="inv--head-section inv--detail-section">
                        <div className="row">
                          <div className="col-sm-6 col-12 mr-auto">
                            <div className="d-flex">
                              <img
                                className="company-logo"
                                src="/assets/img/logo-plain.png"
                                alt="company"
                                style={{ width: 100, height: 50 }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 text-sm-right">
                            <p className="inv-list-number">
                              <span className="inv-title">Invoice: </span>{" "}
                              <span className="inv-number">
                                #{state.invoiceNo}
                              </span>
                            </p>
                          </div>

                          <div className="col-sm-6 align-self-center mt-3">
                            <p className="inv-street-addr">
                              {settingsState.address}
                            </p>
                            <p className="inv-email-address">
                              {settingsState.supportEmail.split(",")[0]}
                            </p>
                            <p className="inv-email-address">
                              {settingsState.supportPhoneNumber.split(",")[0]}
                            </p>
                          </div>
                          <div className="col-sm-6 align-self-center mt-3 text-sm-right">
                            <p className="inv-created-date">
                              <span className="inv-title">Date:</span>
                              <span className="inv-date">
                                {moment(new Date(state.createdAt!))
                                  .tz("Africa/Maputo")
                                  .format("MM/DD/YYYY, h:mm:ss a")}
                              </span>
                            </p>
                          </div>

                          <div className="col-12">
                            <h6 className="h text-capitalize mt-3">
                              Service Description
                            </h6>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: state.description!,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="inv--detail-section inv--customer-detail-section">
                        <div className="row">
                          <div className="col-xl-8 col-lg-7 col-md-6 col-sm-4 align-self-center">
                            <p className="inv-to">Invoice To</p>
                          </div>

                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 align-self-center order-sm-0 order-1 inv--payment-info">
                            <h6 className="inv-title">Payment Info:</h6>
                          </div>

                          <div className="col-xl-8 col-lg-7 col-md-6 col-sm-4">
                            <p className="inv-customer-name">
                              {state.user.firstName} {state.user.lastName}
                            </p>
                            <p className="inv-street-addr">
                              {state.user.businessAddress}
                            </p>
                            <p className="inv-email-address">
                              {state.user.email}
                            </p>
                            <p className="inv-email-address">
                              {state.user.phoneNumbers}
                            </p>
                            <p className="inv-email-address">
                              {state.user.referenceNo}
                            </p>
                          </div>

                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">
                            <div className="inv--payment-info">
                              <p>
                                <span className=" inv-subtitle">
                                  Bank Name:
                                </span>{" "}
                                <span>{settingsState.bankName}</span>
                              </p>
                              <p>
                                <span className=" inv-subtitle">
                                  Account Number:{" "}
                                </span>{" "}
                                <span>{settingsState.accountNumber}</span>
                              </p>
                              <p>
                                <span className=" inv-subtitle">
                                  Branch code:
                                </span>{" "}
                                <span>{settingsState.branchCode}</span>
                              </p>
                              <p>
                                <span className=" inv-subtitle">
                                  SWIFT code:
                                </span>{" "}
                                <span>{settingsState.swiftCode}</span>
                              </p>
                              {/* <p>
                                <span className=" inv-subtitle">Country: </span>{" "}
                                <span>United States</span>
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="inv--product-table-section">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Service Provider</th>
                                <th>Amount</th>
                                {userState().role?.dashboard ||
                                userState().role?.admin ? (
                                  <th className="text-center">Action</th>
                                ) : (
                                  <></>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {state.transactions!.length > 0 ? (
                                state.transactions!.map((t: any, i: number) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>
                                      {moment(new Date(t.createdAt!))
                                        .tz("Africa/Maputo")
                                        .format("MM/DD/YYYY, h:mm:ss a")}
                                    </td>
                                    <td>
                                      {t.userId!.firstName} {t.userId!.lastName}
                                    </td>
                                    <td>R{formatNumbers(t.amount)}</td>

                                    {userState().role?.dashboard ||
                                    userState().role?.admin ? (
                                      <td id="td" className="text-center">
                                        <ul className="table-controls">
                                          {!t.paid && !state.print ? (
                                            <li>
                                              <a
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Edit Transaction"
                                                onClick={() => {
                                                  setState({
                                                    text: undefined,
                                                  });
                                                  setTimeout(() => {
                                                    state.text =
                                                      "Edit Transaction";
                                                    state.transaction = t;

                                                    setState(state);
                                                    setTimeout(() => {
                                                      window
                                                        .$("#modal")
                                                        .modal("show");
                                                    }, 500);
                                                  }, 100);
                                                }}
                                              >
                                                <i data-feather="edit" />
                                              </a>
                                            </li>
                                          ) : (
                                            <></>
                                          )}

                                          <li>
                                            <a
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title={
                                                t.featured ? "Not Paid" : "Paid"
                                              }
                                              onClick={() =>
                                                paid(t._id!, !t.paid!)
                                              }
                                            >
                                              {t.paid ? (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  style={{ color: "#28a745" }}
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-check"
                                                >
                                                  <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg>
                                              ) : (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  style={{ color: "#dc3545" }}
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-slash"
                                                >
                                                  <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                  ></circle>
                                                  <line
                                                    x1="4.93"
                                                    y1="4.93"
                                                    x2="19.07"
                                                    y2="19.07"
                                                  ></line>
                                                </svg>
                                              )}
                                            </a>
                                          </li>

                                          {!state.print ? (
                                            <>
                                              <li>
                                                <a
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Delete"
                                                  onClick={() => {
                                                    setState({
                                                      text: undefined,
                                                    });
                                                    setTimeout(() => {
                                                      state.text =
                                                        "Delete Transaction";
                                                      state.transaction = {
                                                        ...t,
                                                        deleted: true,
                                                      };

                                                      setState(state);
                                                      setTimeout(() => {
                                                        window
                                                          .$("#modal")
                                                          .modal("show");
                                                      }, 500);
                                                    }, 100);
                                                  }}
                                                >
                                                  <i data-feather="trash" />
                                                </a>
                                              </li>

                                              {!t.paid ? (
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      mail("Invoice", {
                                                        email: state.user.email,
                                                        name: `${state.user.firstName} ${state.user.lastName}`,
                                                        amount: `${t.amount}`,
                                                      })
                                                    }
                                                  >
                                                    <span
                                                      className="badge badge-primary"
                                                      style={{
                                                        width: "fit-content",
                                                      }}
                                                    >
                                                      Mail Invoice
                                                    </span>
                                                  </a>
                                                </li>
                                              ) : (
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      mail("Receipt", {
                                                        email: state.user.email,
                                                        name: `${state.user.firstName} ${state.user.lastName}`,
                                                        subTotal: `${formatNumbers(
                                                          state.amount
                                                        )}`,
                                                        discount: `${formatNumbers(
                                                          (state.discount /
                                                            state.amount) *
                                                            100
                                                        )}% = R${formatNumbers(
                                                          state.discount
                                                        )}`,
                                                        grandTotal: `R${formatNumbers(
                                                          state.paid
                                                        )}`,
                                                        date: moment(
                                                          new Date(
                                                            state.dueDate!
                                                          )
                                                        )
                                                          .tz("Africa/Maputo")
                                                          .format("MM/DD/YYYY"),
                                                        data: state.transactions!.map(
                                                          (
                                                            d: any,
                                                            index: number
                                                          ) => {
                                                            return {
                                                              count: index + 1,
                                                              date: moment(
                                                                new Date(
                                                                  d.createdAt!
                                                                )
                                                              )
                                                                .tz(
                                                                  "Africa/Maputo"
                                                                )
                                                                .format(
                                                                  "MM/DD/YYYY, h:mm:ss a"
                                                                ),
                                                              amount: `R${formatNumbers(
                                                                d.amount
                                                              )}`,
                                                              status: d.paid
                                                                ? "PAID"
                                                                : "NOT PAID",
                                                            };
                                                          }
                                                        ),
                                                      })
                                                    }
                                                  >
                                                    <span
                                                      className="badge badge-info"
                                                      style={{
                                                        width: "fit-content",
                                                      }}
                                                    >
                                                      Mail Receipt
                                                    </span>
                                                  </a>
                                                </li>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </ul>
                                      </td>
                                    ) : (
                                      <></>
                                    )}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td />
                                  <td />
                                  <td>
                                    <div className="d-flex justify-content-center mx-5 mt-3">
                                      <p className="h">No Record Found</p>
                                    </div>
                                  </td>
                                  <td />
                                  <td />
                                  <td />
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="inv--total-amounts">
                        <div className="row mt-4">
                          <div className="col-sm-5 col-12 order-sm-0 order-1"></div>
                          <div className="col-sm-7 col-12 order-sm-1 order-0">
                            <div className="text-sm-right">
                              <div className="row">
                                <div className="col-sm-8 col-7">
                                  <p>Sub Total: </p>
                                </div>
                                <div className="col-sm-4 col-5">
                                  <p>R{formatNumbers(state.amount)}</p>
                                </div>

                                <div className="col-sm-8 col-7">
                                  <p className=" discount-rate">
                                    Discount :{" "}
                                    <span className="discount-percentage">
                                      {formatNumbers(
                                        (state.discount / state.amount) * 100
                                      )}
                                      %
                                    </span>{" "}
                                  </p>
                                </div>
                                <div className="col-sm-4 col-5">
                                  <p>R{formatNumbers(state.discount)}</p>
                                </div>
                                <div className="col-sm-8 col-7">
                                  <p>Grand Total: </p>
                                </div>
                                <div className="col-sm-4 col-5">
                                  <p>
                                    R
                                    {formatNumbers(
                                      state.amount - state.discount
                                    )}
                                  </p>
                                </div>
                                {parseFloat(`${state.amount - state.paid}`) !==
                                0 ? (
                                  <>
                                    <div className="col-sm-8 col-7 grand-total-title">
                                      <h4>Balance Due: </h4>
                                    </div>
                                    <div className="col-sm-4 col-5 grand-total-amount">
                                      <h4>
                                        R
                                        {formatNumbers(
                                          state.amount -
                                            state.paid -
                                            state.discount
                                        )}
                                      </h4>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <div className="col-sm-8 col-7 grand-total-title">
                                  <h4>Due Date: </h4>
                                </div>
                                <div className="col-sm-4 col-5 grand-total-amount">
                                  <h4>
                                    {moment(new Date(state.dueDate!))
                                      .tz("Africa/Maputo")
                                      .format("MM/DD/YYYY")}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="inv--note">
                        <div className="row mt-4">
                          <div className="col-sm-12 col-12 order-sm-0 order-1">
                            <p>Thank you for doing Business with us.</p>
                            <p>{settingsState.supportEmail}</p>
                            <p>{settingsState.supportPhoneNumber}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a
            onClick={() => {
              state.print = true;
              setState(state);
              print();
            }}
            className="btn btn-secondary btn-print action-print"
          >
            Print
          </a>
        </>
      )}
    </div>
  );
};
