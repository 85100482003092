import { FC, useContext, useState } from "react";
import { useApiService } from "../utils/hooks/custom/api-with-token-http-hook";
import { UserContext } from "../utils/hooks/context/user-context-hook";
import { ApiResponseInterface } from "../utils/interfaces/shared-interface";
import { closeModal, notify } from "../utils/functions/notification";

export const OrganizationComponent: FC<any> = ({ _id, name, callback }) => {
  const { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<any>({
      _id,
      name,
      text: "Save",
      error: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    resolve = (res: ApiResponseInterface) => {
      if (res.type === "error") {
        setState({ text: "Save" });

        notify(res.type, `${res.message}`);

        if (
          res.message.includes("section has expired") ||
          res.message.toLowerCase().includes("access denied")
        ) {
          closeModal();
          setTimeout(() => {
            if (callback)
              callback({
                _id: state._id,
                name: state.name,
              });
          }, 100);
        }
      } else {
        notify(res.type, `${res.message}`);
        closeModal();
        if (callback) callback();
      }
    },
    submit = () => {
      state.error = [];

      if (state.name!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "name",
          msg: "Name is required and can only contain alphabet a - z and number 0 - 9",
        });

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });

        const data = {
          name: state.name,
          // userId: userState()._id,
        };

        if (!_id)
          post(`organization/add`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
        else
          put(`organization/update/${_id}`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
      } else if (state.error.length > 0) setState(state);
    };

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={state.name}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e: any) => e.field !== "name"),
                  name: e.target.value,
                })
              }
            />

            {state.error!.map((e: any) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "name" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};
