import moment from "moment-timezone";
import { user } from "../hooks/context/user-context-hook";

export const formatDate = (
  date: any = new Date(),
  timeZone = "Africa/Abidjan"
) => {
  return `${moment(new Date(date)).tz(timeZone).format("YYYY-MM-DD")}`;
};

export const formatFullDate = (
  date: any = new Date(),
  timeZone = "Africa/Abidjan"
) => {
  return `${moment(new Date(date)).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")}`;
};

export const formatNumbers = (x: number) => {
  return `${x
    .toFixed(4)
    .split(".")[0]
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    x.toFixed(2).split(".")[1] === "00" ? "" : `.${x.toFixed(2).split(".")[1]}`
  }`;
};
