import { FC, useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { SidebarComponentInterface } from "../../utils/interfaces/components/side-bar-component-interface";
import { UserContext } from "../../utils/hooks/context/user-context-hook";

export const SidebarComponent: FC<SidebarComponentInterface> = () => {
  const { url } = useRouteMatch(),
    { userState } = useContext(UserContext);

  return (
    <div className="sidebar-wrapper sidebar-theme">
      <nav id="sidebar">
        <div className="shadow-bottom"></div>
        <ul className="list-unstyled menu-categories" id="menu-categories">
          {userState()?.role?.dashboard || userState().role?.admin ? (
            <>
              <li className="menu t-menu">
                <Link
                  to={`${url}/dashboard`}
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div>
                    <i data-feather="home" />
                    <span>Dashboard</span>
                  </div>
                </Link>
              </li>
              <hr />
            </>
          ) : (
            <></>
          )}

          {userState()?.role?.dashboard ||
          userState().role?.admin ||
          userState().role?.vendor ? (
            <>
              <li className="menu t-menu">
                <Link
                  to={`${url}/invoices`}
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div>
                    <i data-feather="file-text" />
                    <span>Invoices</span>
                  </div>
                </Link>
              </li>
              <hr />
            </>
          ) : (
            <></>
          )}

          {userState()?.role?.dashboard || userState().role?.admin ? (
            <>
              <li className="menu t-menu">
                <Link
                  to={`${url}/analytics`}
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div>
                    <i data-feather="pie-chart" />
                    <span>Analytics</span>
                  </div>
                </Link>
              </li>
              <hr />
            </>
          ) : (
            <></>
          )}

          {userState()?.role?.country?.canRead ||
          userState()?.role?.state?.canRead ||
          userState()?.role?.city?.canRead ||
          userState()?.role?.organizations?.canRead ||
          userState()?.role?.categories?.canRead ||
          userState()?.role?.services?.canRead ||
          userState().role?.admin ? (
            <li className="menu justify-content-center">
              <a href="#" aria-expanded="false" className="dropdown-toggle">
                <div>
                  <span>SET UP</span>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.country?.canRead || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/country`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="flag" />
                  <span>Country</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.state?.canRead || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/state`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="anchor" />
                  <span>State</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.city?.canRead || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/city`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="map" />
                  <span>City</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.organizations?.canRead ||
          userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/organization`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="layout" />
                  <span>Organization</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.services?.canRead ||
          userState()?.role?.categories?.canRead ||
          userState().role?.admin ? (
            <li className="menu">
              <a
                href="#services-group"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="tool" />
                  <span>CATEGORIES</span>
                </div>
                <div>
                  <i data-feather="chevron-right" />
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="services-group"
              >
                {userState()?.role?.categories?.canRead ||
                userState().role?.admin ? (
                  <li className="menu">
                    <a
                      href="#main-services"
                      data-toggle="collapse"
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div>
                        <span>Category</span>
                      </div>
                      <div>
                        <i data-feather="chevron-right" />
                      </div>
                    </a>
                    <ul
                      className="collapse submenu list-unstyled"
                      id="main-services"
                    >
                      {userState()?.role?.services?.canCreate ? (
                        <li className="t-menu">
                          <Link className="t-menu" to={`${url}/services/add`}>
                            Add Category
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}

                      {userState()?.role?.services?.canRead ? (
                        <li className="t-menu">
                          <Link className="t-menu" to={`${url}/services`}>
                            View Categories
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                ) : (
                  <></>
                )}

                {userState()?.role?.services?.canRead ||
                userState().role?.admin ? (
                  <li className="menu">
                    <a
                      href="#sub-services"
                      data-toggle="collapse"
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div>
                        <span>Services</span>
                      </div>
                      <div>
                        <i data-feather="chevron-right" />
                      </div>
                    </a>
                    <ul
                      className="collapse submenu list-unstyled"
                      id="sub-services"
                    >
                      {userState()?.role?.services?.canCreate ? (
                        <li className="t-menu">
                          <Link
                            className="t-menu"
                            to={`${url}/sub-services/add`}
                          >
                            Add A Service
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}

                      {userState()?.role?.services?.canRead ? (
                        <li className="t-menu">
                          <Link className="t-menu" to={`${url}/sub-services`}>
                            View Service
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.country?.canRead ||
          userState()?.role?.state?.canRead ||
          userState()?.role?.city?.canRead ||
          userState()?.role?.services?.canRead ||
          userState().role?.admin ? (
            <hr />
          ) : (
            <></>
          )}

          <li className="menu t-menu">
            <a
              href="https://docs.google.com/document/d/1AtSHDHIuoQCcXq6YFUSitRfIc3GF8cxe/edit?usp=share_link&ouid=103037511276542656642&rtpof=true&sd=true"
              tabIndex={-1}
              target="_blank"
              className="dropdown-toggle"
            >
              <div>
                <i data-feather="book" />
                <span>User Manual</span>
              </div>
            </a>
          </li>

          <hr />

          {userState()?.role?.quote?.canRead ||
          userState()?.role?.quote?.canCreate ||
          userState()?.role?.submissions?.canRead ||
          userState().role?.admin ? (
            <li className="menu justify-content-center">
              <a href="#" aria-expanded="false" className="dropdown-toggle">
                <div>
                  <span>QUOTATION</span>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.quote?.canCreate || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/quote/add`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="file-plus" />
                  <span>Create Quote</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.quote?.canRead || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/quote`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="file" />
                  <span>View Quotes</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.submissions?.canRead ||
          userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/submission`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="file-text" />
                  <span>Submissions</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.quote?.canRead ||
          userState()?.role?.quote?.canCreate ||
          userState()?.role?.submissions?.canRead ||
          userState().role?.admin ? (
            <hr />
          ) : (
            <></>
          )}

          {userState()?.role?.blog?.canRead ||
          userState()?.role?.blog?.canCreate ||
          userState()?.role?.users?.canRead ||
          userState()?.role?.settings ||
          userState().role?.admin ? (
            <li className="menu justify-content-center">
              <a href="#" aria-expanded="false" className="dropdown-toggle">
                <div>
                  <span>ADMINISTRATIVE</span>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.blog?.canRead ||
          userState()?.role?.blog?.canCreate ||
          userState()?.role?.settings ||
          userState().role?.admin ? (
            <li className="menu">
              <a
                href="#blogs"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="rss" />
                  <span>Posts</span>
                </div>
                <div>
                  <i data-feather="chevron-right" />
                </div>
              </a>
              <ul className="collapse submenu list-unstyled" id="blogs">
                {userState()?.role?.blog?.canCreate ||
                userState().role?.admin ? (
                  <li className="t-menu">
                    <Link className="t-menu" to="/app/blog/add">
                      Create Post
                    </Link>
                  </li>
                ) : (
                  <></>
                )}

                {userState()?.role?.blog?.canRead || userState().role?.admin ? (
                  <li className="t-menu">
                    <Link className="t-menu" to="/app/blog">
                      View Posts
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.users?.canRead || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/users`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="users" />
                  <span>User Management</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}

          {userState()?.role?.settings || userState().role?.admin ? (
            <li className="menu t-menu">
              <Link
                to={`${url}/settings`}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div>
                  <i data-feather="settings" />
                  <span>Settings</span>
                </div>
              </Link>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </nav>
    </div>
  );
};
