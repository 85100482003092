import { useState, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { UserContext } from "../context/user-context-hook";
import { ApiResponseInterface } from "../../interfaces/shared-interface";
import { uPT } from "../../functions/upt-function";
import { useLocation } from "react-router-dom";

// {
//       filter,
//       pageNumber ,
//       pageSize,
//       sort,
//       sortName,
//       searchFilters?: {
//         searchOption,
//         rules,
//       }

//     }

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useApiService = () => {
  const { userState } = useContext(UserContext),
    [_response, setResponse] = useState<ApiResponseInterface>({
      data: {},
      type: "",
      message: "",
    }),
    get = async (
      link: string,
      token: string,
      params: any = null,
      base = true,
      timeout?: number
    ) => {
      setResponse({
        data: {},
        type: "",
        message: "",
      });

      if (params && params.filter) {
        params.searchFilters = JSON.stringify(params.searchFilters);
      }

      await axios
        .get(`${BASE_URL}/${link}`, {
          headers: {
            "x-auth-token": token,
          },
          ...(params && {
            params: params,
          }),
          timeout: timeout ? timeout : 60000,
        })
        .then((response) => {
          uPT(response, userState());
          _response.data = response.data.data;
          _response.message = response.data.message;
          _response.type = "success";

          setResponse(_response);
        })
        .catch((error) => {
          _response.type = "error";
          _response.message =
            !error.response && error.message === "Network Error"
              ? "Invalid URL or Network Error Please check your internet connection"
              : error.response?.data.message;
          _response.data = error.response?.data.data;
          setResponse(_response);
        });

      return _response;
    },
    getOutside = async (
      link: string,
      payload: any = null,
      timeout?: number
    ) => {
      setResponse({
        data: {},
        type: "",
        message: "",
      });

      await axios
        .get(link, {
          ...(payload && {
            payload,
          }),
          timeout: timeout ? timeout : 60000,
        })
        .then((response) => {
          uPT(response, userState());
          _response.data = response.data;
          _response.message = "success";
          _response.type = "success";

          setResponse(_response);
        })
        .catch((error) => {
          _response.type = "error";
          _response.message =
            !error.response && error.message === "Network Error"
              ? "Invalid URL or Network Error Please check your internet connection"
              : "error";
          _response.data = error;
          setResponse(_response);
        });

      return _response;
    },
    post = async (
      link: string,
      token: string,
      data: any,
      params: any = null,
      base = true,
      timeout?: number
    ) => {
      setResponse({
        data: {},
        type: "",
        message: "",
      });

      await axios
        .post(`${BASE_URL}/${link}`, data, {
          headers: {
            "x-auth-token": token,
          },
          ...(params && {
            params: params,
          }),
          timeout: timeout ? timeout : 60000,
        })
        .then((response) => {
          uPT(response, userState());
          _response.data = response.data.data;
          _response.message = response.data.message;
          _response.type = "success";

          setResponse(_response);
        })
        .catch((error) => {
          console.log(error);
          _response.type = "error";
          _response.message =
            !error.response && error.message === "Network Error"
              ? "Invalid URL or Network Error Please check your internet connection"
              : error.response?.data.message;
          _response.data = error.response?.data.data;
          setResponse(_response);
        });

      return _response;
    },
    put = async (
      link: string,
      token: string,
      data: any,
      params: any = null,
      base = true,
      timeout?: number
    ) => {
      setResponse({
        data: {},
        type: "",
        message: "",
      });

      await axios
        .put(`${BASE_URL}/${link}`, data, {
          headers: {
            "x-auth-token": token,
          },
          ...(params && {
            params: params,
          }),
          timeout: timeout ? timeout : 60000,
        })
        .then((response) => {
          uPT(response, userState());
          _response.data = response.data.data;
          _response.message = response.data.message;
          _response.type = "success";

          setResponse(_response);
        })
        .catch((error) => {
          _response.type = "error";
          _response.message =
            !error.response && error.message === "Network Error"
              ? "Invalid URL or Network Error Please check your internet connection"
              : error.response?.data.message;
          _response.data = error.response?.data.data;
          setResponse(_response);
        });

      return _response;
    },
    Delete = async (
      link: string,
      token: string,
      base = true,
      timeout?: number
    ) => {
      setResponse({
        data: {},
        type: "",
        message: "",
      });

      await axios
        .delete(`${BASE_URL}/${link}`, {
          headers: {
            "x-auth-token": token,
          },
          timeout: timeout ? timeout : 60000,
        })
        .then((response) => {
          uPT(response, userState());
          _response.data = response.data.data;
          _response.message = response.data.message;
          _response.type = "success";
        })
        .catch((error) => {
          _response.type = "error";
          _response.message =
            !error.response && error.message === "Network Error"
              ? "Invalid URL or Network Error Please check your internet connection"
              : error.response?.data.message;
          _response.data = error.response?.data.data;
          setResponse(_response);
        });

      return _response;
    };

  return { post, put, get, getOutside, Delete };
};
