import { FC } from "react";
import { closeModal } from "../../utils/functions/notification";
import { ModalComponentInterface } from "../../utils/interfaces/components/modal-component-interface";

export const ModalComponent: FC<ModalComponentInterface> = ({
  children,
  title,
  close,
  additionalClasses,
}) => {
  return (
    <div
      className="modal fade"
      id="modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className={`modal-dialog ${additionalClasses}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            {title ? (
              <h5 className="modal-title" id="modalLabel">
                {title}
              </h5>
            ) : (
              <></>
            )}
            <button
              type="button"
              className="close"
              onClick={() => (close ? close() : closeModal())}
            >
              <i data-feather="x-circle" />
            </button>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};
