import { FC, useContext, useEffect, useState } from "react";
import {
  SubmissionInterface,
  SubmissionPageInterface,
} from "../../../utils/interfaces/pages/app/submission/submission-page-interface";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";
import {
  formatDate,
  formatNumbers,
} from "../../../utils/functions/format-function";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";

const Modal: FC<any> = ({ state, action }) => {
  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      <ActionComponent
        actionFunction={() => action()}
        text="Delete Submission"
      />
    </ModalComponent>
  );
};

export const SubmissionPage: FC<SubmissionPageInterface> = ({ callback }) => {
  const { replace } = useHistory(),
    { pathname } = useLocation(),
    { put } = useApiService(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { url } = useRouteMatch(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<SubmissionPageInterface>({
      loading: true,
      data: [],
      pageNumber: 1,
      records: 0,
      total: 0,
      text: "",
      from: "",
      to: "",
      searchText: "",
      timeOut: {},
      submission: undefined,
    }),
    setState = (newState: SubmissionPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = (pageNumber = 1) => {
      setState({ loading: true });

      let rules: any[] = [
        {
          field: "deleted",
          option: "eq",
          type: "boolean",
          data: false,
        },
      ];

      if (state.from!.length > 0 && state.to!.length > 0)
        rules.push({
          field: "createdAt",
          type: "range",
          data: [
            {
              option: "gte",
              type: "date",
              data: `${formatDate(new Date(state.from!))}, 00:00:00`,
            },
            {
              option: "lte",
              type: "date",
              data: `${formatDate(new Date(state.to!))}, 23:59:59`,
            },
          ],
        });

      if (userState().role?.vendor)
        rules.push({
          field: "userId",
          option: "eq",
          type: "string",
          data: userState()._id,
        });

      get("submission", userState().token!, {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 20,
        pageNumber,
        searchFilters: {
          searchOption: "AND",
          rules,
        },
      });
    },
    action = () => {
      closeModal();
      blockUi();

      put(`quote/update/${`${state.submission!._id}`}`, userState().token!, {
        deleted: state.submission!.deleted,
      })
        .then((res) => {
          unBlockUi();
          notify(res.type, `${res.message}`);

          if (res.type === "success") getData();
        })
        .catch((err) => {
          unBlockUi();
          notify(err, `${err}`);
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Submission" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.admin ||
        userState().role?.submissions?.canRead
      )
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (document.getElementById("calendarRange"))
      window.flatpickr(document.getElementById("calendarRange"), {
        mode: "range",
        defaultDate: `${state.from} to ${state.to}`,
        onClose: (_: any, dateStr: string) => {
          if (dateStr.split("to")[0])
            state.from = dateStr.split("to")[0].replace(/ /g, "");

          if (dateStr.split("to")[1])
            state.to = dateStr.split("to")[1].replace(/ /g, "");

          setState(state);

          if (dateStr.split("to").length === 2) {
            document.getElementById("calendarRange")?.blur();
            getData();
          }
        },
      });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-submission`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.loading = false;
    }

    if (res.url.length > 0) setState(state);
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.submission ? (
        <Modal state={JSON.parse(JSON.stringify(state))} action={action} />
      ) : (
        <></>
      )}

      <h4 className="h">
        Submissions
        <button
          type="button"
          className="btn btn-outline-secondary mb-2 ml-3"
          onClick={() => {
            state.from = "";
            state.to = "";

            setState(state);
            getData();
          }}
        >
          <i data-feather="refresh-cw" />
        </button>
      </h4>

      <input
        id="calendarRange"
        className="form-control flatpickr flatpickr-input col-lg-4"
        type="text"
        value={
          state.from!.length > 0 && state.to!.length > 0
            ? `${state.from} to ${state.to}`
            : ""
        }
        placeholder="Select a range of date"
      />

      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="table-responsive mt-3">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>Amount</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data!.filter((p: SubmissionInterface) => !p.deleted)
                  .length > 0 ? (
                  state
                    .data!.filter((p: SubmissionInterface) => !p.deleted)
                    .map((p: SubmissionInterface, i) => (
                      <tr>
                        <td className="text-center">
                          {i + 1 + 20 * state.pageNumber! - 20}
                        </td>

                        <td>
                          {moment(new Date(p.createdAt!))
                            .tz("Africa/Maputo")
                            .format("MM/DD/YYYY, h:mm:ss a")}
                        </td>
                        <td className="text-capitalize">
                          {JSON.parse(p.userId!).firstName}{" "}
                          {JSON.parse(p.userId!).lastName}
                          {!p.suspended ? (
                            <span
                              className="badge badge-warning"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              Approved
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                          {p.selected ? (
                            <span
                              className="badge badge-secondary"
                              style={{
                                width: "fit-content",
                              }}
                            >
                              Finalized
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          R{formatNumbers(parseFloat(`${p.minAmount}`))} - R
                          {formatNumbers(parseFloat(`${p.maxAmount}`))}
                        </td>
                        <td className="text-center">
                          <ul className="table-controls">
                            <li>
                              <Link
                                to={`${url}/view-details/${p._id!}`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View Details"
                              >
                                <i data-feather="eye" />
                              </Link>
                            </li>
                            {userState().role?.admin ||
                            userState().role?.quote?.canRead ? (
                              <li>
                                <Link
                                  to={`/app/quote/view-details/${p.quoteId!}`}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View Quote"
                                >
                                  <i data-feather="crosshair" />
                                </Link>
                              </li>
                            ) : (
                              <></>
                            )}
                            {!p.approved ? (
                              <>
                                {(userState().role?.admin ||
                                  userState().role?.submissions?.canUpdate) &&
                                p.suspended ? (
                                  <li>
                                    <Link
                                      to={`${url}/edit/${p._id!}`}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i data-feather="edit" />
                                    </Link>
                                  </li>
                                ) : (
                                  <></>
                                )}

                                {(userState().role?.admin ||
                                  userState().role?.quote?.canDelete) &&
                                p.suspended ? (
                                  <li>
                                    <a
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => {
                                        setState({ text: undefined });
                                        setTimeout(() => {
                                          state.text = "Delete";
                                          state.submission = {
                                            ...p,
                                            deleted: true,
                                          };

                                          setState(state);
                                          setTimeout(() => {
                                            window.$("#modal").modal("show");
                                          }, 500);
                                        }, 100);
                                      }}
                                    >
                                      <i data-feather="trash" />
                                    </a>
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div
            className="pagination-custom_outline d-none d-lg-block"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={10}
              onChange={(pageNumber: number) => getData(pageNumber)}
              prevPageText={<i data-feather="chevron-left" />}
              nextPageText={<i data-feather="chevron-right" />}
              firstPageText={<i data-feather="chevrons-left" />}
              lastPageText={<i data-feather="chevrons-right" />}
              itemClassFirst={"prev"}
              itemClassLast={"next"}
              itemClassNext={"next"}
              itemClassPrev={"prev"}
            />
          </div>
          <div
            className="pagination-custom_outline d-block d-lg-none"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={3}
              onChange={(pageNumber: number) => getData(pageNumber)}
            />
          </div>
        </>
      )}
    </div>
  );
};
