import { useState, createContext, useEffect, useContext, FC } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "./user-context-hook";
import { loaded } from "../../functions/loaded-function";
import { SocketContext } from "./socket-context-hooks";
import { getSocketId } from "../../functions/upt-function";

const data = {
  charge: "0",
  percent: "0",
  address: "",
  accountName: "",
  accountNumber: "",
  bankName: "",
  branchCode: "",
  swiftCode: "",
  supportEmail: "",
  supportPhoneNumber: "",
  about: "",
  terms: "",
  privacy: "",
  faqs: "",
};

export const SettingsContext = createContext({
  settingsState: {
    loading: true,
    ...JSON.parse(JSON.stringify(data)),
  },
  getSettingsData: () => {},
  setSettingsState: (data: any) => {},
});

export const SettingsProvider: FC = ({ children }) => {
  const { get, res } = useContext(SocketContext),
    { pathname } = useLocation(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState({
      searchText: "",
      loading: true,
      ...JSON.parse(JSON.stringify(data)),
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      get("settings-2/id", userState().token!, null, "1");
    };

  useEffect(() => {
    loaded(() => getData());
  }, [pathname]);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-settings-2/1`) {
      setState({
        ...state,
        ...res.data,
        loading: false,
      });
    }
  }, [res.url]);

  return (
    <SettingsContext.Provider
      value={{
        settingsState: state,
        getSettingsData: getData,
        setSettingsState: setState,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
