import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ManipulateQuotePageInterface } from "../../../utils/interfaces/pages/app/quote/quote-page-interface";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { ApiResponseInterface } from "../../../utils/interfaces/shared-interface";
import { notify } from "../../../utils/functions/notification";
import { redirect } from "../../../utils/functions/redirect";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { formatDate } from "../../../utils/functions/format-function";

export const ManipulateQuotePage: FC<ManipulateQuotePageInterface> = ({
  callback,
}) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { id } = useParams<any>(),
    { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<ManipulateQuotePageInterface>({
      loading: id ? true : false,
      type: "",
      name: "",
      phoneNumber: "",
      description: "",
      address: "",
      email: "",
      status: "",
      date: "",
      biddingEndDate: "",
      beeLevel: 0,
      services: [],
      allServices: [],
      text: "Save",
      error: [],
    }),
    setState = (newState: ManipulateQuotePageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      get("quote/id", userState().token!, null, id);
    },
    getServices = () => {
      setState({ loading: true });
      get("sub-service", userState().token!, {
        filter: id ? false : true,
        sort: "asc",
        sortName: "name",
        pageSize: 99999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      });
    },
    submit = () => {
      state.error = [];

      if (
        state.name!.replace(/ /g, "").length === 0 ||
        !state.name!.match(/^[a-zA-Z0-9\s]*$/)
      )
        state.error!.push({
          field: "name",
          msg: "Name is required and can only contain alphabet a - z",
        });

      if (state.type!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "type",
          msg: "Kindly select an option",
        });

      if (state.status!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "status",
          msg: "Kindly select an option",
        });

      if (state.date!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "date",
          msg: "Kindly select an option",
        });

      if (state.biddingEndDate!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "biddingEndDate",
          msg: "Kindly select an option",
        });

      if (state.phoneNumber!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "phoneNumber",
          msg: "Your phone number is required",
        });

      if (state.address!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "address",
          msg: "Your phone number is required",
        });

      if (state.email!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "email",
          msg: "Your Email is required",
        });
      else if (
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (document.getElementsByClassName("ql-editor")[0].innerHTML.length < 10)
        state.error!.push({
          field: "description",
          msg: "Description is required.",
        });

      state.services = [];

      const d: any = document,
        options = d.getElementById("service")?.options;

      for (const o of options) if (o.selected) state.services!.push(o.value);

      if (state.services!.length === 0)
        state.error!.push({
          field: "service",
          msg: "Kindly select an option",
        });

      if (isNaN(state.beeLevel!))
        state.error!.push({
          field: "beeLevel",
          msg: "Kindly select an option",
        });

      setState(state);

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });
        blockUi();

        const done = (res: ApiResponseInterface) => {
          unBlockUi();
          if (res.type === "error") {
            setState({ text: "Save" });

            notify(res.type, `${res.message}`);

            if (
              res?.message?.includes("section has expired") ||
              res?.message?.toLowerCase().includes("access denied")
            ) {
              redirect(replace, pathname);
            }
          } else {
            setState({ text: "Save" });
            notify(res.type, `${res.message}`);

            replace("/app/quote");
          }
        };

        const data = JSON.parse(
          JSON.stringify({
            type: state.type,
            name: state.name,
            phoneNumber: state.phoneNumber,
            address: state.address,
            email: state.email,
            services: state.services,
            description: `${
              document.getElementsByClassName("ql-editor").length > 0
                ? `${document.getElementsByClassName("ql-editor")[0].innerHTML}`
                : ""
            }`,
            beeLevel: parseFloat(`${state.beeLevel}`),
            biddingEndDate: formatDate(new Date(state.biddingEndDate!)),
            date: formatDate(new Date(state.date!)),
            status: state.status,
            userId: userState()?._id,
          })
        );

        if (id)
          put(`quote/update/${`${id}`}`, userState().token!, data)
            .then((res) => {
              done(res);
            })
            .catch((err) => {
              setState({ text: "Save" });
              unBlockUi();
              notify(err, `${err}`);
            });
        else
          post(`quote/add`, userState().token!, data)
            .then((res) => {
              done(res);
            })
            .catch((err) => {
              setState({ text: "Save" });
              unBlockUi();
              notify(err, `${err}`);
            });
      }
    };

  useEffect(() => {
    if (callback) callback({ page: "Add Quote" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.admin ||
        (!id && userState().role?.quote?.canCreate) ||
        (id && userState().role?.quote?.canUpdate)
      ) {
        getServices();
      } else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    for (const e of document.getElementsByClassName("ql-toolbar")) e.remove();

    if (
      document.getElementById("description") &&
      document.getElementsByClassName("ql-toolbar ql-snow").length == 0
    )
      new window.Quill("#description", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Describe this quote...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("datePicker"))
      window.flatpickr(document.getElementById("datePicker"), {
        defaultDate: "",
        onClose: (_: any, dateStr: string) => {
          setState({
            biddingEndDate: dateStr,
          });
          document.getElementById("datePicker")?.blur();
        },
      });

    if (document.getElementById("datePicker2"))
      window.flatpickr(document.getElementById("datePicker2"), {
        onClose: (_: any, dateStr: string) => {
          setState({
            date: dateStr,
          });
          document.getElementById("datePicker2")?.blur();
        },
      });
  }, [state.loading]);

  useEffect(() => {
    if (document.getElementById("service")) {
      window.$("#service").select2();
    }
  }, [state]);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-sub-service`) {
      state.allServices = res.data.data;
      state.loading = id ? true : false;

      setState(state);

      if (id) getData();
    }

    if (res.url === `${userState()._id}-${getSocketId()}-quote/${id}`) {
      setState({
        ...state,
        ...res.data,
        services: res.data.services
          .map((s: string) => {
            const d = state.allServices?.find((al) => al._id === s);

            if (d && d.deleted)
              d.name = `${d.name} (this service has been deleted)`;
            return d;
          })
          .filter((s: any) => s),
        loading: false,
      });
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      <h4 className="h">{id ? "Edit" : "Add"} Quote</h4>
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="form-group">
            <label>Your Full Name</label>
            <input
              type="text"
              className="form-control"
              value={state.name}
              onChange={(e) => {
                state.error = state.error!.filter((e) => e.field !== "name");
                state.name = e.target.value;
                setState(state);
              }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "name" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={state.email}
              onChange={(e) => {
                state.email = e.target.value;
                setState(state);
              }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "email" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              value={state.phoneNumber}
              onChange={(e) => {
                state.phoneNumber = e.target.value;
                setState(state);
              }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "phoneNumber" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Address</label>
            <textarea
              className="form-control"
              value={state.address}
              onChange={(e) => {
                state.address = e.target.value;
                setState(state);
              }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "address" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>
              Is this request for you personally or for an organization?
            </label>
            <select
              className="form-control"
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "type"),
                  type: e.target.value,
                })
              }
            >
              <option value="" selected={state.type === ""}>
                Choose...
              </option>
              <option value="personal" selected={state.type === "personal"}>
                Personal
              </option>
              <option
                value="organization"
                selected={state.type === "organization"}
              >
                For An Organization
              </option>
            </select>

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "type" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>BEE Level</label>
            <select
              className="form-control"
              onChange={(e) =>
                setState({
                  error: state.error!.filter(
                    (e: any) => e.field !== "beeLevel"
                  ),
                  beeLevel:
                    e.target.value.length > 0 ? parseInt(e.target.value) : 0,
                })
              }
            >
              <option value="0" selected={state.beeLevel === 0}>
                Not Applicable
              </option>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((b) => (
                <option value={b} selected={state.beeLevel === b}>
                  BEE Level {b}
                </option>
              ))}
            </select>
            {state.error!.map((e: any) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "beeLevel" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>
              Kindly provide a detailed description of what you want
            </label>
            <div
              id="description"
              dangerouslySetInnerHTML={{ __html: state.description! }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "description" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Select Services You want</label>
            <select id="service" className="form-control" multiple={true}>
              {state.allServices?.map((s) => (
                <option
                  value={s._id}
                  selected={
                    state.services!.find((ss) => ss._id === s._id)
                      ? true
                      : false
                  }
                >
                  {s.name}
                </option>
              ))}
            </select>

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "service" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Due Date</label>
            <input
              type="text"
              id="datePicker2"
              className="form-control flatpickr flatpickr-input active"
              value={state.date}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "date"),
                  date: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "date" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Submissions Due Date</label>
            <input
              type="text"
              id="datePicker"
              className="form-control flatpickr flatpickr-input active"
              value={state.biddingEndDate}
              onChange={(e) =>
                setState({
                  error: state.error!.filter(
                    (e) => e.field !== "biddingEndDate"
                  ),
                  biddingEndDate: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "biddingEndDate" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Status</label>
            <select
              className="form-control"
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "status"),
                  status: e.target.value,
                })
              }
            >
              <option value="" selected={state.status === ""}>
                Choose...
              </option>
              <option value="bidding" selected={state.status === "bidding"}>
                Open For Submission
              </option>
              <option value="approved" selected={state.status === "approved"}>
                Approved (this will send email to customer )
              </option>
            </select>

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "status" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          {state.status !== "closed" ? (
            <button
              type="button"
              className="btn btn-primary mt-5"
              onClick={() => submit()}
            >
              <i data-feather="check-circle" />
            </button>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
