import { Index } from "./pages/index";
import { SocketProvider } from "./utils/hooks/context/socket-context-hooks";
import { UserProvider } from "./utils/hooks/context/user-context-hook";
import { initializeApp } from "firebase/app";
import { useEffect } from "react";
import feather from "feather-icons";
import { displayOwlCarousel } from "./utils/functions/jQuery-functions";
import { SettingsProvider } from "./utils/hooks/context/settings-context-hooks";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyB3E3PI_rNg5ZVjf3NxeFkUSWVHXelxeOk",
  authDomain: "enviroquotes1.firebaseapp.com",
  projectId: "enviroquotes1",
  storageBucket: "enviroquotes1.appspot.com",
  messagingSenderId: "702625830534",
  appId: "1:702625830534:web:f9ae3375a7ec15a656e307",
  measurementId: "G-LKWRWCNNN0",
});

window.Apex.grid = {
  borderColor: "#bfc9d4",
};
window.Apex.track = {
  background: "#bfc9d4",
};
window.Apex.tooltip = {
  theme: "dark",
};

declare global {
  interface Window {
    App: any;
    $: any;
    ApexCharts: any;
    Apex: any;
    checkall: any;
    flatpickr: any;
    FileUploadWithPreview: any;
    FlutterwaveCheckout: any;
    Quill: any;
    owlCarousel: any;
    select2: any;
    arr: any;
    webkitIDBTransaction: any;
    webkitIDBKeyRange: any;
  }
}

const App = () => {
  useEffect(() => {
    setInterval(() => {
      feather.replace();
      displayOwlCarousel();

      window.checkall("todoAll", "todochkbox");
      // $('[data-toggle="tooltip"]').tooltip();
    }, 100);
  }, []);

  return (
    <UserProvider>
      <SocketProvider>
        <SettingsProvider>
          <Index />
        </SettingsProvider>
      </SocketProvider>
    </UserProvider>
  );
};

export default App;
