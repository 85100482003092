import { useState, createContext } from "react";
import { withRouter } from "react-router-dom";
import { UserInterface } from "../../interfaces/user-interface";

export const defaultUser = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  profilePhoto: "",
  beeLevel: 0,
  isVerified: false,
  admin: false,
  deleted: false,
  blocked: false,
  token: "",
  role: undefined,
};

export const user = (): UserInterface => {
  const local = window.localStorage.getItem("@enviro-quote-user");
  return local ? JSON.parse(local) : defaultUser;
};

export const UserContext = createContext({
  userState: user,
  changeUserState: (newState: UserInterface, url = "", store = false) => {},
});

export const UserProvider = withRouter((props) => {
  const [state, setActualState] = useState(user());

  const setState = (newState: UserInterface, url = "", navigate = false) => {
    localStorage.setItem(
      "@enviro-quote-user",
      JSON.stringify({
        ...state,
        ...newState,
      })
    );

    setActualState((currentState) => ({
      ...currentState,
      ...newState,
    }));

    if (JSON.stringify(newState) === JSON.stringify(defaultUser)) {
      window.localStorage.removeItem("@enviro-quote-user-socket-id");
      window.localStorage.removeItem("@enviro-quote_local_stocks");

      if (url.length > 1 && navigate) {
        return !(
          url.includes("sign") ||
          url.includes("verify-email") ||
          url.includes("-password")
        )
          ? props.history.replace(`/sign-in?returnUrl=${url}`)
          : "";
      } else if (url === "" && navigate) {
        return props.history.replace("/");
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        userState: user,
        changeUserState: setState,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
});
