import { FC, useContext, useEffect, useState } from "react";
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { UserContext } from "../../utils/hooks/context/user-context-hook";
import { SocketContext } from "../../utils/hooks/context/socket-context-hooks";
import { redirect } from "../../utils/functions/redirect";
import { RoutePathInterface } from "../../utils/interfaces/shared-interface";
import { HeaderComponent } from "../../components/shared/header-component";
import { SidebarComponent } from "../../components/shared/side-bar-component";
import { DashboardPage } from "./dashboard/dashboard";
import { CountryPage } from "./set-up/country-page";
import { StatePage } from "./set-up/state-page";
import { CityPage } from "./set-up/city-page";
import { ManipulateBlogPage } from "./administrative/blog/manipulate-blog-page";
import { BlogDetailsPage } from "./administrative/blog/blog-details-page";
import { BlogPage } from "./administrative/blog/blog-page";
import { UserPage } from "./users/user-page";
import { UserDetailsPage } from "./users/user-details-page";
import { ManipulateServicePage } from "./set-up/services/main-services/manipulate-service-page";
import { ServicePage } from "./set-up/services/main-services/service-page";
import { ServiceDetailsPage } from "./set-up/services/main-services/service-details-page";
import { ManipulateQuotePage } from "./quote/manipulate-quote-page";
import { QuotePage } from "./quote/quote-page";
import { QuoteDetailsPage } from "./quote/quote-details-page";
import { SubmissionPage } from "./submission/submission-page";
import { SubmissionDetailsPage } from "./submission/submission-details-page";
import { ManipulateSubmissionPage } from "./submission/manipulate-submission-page";
import { SettingsPage } from "./settings/settings";
import { ManipulateSubServicePage } from "./set-up/services/sub-services/manipulate-sub-services-page";
import { SubServiceDetailsPage } from "./set-up/services/sub-services/sub-services-details-page";
import { SubServicePage } from "./set-up/services/sub-services/sub-services-page";
import { InvoicePage } from "./invoice/invoice-page";
import { InvoiceDetailsPage } from "./invoice/invoice-details-page";
import { OrganizationPage } from "./set-up/organization-page";
import { AnalyticsPage } from "./analytics/analytics-page";

export const AppRoute: FC = () => {
  const { path } = useRouteMatch(),
    { pathname } = useLocation(),
    { replace } = useHistory(),
    { userState } = useContext(UserContext),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    [state, setActualState] = useState<RoutePathInterface>({
      page: "",
      subPage: "",
    }),
    setState = (newState: RoutePathInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    };

  useEffect(() => {
    if (window.App) window.App.init();
  }, []);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <>
      <HeaderComponent page={state.page} />

      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <SidebarComponent callback={setState} />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <Switch>
              <Route
                exact
                path={`${path}/dashboard`}
                render={() => <DashboardPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/invoices`}
                render={() => <InvoicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/invoices/:id`}
                render={() => <InvoiceDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/analytics`}
                render={() => <AnalyticsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/country`}
                render={() => <CountryPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/state`}
                render={() => <StatePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/city`}
                render={() => <CityPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/organization`}
                render={() => <OrganizationPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/services`}
                render={() => <ServicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/services/add`}
                render={() => <ManipulateServicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/services/view-details/:id`}
                render={() => <ServiceDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/services/edit/:id`}
                render={() => <ManipulateServicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/sub-services`}
                render={() => <SubServicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/sub-services/add`}
                render={() => <ManipulateSubServicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/sub-services/view-details/:id`}
                render={() => <SubServiceDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/sub-services/edit/:id`}
                render={() => <ManipulateSubServicePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/quote`}
                render={() => <QuotePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/quote/add`}
                render={() => <ManipulateQuotePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/quote/view-details/:id`}
                render={() => <QuoteDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/quote/edit/:id`}
                render={() => <ManipulateQuotePage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/submission`}
                render={() => <SubmissionPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/submission/view-details/:id`}
                render={() => <SubmissionDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/submission/edit/:id`}
                render={() => <ManipulateSubmissionPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/submission/:quoteId/add`}
                render={() => <ManipulateSubmissionPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/blog`}
                render={() => <BlogPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/blog/add`}
                render={() => <ManipulateBlogPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/blog/view-details/:id`}
                render={() => <BlogDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/blog/edit/:id`}
                render={() => <ManipulateBlogPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/users`}
                render={() => <UserPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/users/:id`}
                render={() => <UserDetailsPage callback={setState} />}
              />

              <Route
                exact
                path={`${path}/settings`}
                render={() => <SettingsPage callback={setState} />}
              />

              <Redirect to="/page-not-found" exact />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};
