import { FC, useState, useContext, useEffect } from "react";
import { SignUpInterface } from "../../utils/interfaces/pages/auth/auth-interface";
import { UseUserService } from "../../utils/hooks/custom/user";
import { notify } from "../../utils/functions/notification";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  defaultUser,
  UserContext,
} from "../../utils/hooks/context/user-context-hook";
import { parse } from "query-string";

export const SignUpPage: FC = () => {
  const { changeUserState } = useContext(UserContext),
    { register } = UseUserService(),
    { push } = useHistory(),
    { search } = useLocation(),
    { returnUrl } = parse(search),
    [togglePassword, setTogglePassword] = useState(false),
    [state, setActualState] = useState<SignUpInterface>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      text: "Get Started!",
      checked: false,
      error: [],
    }),
    setState = (newState: SignUpInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    submit = () => {
      state.error = [];

      if (state.firstName!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "firstName",
          msg: "Your First Name is required",
        });

      if (state.lastName!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "lastName",
          msg: "Your Last Name is required",
        });

      if (state.email!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "email",
          msg: "Your Email is required",
        });
      else if (
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (state.password!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "password",
          msg: "Your Password is required",
        });
      // else if (
      //   !state.password?.match(
      //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~]).{8,}$/
      //   ) // eslint-disable-line
      // )
      //   state.error.push({
      //     field: "password",
      //     msg: "Your password is not strong enough",
      //   });

      if (!state.checked)
        state.error.push({
          field: "checkbox",
          msg: "Your you've to agree to the terms and conditions.",
        });

      if (state.error.length === 0 && state.text === "Get Started!") {
        setState({ text: "Processing..." });
        register(
          state.firstName!,
          state.lastName!,
          state.email!.trim(),
          state.password!
        )
          .then((res) => {
            if (res.type === "error") {
              setState({ text: "Get Started!" });

              notify(res.type, `${res.message}`);
            } else {
              changeUserState({ email: state.email });
              notify("success", `${res.message}`);

              push(`/confirm-email${returnUrl ? `?${returnUrl}` : ""}`);
            }
          })
          .catch((err) => {
            setState({ text: "Get Started!" });

            notify(err, `${err}`);
          });
      } else if (state.error.length > 0) setState(state);
    };

  useEffect(() => changeUserState(defaultUser), []);

  return (
    <div className="form">
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1 className="">Sign Up</h1>
                <p className="signup-link register">
                  Already have an account?{" "}
                  <Link to={`/sign-in${returnUrl ? `?${returnUrl}` : ""}`}>
                    sign in
                  </Link>
                </p>
                <form className="text-left">
                  <div className="form">
                    <div className="row">
                      <div className="col-md">
                        <div
                          id="username-field"
                          className="field-wrapper input"
                        >
                          <label htmlFor="firstName">First Name</label>
                          <i data-feather="user" />
                          <input
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={state.firstName}
                            onChange={(e) =>
                              setState({
                                error: state.error!.filter(
                                  (e) => e.field !== "firstName"
                                ),
                                firstName: e.target.value,
                              })
                            }
                          />

                          {state.error!.map((e) => (
                            <div
                              className="invalid-feedback"
                              style={{
                                display:
                                  e.field === "firstName" ? "block" : "none",
                              }}
                            >
                              {e.msg}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md">
                        <div
                          id="username-field"
                          className="field-wrapper input"
                        >
                          <label htmlFor="lastName">Last Name</label>
                          <i data-feather="user" />
                          <input
                            name="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={state.lastName}
                            onChange={(e) =>
                              setState({
                                error: state.error!.filter(
                                  (e) => e.field !== "lastName"
                                ),
                                lastName: e.target.value,
                              })
                            }
                          />

                          {state.error!.map((e) => (
                            <div
                              className="invalid-feedback"
                              style={{
                                display:
                                  e.field === "lastName" ? "block" : "none",
                              }}
                            >
                              {e.msg}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div id="email-field" className="field-wrapper input">
                      <label htmlFor="email">EMAIL</label>
                      <i data-feather="at-sign" />
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={state.email}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "email"
                            ),
                            email: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "email" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}
                    </div>

                    <div
                      id="password-field"
                      className="field-wrapper input mb-2"
                    >
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password">PASSWORD</label>
                      </div>
                      <i data-feather="lock" />
                      <input
                        id="password"
                        name="password"
                        autoComplete="off"
                        autoCapitalize="off"
                        type={!togglePassword ? "password" : "text"}
                        className="form-control"
                        placeholder="Password"
                        value={state.password}
                        onChange={(e) =>
                          setState({
                            error: state.error!.filter(
                              (e) => e.field !== "password"
                            ),
                            password: e.target.value,
                          })
                        }
                      />

                      {state.error!.map((e) => (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: e.field === "password" ? "block" : "none",
                          }}
                        >
                          {e.msg}
                        </div>
                      ))}

                      <div
                        id="toggle-password"
                        onClick={() => {
                          setTogglePassword(!togglePassword);
                        }}
                      >
                        <i data-feather="eye" />
                      </div>
                    </div>

                    <div className="field-wrapper terms_condition">
                      <div className="n-chk">
                        <label className="new-control new-checkbox checkbox-primary">
                          <input
                            type="checkbox"
                            className="new-control-input"
                            checked={state.checked}
                            onChange={(e) =>
                              setState({
                                error: state.error!.filter(
                                  (e) => e.field !== "checkbox"
                                ),
                                checked: !state.checked,
                              })
                            }
                          />
                          <span className="new-control-indicator"></span>
                          <span>
                            I agree to the{" "}
                            <a
                              target="_blank"
                              href="https://enviroquotes.co.za/terms-and-conditions"
                            >
                              terms and conditions
                            </a>
                          </span>
                        </label>
                        {state.error!.map((e) => (
                          <div
                            className="invalid-feedback"
                            style={{
                              display:
                                e.field === "checkbox" ? "block" : "none",
                            }}
                          >
                            {e.msg}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => submit()}
                        >
                          {state.text !== "Get Started!" ? (
                            <div className="spinner-grow text-info align-self-center">
                              {state.text}
                            </div>
                          ) : (
                            <>{state.text}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
