import { FC, useContext, useEffect, useState } from "react";
import { ServiceDetailsPageInterface } from "../../../../../utils/interfaces/pages/app/set-up/service-page-interface";
import { UserContext } from "../../../../../utils/hooks/context/user-context-hook";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SocketContext } from "../../../../../utils/hooks/context/socket-context-hooks";
import { loaded } from "../../../../../utils/functions/loaded-function";
import { getSocketId } from "../../../../../utils/functions/upt-function";
import { redirect } from "../../../../../utils/functions/redirect";
import { notify } from "../../../../../utils/functions/notification";

export const ServiceDetailsPage: FC<ServiceDetailsPageInterface> = ({
  callback,
}) => {
  const { userState } = useContext(UserContext),
    { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { id } = useParams<any>(),
    [state, setActualState] = useState<ServiceDetailsPageInterface>({
      loading: true,
      name: "",
      description: "",
      pictures: [],
    }),
    setState = (newState: ServiceDetailsPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get("service/id", userState().token!, null, `${id}`);
    };

  useEffect(() => {
    if (callback) callback({ page: "Category Details " });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.services?.canRead)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-service/${id}`) {
      const d = {
        ...state,
        ...res.data,
        loading: false,
      };

      setState(d);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <h4 className="">Category Details</h4>
            </div>
          </div>

          <h6 className="h text-capitalize mt-5">Name: {state.name}</h6>

          <h6 className="h text-capitalize mt-3">Cover</h6>
          <div className="owl-carousel owl-theme mt-3">
            {state.pictures!.map((p) => (
              <div className="item" style={{ width: "250px" }}>
                <div
                  style={{
                    height: 250,
                    width: 250,
                    backgroundImage: `url("${p}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            ))}
          </div>
          <hr />
          <h6 className="h text-capitalize">Description</h6>
          <div dangerouslySetInnerHTML={{ __html: state.description! }} />
        </>
      )}
    </div>
  );
};
