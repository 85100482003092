import { FC, useContext, useEffect, useState } from "react";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import {
  UserComponentInterface,
  UserPageInterface,
} from "../../../utils/interfaces/pages/app/users/user-page-interface";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { redirect } from "../../../utils/functions/redirect";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { UserComponent } from "../../../components/user-component";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";

const Modal: FC<any> = ({ state, action, getData, replace, pathname }) => (
  <ModalComponent title={state.text!} additionalClasses="modal-dialog-centered">
    {state.text === "Delete User" ||
    state.text === "Suspend User" ||
    state.text === "Activate User" ? (
      <ActionComponent
        actionFunction={() => action()}
        text={state.text.split(" ")[0]}
      />
    ) : (
      <UserComponent
        {...state.user}
        callback={(user?: UserComponentInterface) => {
          if (user) {
            redirect(replace, pathname);
          } else {
            getData();
          }
        }}
      />
    )}
  </ModalComponent>
);

export const UserPage: FC<UserPageInterface> = ({ callback }) => {
  const { replace } = useHistory(),
    { pathname } = useLocation(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { put } = useApiService(),
    { url } = useRouteMatch(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<UserPageInterface>({
      loading: true,
      data: [],
      pageNumber: 1,
      records: 0,
      total: 0,
      user: undefined,
      text: undefined,
      searchText: "",
      searchRole: "",
      timeOut: {},
    }),
    setState = (newState: UserPageInterface) =>
      setActualState((currentState) => ({ ...currentState, ...newState })),
    action = () => {
      closeModal();

      blockUi();
      put(
        `user/update/${state.user!._id!}`,
        userState().token!,
        {
          ...(typeof state.user!.blocked !== "undefined" &&
            (state.user!.blocked! === true ||
              state.user!.blocked! === false) && {
              blocked: state.user!.blocked,
            }),
          ...(typeof state.user!.deleted !== "undefined" &&
            (state.user!.deleted! === true ||
              state.user!.deleted! === false) && {
              deleted: state.user!.deleted,
            }),
        },
        null,
        false
      )
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();
            window.$("#modal").modal("show");

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            )
              redirect(replace, pathname);
          } else {
            notify("success", `${res.message}`);

            unBlockUi();
            getData();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    },
    getData = (pageNumber = 1) => {
      setState({ loading: true });

      const rules = [
        {
          field: "email",
          option: "cn",
          type: "string",
          data: state.searchText,
        },
        {
          field: "deleted",
          option: "eq",
          type: "boolean",
          data: false,
        },
      ];

      if (state.searchRole!.length > 0) {
        rules.push({
          field: state.searchRole!,
          option: "eq",
          type: "boolean",
          data: true,
        });
      }

      get(
        "user",
        userState().token!,
        {
          filter: true,
          sort: "desc",
          sortName: "createdAt",
          pageSize: 20,
          pageNumber,
          searchFilters: {
            searchOption: "AND",
            rules,
          },
        },
        null,
        false
      );
    };

  useEffect(() => {
    if (callback) callback({ page: "User" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.users?.canRead || userState().role?.admin)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-user`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.loading = false;

      setState(state);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <>
      {state.text ? (
        <Modal
          state={JSON.parse(JSON.stringify(state))}
          setState={setState}
          action={action}
          getData={getData}
          replace={replace}
          pathname={pathname}
        />
      ) : (
        <></>
      )}

      <div className="container pt-5 pb-5">
        <h4 className="h">
          Users
          <button
            type="button"
            className="btn btn-outline-secondary mb-2 ml-3"
            onClick={() => {
              state.searchText = "";

              setState(state);

              getData();
            }}
          >
            <i data-feather="refresh-cw" />
          </button>
          {/* {(!state.loading && role?.canCreate) || userState().role?.admin? ( */}
          <button
            type="button"
            className="btn btn-primary mb-2 ml-3"
            onClick={() => {
              setState({ text: undefined });
              setTimeout(() => {
                state.text = "Add User";

                state.user = {
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  businessAddress: "",
                  phoneNumbers: "",
                  services: [],
                  documents: [],
                };
                setState(state);

                setTimeout(() => {
                  window.$("#modal").modal("show");
                }, 100);
              }, 100);
            }}
          >
            <i data-feather="plus-circle" />
          </button>
        </h4>
        <div className="row mt-5">
          <div className="col-lg-8 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="search using email..."
              autoComplete="off"
              value={state.searchText}
              onChange={(e) => {
                if (typeof state.timeOut === "number") {
                  clearTimeout(state.timeOut);
                }
                state.searchText = e.target.value;

                state.timeOut = setTimeout(() => {
                  getData();
                }, 1000);

                setState(state);
              }}
            />
          </div>

          <div className="col-lg">
            <select
              className="form-control"
              value={state.searchRole}
              onChange={(e) => {
                state.searchRole = e.target.value;
                setState(state);

                state.timeOut = setTimeout(() => {
                  getData();
                }, 1000);
              }}
            >
              <option value="" selected={state.searchRole === ""}>
                All Users
              </option>
              {[
                { role: "Service Provider", value: "role.vendor" },
                { role: "Admin", value: "role.admin" },
              ].map((c) => (
                <option value={c.value} selected={c.value === state.searchRole}>
                  {c.role}
                </option>
              ))}
            </select>
          </div>
        </div>

        {state.loading ? (
          <div className="d-flex justify-content-center mx-5 mt-3">
            <div className="spinner-grow text-info align-self-center loader-lg" />
          </div>
        ) : (
          <>
            <div className="table-responsive mt-5">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Date Registered</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    {/* {userState().role?.admin? ( */}
                    <th className="text-center">Action</th>
                    {/* ) : (
                      <></>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {state.data!.length > 0 ? (
                    state.data!.map((u, i) => (
                      <tr>
                        <td className="text-center">
                          {i + 1 + 20 * state.pageNumber! - 20}
                        </td>
                        <td id="td">
                          {moment(new Date(u.createdAt!))
                            .tz("Africa/Maputo")
                            .format("MM/DD/YYYY, h:mm:ss a")}
                        </td>
                        <td id="td">
                          {u.firstName} {u.lastName}
                        </td>
                        <td id="td">{u.email}</td>
                        <td id="td">{u.phoneNumbers}</td>
                        <td id="td">{u.businessAddress}</td>
                        {/* {userState().role?.admin? ( */}
                        <td id="td" className="text-center">
                          <ul className="table-controls">
                            {/* {role?.canRead || userState().role?.admin? ( */}
                            <li>
                              <Link
                                to={`${url}/${u._id!.split("|")[0]}`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View Details"
                              >
                                <i data-feather="eye" />
                              </Link>
                            </li>
                            {/* ) : (
                                <></>
                              )} */}

                            {/* {role??.canUpdate || userState().role?.admin? (
                                <> */}
                            <li>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                onClick={() => {
                                  setState({ text: undefined });
                                  setTimeout(() => {
                                    state.text = "Edit User";
                                    state.user = u;

                                    setState(state);
                                    setTimeout(() => {
                                      window.$("#modal").modal("show");
                                    }, 500);
                                  }, 100);
                                }}
                              >
                                <i data-feather="edit" />
                              </a>
                            </li>
                            <li>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={u.blocked ? "Activate" : "Suspend"}
                                onClick={() => {
                                  setState({ text: undefined });
                                  setTimeout(() => {
                                    state.text = `${
                                      u.blocked ? "Activate" : "Suspend"
                                    } User`;
                                    state.user = {
                                      ...u,

                                      blocked: !u.blocked,
                                    };

                                    setState(state);
                                    setTimeout(() => {
                                      window.$("#modal").modal("show");
                                    }, 500);
                                  }, 100);
                                }}
                              >
                                <i
                                  data-feather={`${
                                    u.blocked ? "check" : "slash"
                                  }`}
                                />
                              </a>
                            </li>
                            {/* </>
                              ) : (
                                <></>
                              )} */}

                            {/* {role??.canDelete || userState().role?.admin? ( */}
                            <li>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => {
                                  setState({ text: undefined });
                                  setTimeout(() => {
                                    state.text = "Delete User";
                                    state.user = {
                                      ...u,
                                      deleted: true,
                                    };

                                    setState(state);
                                    setTimeout(() => {
                                      window.$("#modal").modal("show");
                                    }, 500);
                                  }, 100);
                                }}
                              >
                                <i data-feather="trash" />
                              </a>
                            </li>
                            {/* ) : (
                                <></>
                              )} */}
                          </ul>
                        </td>
                        {/* ) : (
                          <></>
                        )} */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td />
                      <td />
                      <td>
                        <div className="d-flex justify-content-center mx-5 mt-3">
                          <p className="h">No Record Found</p>
                        </div>
                      </td>
                      <td />
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="pagination-custom_outline d-none d-lg-block"
              style={{ justifyContent: "left" }}
            >
              <Pagination
                activePage={state.pageNumber!}
                itemsCountPerPage={20}
                totalItemsCount={state.records!}
                pageRangeDisplayed={10}
                onChange={(pageNumber: number) => getData(pageNumber)}
                prevPageText={<i data-feather="chevron-left" />}
                nextPageText={<i data-feather="chevron-right" />}
                firstPageText={<i data-feather="chevrons-left" />}
                lastPageText={<i data-feather="chevrons-right" />}
                itemClassFirst={"prev"}
                itemClassLast={"next"}
                itemClassNext={"next"}
                itemClassPrev={"prev"}
              />
            </div>
            <div
              className="pagination-custom_outline d-block d-lg-none"
              style={{ justifyContent: "left" }}
            >
              <Pagination
                activePage={state.pageNumber!}
                itemsCountPerPage={20}
                totalItemsCount={state.records!}
                pageRangeDisplayed={3}
                onChange={(pageNumber: number) => getData(pageNumber)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
