import { FC, useContext, useEffect, useState } from "react";
import { useApiService } from "../utils/hooks/custom/api-with-token-http-hook";
import { InvoiceComponentInterface } from "../utils/interfaces/pages/app/invoice/invoice-page-interface";
import { ApiResponseInterface } from "../utils/interfaces/shared-interface";
import { UserContext } from "../utils/hooks/context/user-context-hook";
import { closeModal, notify } from "../utils/functions/notification";

export const InvoiceComponent: FC<InvoiceComponentInterface> = ({
  _id,
  amount,
  discount,
  dueDate,
  description,
  callback,
}) => {
  const { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<InvoiceComponentInterface>({
      _id,
      discount,
      amount,
      dueDate,
      description,
      text: "Save",
      error: [],
    }),
    setState = (newState: InvoiceComponentInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    resolve = (res: ApiResponseInterface) => {
      if (res.type === "error") {
        setState({ text: "Save" });

        notify(res.type, `${res.message}`);

        if (
          res.message.includes("section has expired") ||
          res.message.toLowerCase().includes("access denied")
        ) {
          closeModal();
          setTimeout(() => {
            if (callback)
              callback({
                _id: state._id,
                discount: state.discount,
                dueDate: state.dueDate,
                description: state.description,
                amount: state.amount,
              });
          }, 100);
        }
      } else {
        notify(res.type, `${res.message}`);
        closeModal();
        if (callback) callback();
      }
    },
    submit = () => {
      state.error = [];

      if (state.dueDate!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "dueDate",
          msg: "Due date is required.",
        });

      if (document.getElementsByClassName("ql-editor")[0].innerHTML.length < 10)
        state.error!.push({
          field: "description",
          msg: "Description is required and must be at least i50 words.",
        });

      if (
        isNaN(parseFloat(state.amount!)) ||
        parseFloat(state.amount!) < 0.0000000000009
      )
        state.error.push({
          field: "amount",
          msg: "Amount is required.",
        });

      if (isNaN(parseFloat(state.discount!)))
        state.error.push({
          field: "discount",
          msg: "Discount is required.",
        });

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });

        const data = {
          dueDate: state.dueDate,
          description: `${
            document.getElementsByClassName("ql-editor").length > 0
              ? `${document.getElementsByClassName("ql-editor")[0].innerHTML}`
              : ""
          }`,
          discount: parseFloat(state.discount!),
          amount: parseFloat(state.amount!),
        };

        if (!_id)
          post(`invoice/add`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
        else
          put(`invoice/update/${_id}`, userState().token!, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              setState({ text: "Save" });

              notify(err, `${err}`);
            });
      } else if (state.error.length > 0) setState(state);
    };

  useEffect(() => {
    for (const e of document.getElementsByClassName("ql-toolbar")) e.remove();

    if (document.getElementById("description"))
      new window.Quill("#description", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Describe this service...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("datePicker"))
      window.flatpickr(document.getElementById("datePicker"), {
        defaultDate: "",
        onClose: (_: any, dateStr: string) => {
          setState({
            dueDate: dateStr,
          });
          document.getElementById("datePicker")?.blur();
        },
      });
  }, []);

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="text"
              className="form-control"
              name="amount"
              value={state.amount}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "amount"),
                  amount: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "amount" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Discount</label>
            <input
              type="text"
              className="form-control"
              name="discount"
              value={state.discount}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "discount"),
                  discount: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "discount" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Due Date</label>
            <input
              type="text"
              id="datePicker"
              className="form-control flatpickr flatpickr-input active"
              value={state.dueDate}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "dueDate"),
                  dueDate: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "endDate" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Service Description</label>
            <div
              id="description"
              dangerouslySetInnerHTML={{ __html: state.description! }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "description" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};
