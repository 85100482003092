import { FC, useContext, useEffect, useState } from "react";
import { SubmissionDetailsPageInterface } from "../../../utils/interfaces/pages/app/submission/submission-page-interface";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import { formatNumbers } from "../../../utils/functions/format-function";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { notify } from "../../../utils/functions/notification";

export const SubmissionDetailsPage: FC<SubmissionDetailsPageInterface> = ({
  callback,
}) => {
  const { userState } = useContext(UserContext),
    { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { id } = useParams<any>(),
    { put } = useApiService(),
    [state, setActualState] = useState<SubmissionDetailsPageInterface>({
      loading: true,
      userId: "",
      quoteId: "",
      minAmount: "",
      maxAmount: "",
      description: "",
      quote: undefined,
      approved: false,
      suspended: false,
    }),
    setState = (newState: SubmissionDetailsPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get("submission/id", userState().token!, null, `${id}`);
    },
    submit = () => {
      blockUi();

      put(`submission/update/${`${id}`}`, userState().token!, {
        suspended: false,
      })
        .then((res) => {
          unBlockUi();
          notify(res.type, `${res.message}`);
          if (res.type === "success")
            replace(`/app/quote/view-details/${state.quoteId}`);
        })
        .catch((err) => {
          unBlockUi();
          notify(err, `${err}`);
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Submission Details " });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.admin ||
        userState().role?.submissions?.canRead
      )
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
      getData();
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-submission/${id}`) {
      const d = {
        ...state,
        ...res.data,
        loading: false,
      };

      setState(d);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-11">
              <h4 className="mb-3">Submission Details</h4>
            </div>
            {!state.approved ? (
              <div className="col">
                <Link
                  to={`/app/submission/edit/${id!}`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                >
                  <i data-feather="edit" />
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
          {state.approved ? (
            <h6 className="h text-capitalize mt-3">
              Status:{" "}
              <span
                className="badge badge-success"
                style={{
                  width: "fit-content",
                }}
              >
                Approved
              </span>
            </h6>
          ) : (
            <></>
          )}
          <h6 className="h text-capitalize mt-3">
            Amount: R{formatNumbers(parseFloat(`${state.minAmount}`))} - R
            {formatNumbers(parseFloat(`${state.maxAmount}`))}
          </h6>
          <hr />
          <h6 className="h text-capitalize mt-3">Details Of Quote</h6>
          <div dangerouslySetInnerHTML={{ __html: state.description! }} />
          {state.suspended && userState()?.role?.admin ? (
            <button
              type="button"
              className="btn btn-primary mt-5"
              onClick={() => submit()}
            >
              Approve <i data-feather="check-circle" />
            </button>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
