import { FC, useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";
import { ModalComponent } from "../../../components/shared/modal-component";
import { notify } from "../../../utils/functions/notification";
import { formatDate } from "../../../utils/functions/format-function";

const Modal: FC<any> = ({ state }) => {
  return (
    <ModalComponent
      title="Analytics Info | Pages Visited"
      additionalClasses="modal-dialog-centered modal-lg"
    >
      <div className="container">
        <ul className="list">
          {state.analytics.pages.map((p: any) => (
            <li className="list-item">
              <a href={p.page} target="_blank" rel="noopener noreferrer">
                {p.page} visited {p.count} time(s)
              </a>
            </li>
          ))}
        </ul>
      </div>
    </ModalComponent>
  );
};

export const AnalyticsPage: FC<any> = ({ callback }) => {
  const { replace } = useHistory(),
    { pathname } = useLocation(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<any>({
      loading: true,
      data: [],
      pageNumber: 1,
      records: 0,
      total: 0,
      analytics: undefined,
      from: formatDate(new Date(new Date()).setDate(new Date().getDate() - 7)),
      to: formatDate(),
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getData = (pageNumber = 1) => {
      setState({ loading: true, analytics: undefined });

      let rules: any[] = [];

      if (state.from!.length > 0 && state.to!.length > 0)
        rules.push({
          field: "createdAt",
          type: "range",
          data: [
            {
              option: "gte",
              type: "date",
              data: `${formatDate(new Date(state.from!))}, 00:00:00`,
            },
            {
              option: "lte",
              type: "date",
              data: `${formatDate(new Date(state.to!))}, 23:59:59`,
            },
          ],
        });

      get("analytics", userState().token!, {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 20,
        pageNumber,
        searchFilters: {
          searchOption: "AND",
          rules,
        },
      });
    };

  useEffect(() => {
    if (callback) callback({ page: "Analytics" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (userState().role?.admin || userState().role?.dashboard)
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    if (document.getElementById("calendarRange"))
      window.flatpickr(document.getElementById("calendarRange"), {
        mode: "range",
        defaultDate: `${state.from} to ${state.to}`,
        onClose: (_: any, dateStr: string) => {
          if (dateStr.split("to")[0])
            state.from = dateStr.split("to")[0].replace(/ /g, "");

          if (dateStr.split("to")[1])
            state.to = dateStr.split("to")[1].replace(/ /g, "");

          setState(state);

          if (dateStr.split("to").length === 2) {
            document.getElementById("calendarRange")?.blur();
            getData();
          }
        },
      });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-analytics`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.loading = false;

      for (const el of document.getElementsByClassName("jvectormap-container"))
        el.remove();

      window.$("#world-map").vectorMap({
        map: "world_mill_en",
        backgroundColor: "#805dca",
        borderColor: "#818181",
        borderOpacity: 0.25,
        borderWidth: 1,
        color: "#f4f3f0",
        regionStyle: {
          initial: {
            fill: "#fff",
          },
        },
        markerStyle: {
          initial: {
            fill: "#F8E23B",
            stroke: "#383f47",
          },
        },
        markers: res.data.data.map((d: any) => {
          return {
            latLng: [d.geoIp.loc.split(",")[0], d.geoIp.loc.split(",")[1]],
            name: `${d.geoIp.city} ${d.geoIp.region}, ${d.geoIp.country} `,
          };
        }),
        enableZoom: true,
        hoverColor: "#060818",
        hoverOpacity: 0.7,
        normalizeFunction: "polynomial",
        scaleColors: ["#b6d6ff", "#005ace"],
        selectedColor: "#c9dfaf",
        selectedRegions: [],
        showTooltip: true,
      });
    }

    if (res.url.length > 0) setState(state);
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      {state.analytics ? (
        <Modal state={JSON.parse(JSON.stringify(state))} />
      ) : (
        <></>
      )}

      <h4 className="h">
        Analytics
        <button
          type="button"
          className="btn btn-outline-secondary mb-2 ml-3"
          onClick={() => getData()}
        >
          <i data-feather="refresh-cw" />
        </button>
      </h4>

      <input
        id="calendarRange"
        className="form-control flatpickr flatpickr-input col-lg-4"
        type="text"
        value={
          state.from!.length > 0 && state.to!.length > 0
            ? `${state.from} to ${state.to}`
            : ""
        }
        placeholder="Select a range of date"
      />

      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <></>
      )}

      <div className="widget-content widget-content-area">
        <div id="world-map" className="mt-3" style={{ height: 400 }}></div>
      </div>

      {state.loading ? (
        <></>
      ) : (
        <>
          <div className="table-responsive mt-3">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Date</th>
                  <th>IP</th>
                  <th>Location</th>
                  <th>Device</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data!.length > 0 ? (
                  state.data!.map((t: any, i: number) => (
                    <tr>
                      <td>{i + 1 + 20 * state.pageNumber! - 20}</td>
                      <td>
                        {moment(new Date(t.createdAt!))
                          .tz("Africa/Maputo")
                          .format("MM/DD/YYYY, h:mm:ss a")}
                      </td>
                      <td>{t.geoIp!.ip}</td>
                      <td>{`${t?.geoIp?.city} ${t?.geoIp?.region}, ${t?.geoIp?.country}`}</td>
                      <td>{`${t?.device?.device?.brand} ${t?.device?.device?.type}, running ${t?.device?.os?.name} OS ${t?.device?.os?.version}, with ${t?.device?.client?.name} ${t?.device?.client?.type} v${t?.device?.client?.version}.`}</td>

                      <td id="td" className="text-center">
                        <ul className="table-controls">
                          <li>
                            <a
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View Details"
                              onClick={() => {
                                setState({ text: undefined });
                                setTimeout(() => {
                                  state.text = "Analytics Details";
                                  state.analytics = t;

                                  setState(state);
                                  setTimeout(() => {
                                    window.$("#modal").modal("show");
                                  }, 500);
                                }, 100);
                              }}
                            >
                              <i data-feather="eye" />
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div
            className="pagination-custom_outline d-none d-lg-block"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={10}
              onChange={(pageNumber: number) => getData(pageNumber)}
              prevPageText={<i data-feather="chevron-left" />}
              nextPageText={<i data-feather="chevron-right" />}
              firstPageText={<i data-feather="chevrons-left" />}
              lastPageText={<i data-feather="chevrons-right" />}
              itemClassFirst={"prev"}
              itemClassLast={"next"}
              itemClassNext={"next"}
              itemClassPrev={"prev"}
            />
          </div>
          <div
            className="pagination-custom_outline d-block d-lg-none"
            style={{ justifyContent: "left" }}
          >
            <Pagination
              activePage={state.pageNumber!}
              itemsCountPerPage={20}
              totalItemsCount={state.records!}
              pageRangeDisplayed={3}
              onChange={(pageNumber: number) => getData(pageNumber)}
            />
          </div>
        </>
      )}
    </div>
  );
};
