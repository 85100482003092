import { FC, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { closeModal, notify } from "../../../utils/functions/notification";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { loaded } from "../../../utils/functions/loaded-function";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { redirect } from "../../../utils/functions/redirect";
import { getSocketId } from "../../../utils/functions/upt-function";
import { ModalComponent } from "../../../components/shared/modal-component";
import { ActionComponent } from "../../../components/shared/action-component";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import Pagination from "react-js-pagination";
import { OrganizationComponent } from "../../../components/organization-component";

const Modal: FC<any> = ({ state, setState, getData, action }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory();

  return (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      {state.text === "Delete Organization" ||
      state.text === "Suspend Organization" ||
      state.text === "Activate Organization" ? (
        <ActionComponent
          actionFunction={() => action()}
          text={state.text.split(" ")[0]}
        />
      ) : (
        <OrganizationComponent
          {...state.organization}
          callback={(organization?: any) => {
            if (organization) {
              redirect(replace, pathname);
            } else {
              setState({ loading: true });
              getData();
            }
          }}
        />
      )}
    </ModalComponent>
  );
};

export const OrganizationPage: FC<any> = ({ callback }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<any>({
      loading: true,
      data: [],
      countries: [],
      organization: undefined,
      text: undefined,
      searchText: "",
      pageNumber: 1,
      records: 0,
      total: 0,
      timeOut: {},
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({
        ...currentState,
        ...newState,
      }));
    },
    getData = (pageNumber: number = 1) => {
      setState({ loading: true, text: undefined });
      get(
        "organization",
        userState().token!,
        {
          filter: true,
          sort: "asc",
          sortName: "name",
          pageSize: 20,
          pageNumber,
          searchFilters: {
            searchOption: "AND",
            rules: [
              {
                field: "name",
                option: "cn",
                type: "string",
                data: state.searchText,
              },
              {
                field: "deleted",
                option: "eq",
                type: "boolean",
                data: false,
              },
            ],
          },
        },
        null,
        false
      );
    },
    action = () => {
      closeModal();

      blockUi();
      put(
        `organization/update/${state.organization!._id}`,
        userState().token!,
        {
          ...(typeof state.organization!.deleted !== "undefined" &&
            (state.organization!.deleted! === true ||
              state.organization!.deleted! === false) && {
              deleted: state.organization!.deleted,
            }),
        }
      )
        .then((res) => {
          if (res.type === "error") {
            notify(res.type, `${res.message}`);
            unBlockUi();

            window.$("#modal").modal("show");

            if (
              res.message.includes("section has expired") ||
              res.message.toLowerCase().includes("access denied")
            ) {
              closeModal();
              redirect(replace, pathname);
            }
          } else {
            notify("success", `${res.message}`);

            unBlockUi();
            getData();
          }
        })
        .catch((err) => {
          notify(err, `${err}`);

          unBlockUi();
        });
    };

  useEffect(() => {
    if (callback) callback({ page: "Organization" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.organizations?.canRead ||
        userState().role?.admin
      )
        getData();
      else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
      getData();
    });
  }, []);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-organization`) {
      state.data = res.data.data;
      state.pageNumber = res.data.pageNumber;
      state.records = res.data.records;
      state.total = res.data.total;
      state.countries = res.data.countries;
      state.loading = false;

      setState(state);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <>
      {state.text ? (
        <Modal
          state={JSON.parse(JSON.stringify(state))}
          setState={setState}
          getData={getData}
          action={action}
        />
      ) : (
        <></>
      )}

      <div className="container pt-5 pb-5">
        <h4 className="h">
          Organization
          <button
            type="button"
            className="btn btn-outline-secondary mb-2 ml-3"
            onClick={() => {
              state.searchText = "";

              setState(state);

              getData();
            }}
          >
            <i data-feather="refresh-cw" />
          </button>
          {!state.loading &&
          (userState().role?.admin ||
            userState().role?.organizations?.canCreate) ? (
            <button
              type="button"
              className="btn btn-primary mb-2 ml-3"
              onClick={() => {
                setState({ text: undefined });
                setTimeout(() => {
                  state.text = "Add";
                  state.organization = {
                    name: "",
                  };

                  setState(state);

                  setTimeout(() => {
                    window.$("#modal").modal("show");
                  }, 100);
                }, 100);
              }}
            >
              <i data-feather="plus-circle" />
            </button>
          ) : (
            <></>
          )}
        </h4>

        <input
          type="text"
          className="form-control"
          placeholder="search..."
          autoComplete="off"
          value={state.searchText}
          onChange={(e) => {
            if (typeof state.timeOut === "number") {
              clearTimeout(state.timeOut);
            }
            state.searchText = e.target.value;

            state.timeOut = setTimeout(() => {
              getData();
            }, 1000);

            setState(state);
          }}
        />

        {state.loading ? (
          <div className="d-flex justify-content-center mx-5 mt-3">
            <div className="spinner-grow text-info align-self-center loader-lg" />
          </div>
        ) : (
          <div className="table-responsive mt-5">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Name</th>
                  {userState().role?.organizations?.canUpdate ||
                  userState().role?.organizations?.canDelete ||
                  userState().role?.admin ? (
                    <th className="text-center">Action</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {state.data!.length > 0 ? (
                  state.data!.map((s: any, i: number) => (
                    <tr>
                      <td className="text-center">{i + 1}</td>

                      <td className="text-capitalize">{s.name}</td>
                      {userState().role?.organizations?.canUpdate ||
                      userState().role?.organizations?.canDelete ||
                      userState().role?.admin ? (
                        <td id="td" className="text-center">
                          <ul className="table-controls">
                            {userState().role?.organizations?.canUpdate ||
                            userState().role?.admin ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      setState({
                                        text: "Edit Organization",
                                        organization: s,
                                      });
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="edit" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}

                            {userState().role?.organizations?.canDelete ||
                            userState().role?.admin ? (
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => {
                                    setState({ text: undefined });
                                    setTimeout(() => {
                                      state.text = "Delete Organization";
                                      state.organization = {
                                        _id: s._id,
                                        deleted: true,
                                      };

                                      setState(state);
                                      setTimeout(() => {
                                        window.$("#modal").modal("show");
                                      }, 500);
                                    }, 100);
                                  }}
                                >
                                  <i data-feather="trash" />
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td>
                      <div className="d-flex justify-content-center mx-5 mt-3">
                        <p className="h">No Record Found</p>
                      </div>
                    </td>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div
          className="pagination-custom_outline d-none d-lg-block"
          style={{ justifyContent: "left" }}
        >
          <Pagination
            activePage={state.pageNumber!}
            itemsCountPerPage={20}
            totalItemsCount={state.records!}
            pageRangeDisplayed={10}
            onChange={(pageNumber: number) => getData(pageNumber)}
            prevPageText={<i data-feather="chevron-left" />}
            nextPageText={<i data-feather="chevron-right" />}
            firstPageText={<i data-feather="chevrons-left" />}
            lastPageText={<i data-feather="chevrons-right" />}
            itemClassFirst={"prev"}
            itemClassLast={"next"}
            itemClassNext={"next"}
            itemClassPrev={"prev"}
          />
        </div>
        <div
          className="pagination-custom_outline d-block d-lg-none"
          style={{ justifyContent: "left" }}
        >
          <Pagination
            activePage={state.pageNumber!}
            itemsCountPerPage={20}
            totalItemsCount={state.records!}
            pageRangeDisplayed={3}
            onChange={(pageNumber: number) => getData(pageNumber)}
          />
        </div>
      </div>
    </>
  );
};
