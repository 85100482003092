import { UserInterface } from "../interfaces/user-interface";
import { AxiosResponse } from "axios";
export const uPT = (response: AxiosResponse, state: UserInterface) => {
  const data = response.headers["x-auth-data"],
    l = localStorage.getItem("@enviro-quote-login-date"),
    newToken = data && data.length > 5 ? JSON.parse(data) : undefined;

  if (newToken) {
    const { token, date, user } = newToken;

    localStorage.setItem(
      "@enviro-quote-login-date",
      date !== l ? date : JSON.stringify(l)
    );

    if (user && user._id === state._id) {
      delete user.expi;
      delete user.iat;
      delete user.exp;

      localStorage.setItem(
        "@enviro-quote-user",
        JSON.stringify({
          ...state,
          ...user,
          token,
        })
      );
    }
  }
};

export const uPTSocket = (
  response: any,
  state: UserInterface,
  setState: Function
) => {
  const data = response["x-auth-data"],
    l = localStorage.getItem("@enviro-quote-login-date"),
    newToken = data && data.length > 5 ? JSON.parse(data) : undefined;

  if (newToken) {
    const { token, date, user } = newToken;

    localStorage.setItem(
      "@enviro-quote-login-date",
      date !== l ? date : JSON.stringify(l)
    );

    if (user && user._id === state._id) {
      delete user.expi;
      delete user.iat;
      delete user.exp;

      setState({
        ...state,
        ...user,
        token,
      });

      localStorage.setItem(
        "@enviro-quote-user",
        JSON.stringify({
          ...state,
          ...user,
          token,
        })
      );
    }
  }
};

export const getSocketId = () => {
  if (window.localStorage.getItem("@socket-id"))
    return window.localStorage.getItem("@socket-id")!;
  else {
    const id = `${Math.random()}-${Math.random()}`;
    window.localStorage.setItem("@socket-id", id);

    return id;
  }
};
