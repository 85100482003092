import { FC, useContext, useEffect, useState } from "react";
import { useApiService } from "../utils/hooks/custom/api-with-token-http-hook";
import { CountryComponentInterface } from "../utils/interfaces/pages/app/set-up/country-interface";
import { UserContext } from "../utils/hooks/context/user-context-hook";
import { ApiResponseInterface } from "../utils/interfaces/shared-interface";
import { closeModal, notify } from "../utils/functions/notification";
import { uploadFile } from "../utils/functions/firebase-file-upload";

export const CountryComponent: FC<CountryComponentInterface> = ({
  _id,
  name,
  timeZone,
  countryCode,
  currency,
  utc,
  slug,
  flag,
  callback,
}) => {
  const { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<CountryComponentInterface>({
      _id,
      name,
      timeZone,
      countryCode,
      currency,
      utc: `${utc}`,
      slug,
      flag,
      text: "Save",
      error: [],
    }),
    setState = (newState: CountryComponentInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    resolve = (res: ApiResponseInterface) => {
      if (res.type === "error") {
        setState({ text: "Save" });

        notify(res.type, `${res.message}`);

        if (
          res.message.includes("section has expired") ||
          res.message.toLowerCase().includes("access denied")
        ) {
          closeModal();
          setTimeout(() => {
            if (callback)
              callback({
                _id: state._id,
                name: state.name,
                timeZone: state.timeZone,
                countryCode: state.countryCode,
                utc: state.utc,
                slug: state.slug,
                flag: state.flag,
              });
          }, 100);
        }
      } else {
        notify(res.type, `${res.message}`);
        closeModal();
        if (callback) callback();
      }
    },
    submit = () => {
      state.error = [];

      if (
        state.name!.replace(/ /g, "").length === 0 ||
        !state.name!.match(/^[a-zA-Z0-9\s]*$/)
      )
        state.error.push({
          field: "name",
          msg: "Name is required and can only contain alphabet a - z and number 0 - 9",
        });

      if (state.currency!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "currency",
          msg: "Currency Sign is required.",
        });

      if (state.utc!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "utc",
          msg: "utc is required.",
        });

      if (state.timeZone!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "timeZone",
          msg: "TimeZone is required.",
        });

      if (state.slug!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "slug",
          msg: "Country Slug is required.",
        });

      if (state.countryCode!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "countryCode",
          msg: "Country is required.",
        });

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });

        const done = (flag?: string) => {
          const data = {
            name: state.name,
            timeZone: state.timeZone,
            countryCode: state.countryCode,
            currency: state.currency,
            utc: parseInt(`${state.utc}`),
            slug: state.slug,
            ...(flag && {
              flag,
            }),
          };

          if (!_id)
            post(`country/add`, userState().token!, data)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                setState({ text: "Save" });

                notify(err, `${err}`);
              });
          else
            put(`country/update/${_id}`, userState().token!, data)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                setState({ text: "Save" });

                notify(err, `${err}`);
              });
        };

        if (typeof state.flag === "string") done();
        else
          uploadFile(state.flag!, ({ type, data }) => {
            if (type === "success") {
              done(data!);
            } else if (type === "error") {
              setState({ text: "Save" });
              notify("error", "Unable to upload files");
            }
          });
      } else if (state.error.length > 0) setState(state);
    };

  useEffect(() => {
    if (document.getElementById("imagePreview"))
      new window.FileUploadWithPreview("imagePreview");
  }, []);

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={state.name}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "name"),
                  name: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "name" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Slug</label>
            <input
              type="text"
              className="form-control"
              name="slug"
              value={state.slug}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "slug"),
                  slug: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "slug" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Currency</label>
            <input
              type="text"
              className="form-control"
              name="currency"
              value={state.currency}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "currency"),
                  currency: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "currency" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>UTC (eg +1)</label>
            <input
              type="number"
              className="form-control"
              name="utc"
              value={state.utc}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "utc"),
                  utc: `${e.target.value}`,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "utc" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>TimeZone</label>
            <input
              type="text"
              className="form-control"
              name="timeZone"
              value={state.timeZone}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "timeZone"),
                  timeZone: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "timeZone" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>Country Code</label>
            <input
              type="text"
              className="form-control"
              name="countryCode"
              value={state.countryCode}
              onChange={(e) =>
                setState({
                  error: state.error!.filter((e) => e.field !== "countryCode"),
                  countryCode: e.target.value,
                })
              }
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "countryCode" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <div className="form-group statbox widget box box-shadow">
            {typeof state.flag === "string" && state.flag.length > 0 ? (
              <img
                src={state.flag}
                alt="country flag"
                className="img-fluid b-2 mb-3"
              />
            ) : (
              <></>
            )}

            <div
              id="imagePreview"
              className="custom-file-container"
              data-upload-id="imagePreview"
            >
              <label>
                Country Images (optional)
                <a
                  href="javascript:void(0)"
                  className="custom-file-container__image-clear btn btn-outline-success btn-sm ml-3"
                  title="Clear Image"
                >
                  <i data-feather="x-octagon" />
                </a>
              </label>
              <label className="custom-file-container__custom-file">
                <input
                  type="file"
                  accept="image/*"
                  className="custom-file-container__custom-file__custom-file-input"
                  onChange={(e) => {
                    if (e.target.files) state.flag = e.target.files[0];
                    setState(state);
                  }}
                />
                <span className="custom-file-container__custom-file__custom-file-control"></span>
              </label>
              <div className="custom-file-container__image-preview"></div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close" onClick={() => closeModal()}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => submit()}
        >
          <i data-feather="check-circle" />
          {state.text !== "Save" ? (
            <div className="spinner-grow text-info align-self-center c-spinner" />
          ) : (
            <></>
          )}
        </button>
      </div>
    </>
  );
};
