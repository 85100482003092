import { FC, useContext, useEffect, useState } from "react";
import {
  UserDetailsPageInterface,
  UserServicesComponentInterface,
} from "../../../utils/interfaces/pages/app/users/user-page-interface";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { OptionType } from "../../../utils/interfaces/roles-interface";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { redirect } from "../../../utils/functions/redirect";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { uploadFile } from "../../../utils/functions/firebase-file-upload";
import { notify } from "../../../utils/functions/notification";
import { ModalComponent } from "../../../components/shared/modal-component";
import {
  UserOrganizationComponent,
  UserServicesComponent,
} from "../../../components/user-component";

const crud = {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  Modal: FC<any> = ({ state, getData, replace, pathname }) => (
    <ModalComponent
      title={state.text!}
      additionalClasses="modal-dialog-centered"
    >
      {state.text === "User Services" ? (
        <UserServicesComponent
          _id={state._id}
          services={state.services}
          allServices={state.allServices}
          callback={(user?: UserServicesComponentInterface) => {
            if (user) redirect(replace, pathname);
            else getData();
          }}
        />
      ) : (
        <UserOrganizationComponent
          _id={state._id}
          organizations={state.organizations}
          allOrganizations={state.allOrganizations}
          callback={(data?: any) => {
            if (data) redirect(replace, pathname);
            else getData();
          }}
        />
      )}
    </ModalComponent>
  );

export const UserDetailsPage: FC<UserDetailsPageInterface> = ({ callback }) => {
  const { userState } = useContext(UserContext),
    { pathname } = useLocation(),
    { replace } = useHistory(),
    { put } = useApiService(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { id } = useParams<any>(),
    [state, setActualState] = useState<UserDetailsPageInterface>({
      loading: true,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      businessName: "",
      businessAddress: "",
      phoneNumbers: "",
      referenceNo: "",
      beeLevel: 0,
      documents: [],
      services: [],
      allServices: [],
      organizations: [],
      allOrganizations: [],
      profilePictureDoc: [],
      role: {
        dashboard: false,
        vendor: false,
        settings: false,
        admin: false,
        country: JSON.parse(JSON.stringify(crud)),
        state: JSON.parse(JSON.stringify(crud)),
        city: JSON.parse(JSON.stringify(crud)),
        categories: JSON.parse(JSON.stringify(crud)),
        services: JSON.parse(JSON.stringify(crud)),
        organizations: JSON.parse(JSON.stringify(crud)),
        quote: JSON.parse(JSON.stringify(crud)),
        submissions: JSON.parse(JSON.stringify(crud)),
        blog: JSON.parse(JSON.stringify(crud)),
        users: {
          canCreate: false,
          canRead: false,
          canUpdate: false,
          canDelete: false,
          canAssignRoles: false,
        },
      },
      text: undefined,
    }),
    setState = (newState: UserDetailsPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get("user/id", userState().token!, null, `${id}`);
    },
    Crud: FC<{ name: OptionType }> = ({ name }) => {
      return (
        <div className="mb-3">
          <p className="text-info text-capitalize">{name}</p>
          <div className="row">
            <div className="col-md-3 mb-4 mt-2">
              <p>Can View</p>
              <label className="switch s-icons s-outline  s-outline-primary">
                <input
                  type="checkbox"
                  checked={state.role![name]!.canRead}
                  onClick={() => {
                    state.role![name]!.canRead = !state.role![name]!.canRead;
                    setState(state);
                  }}
                />
                <span className="slider" />
              </label>
            </div>
            <div className="col-md-3 mb-4 mt-2">
              <p>Can Create</p>
              <label className="switch s-icons s-outline  s-outline-primary">
                <input
                  type="checkbox"
                  checked={state.role![name]!.canCreate}
                  onClick={() => {
                    state.role![name]!.canCreate =
                      !state.role![name]!.canCreate;
                    if (
                      state.role![name]!.canCreate ||
                      state.role![name]!.canUpdate ||
                      state.role![name]!.canDelete
                    )
                      state.role![name]!.canRead = true;
                    else state.role![name]!.canRead = false;

                    setState(state);
                  }}
                />
                <span className="slider" />
              </label>
            </div>
            <div className="col-md-3 mb-4 mt-2">
              <p>Can Update</p>
              <label className="switch s-icons s-outline  s-outline-primary">
                <input
                  type="checkbox"
                  checked={state.role![name]!.canUpdate}
                  onClick={() => {
                    state.role![name]!.canUpdate =
                      !state.role![name]!.canUpdate;
                    if (
                      state.role![name]!.canCreate ||
                      state.role![name]!.canUpdate ||
                      state.role![name]!.canDelete
                    )
                      state.role![name]!.canRead = true;
                    else state.role![name]!.canRead = false;

                    setState(state);
                  }}
                />
                <span className="slider" />
              </label>
            </div>
            <div className="col-md-3 mb-4 mt-2">
              <p>Can Delete</p>
              <label className="switch s-icons s-outline  s-outline-primary">
                <input
                  type="checkbox"
                  checked={state.role![name]!.canDelete}
                  onClick={() => {
                    state.role![name]!.canDelete =
                      !state.role![name]!.canDelete;
                    if (
                      state.role![name]!.canCreate ||
                      state.role![name]!.canUpdate ||
                      state.role![name]!.canDelete
                    )
                      state.role![name]!.canRead = true;
                    else state.role![name]!.canRead = false;

                    setState(state);
                  }}
                />
                <span className="slider" />
              </label>
            </div>
            {name === "users" ? (
              <div className="col-md-3 mb-4 mt-2">
                <p>Can Assign Roles</p>
                <label className="switch s-icons s-outline  s-outline-primary">
                  <input
                    type="checkbox"
                    checked={state.role![name]!.canAssignRoles}
                    onClick={() => {
                      state.role![name]!.canAssignRoles =
                        !state.role![name]!.canAssignRoles;
                      setState(state);
                    }}
                  />
                  <span className="slider" />
                </label>
              </div>
            ) : (
              <></>
            )}
          </div>
          <hr />
        </div>
      );
    };

  useEffect(() => {
    if (callback) callback({ page: "User Details " });

    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else {
        state.loading = true;
        setState(state);
        getData();
      }
    });
  }, [pathname]);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-user/${id}`) {
      const d = {
        ...state,
        ...res.data,
        role: { ...state.role, ...res.data.role },
        services: res.data.services
          ? res.data.services
              .map((s: any) => {
                return res.data.allServices.find(
                  (as: any) => as._id === s.serviceId
                );
              })
              .filter((s: any) => typeof s !== "undefined")
          : [],
        organizations: res.data.organizations
          ? res.data.organizations
              .map((o: any) => {
                return res.data.allOrganizations.find(
                  (ao: any) => ao._id === o.organizationId
                );
              })
              .filter((o: any) => typeof o !== "undefined")
          : [],
        loading: false,
        text: undefined,
      };
      console.log(d.services, d.organizations);

      setState(d);

      for (const e of document.getElementsByClassName("ql-toolbar")) e.remove();

      setTimeout(() => {
        if (document.getElementById("bio"))
          new window.Quill("#bio", {
            modules: {
              toolbar: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ align: [] }],

                ["clean"],
              ],
            },
            placeholder: "Summarize yourself...",
            theme: "snow", // or 'bubble'
          });
      }, 500);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  useEffect(() => {
    if (document.getElementById("imagePreview"))
      new window.FileUploadWithPreview("imagePreview");
  }, [state.loading]);

  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col">
          <h4 className="">
            User Details{" "}
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 ml-3"
              onClick={() => getData()}
            >
              <i data-feather="refresh-cw" />
            </button>
          </h4>
        </div>
      </div>
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          {state.text ? (
            <Modal
              state={JSON.parse(JSON.stringify(state))}
              setState={setState}
              getData={getData}
              replace={replace}
              pathname={pathname}
            />
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              {state.profilePhoto!.length > 1 ? (
                <div
                  style={{
                    height: 250,
                    width: 250,
                    borderRadius: "100%",
                    backgroundImage: `url("${state.profilePhoto}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              ) : (
                <div
                  style={{
                    height: 250,
                    width: 250,
                    borderRadius: "100%",
                    background: "#4361ee",

                    textAlign: "center",
                    color: "#060818",
                    fontSize: 140,
                    paddingTop: 30,
                    textTransform: "uppercase",
                  }}
                >
                  {`${state.firstName![0]}${state.lastName![0]}`}
                </div>
              )}
            </div>
            <div className="col-md-6">
              <div className="form-group mt-3">
                <div
                  id="imagePreview"
                  className="custom-file-container"
                  data-upload-id="imagePreview"
                >
                  <label>
                    Update Photo
                    <a
                      href="javascript:void(0)"
                      className="custom-file-container__image-clear btn btn-outline-success btn-sm ml-3"
                      title="Clear Image"
                    >
                      <i data-feather="x-octagon" />
                    </a>
                  </label>
                  <label className="custom-file-container__custom-file">
                    <input
                      type="file"
                      accept="image/*"
                      className="custom-file-container__custom-file__custom-file-input"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          blockUi();
                          uploadFile(e.target.files[0], ({ type, data }) => {
                            if (type === "success") {
                              put(
                                `user/update/${id}`,
                                userState().token!,
                                { profilePhoto: data },
                                null,
                                false
                              )
                                .then(() => {
                                  getData();
                                  unBlockUi();
                                })
                                .catch((err) => {
                                  unBlockUi();
                                  notify(err, `${err}`);
                                });
                            } else if (type === "error") {
                              unBlockUi();
                              notify("error", "Unable to upload files");
                            }
                          });
                        }
                      }}
                    />
                    <span className="custom-file-container__custom-file__custom-file-control"></span>
                  </label>
                  <div className="custom-file-container__image-preview"></div>
                </div>
              </div>
            </div>
          </div>

          <h6 className="h text-capitalize mt-5">
            Name: {state.firstName} {state.lastName}
          </h6>

          <h6 className="h text-lowercase mt-3">Email: {state.email}</h6>

          {state.referenceNo!.length > 0 ? (
            <h6 className="h mt-3">Reference NO: {state.referenceNo}</h6>
          ) : (
            <></>
          )}

          <hr />

          <div className="form-group mt-5">
            <label>
              Phone Numbers (you can add multiple separated by comma)
            </label>

            <input
              name="phoneNumbers"
              autoComplete="off"
              autoCapitalize="off"
              type="text"
              className="form-control"
              placeholder="Phone Numbers"
              value={state.phoneNumbers}
              onChange={(e) => {
                state.phoneNumbers = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group mt-5">
            <label>Business Name</label>

            <input
              name="businessName"
              autoComplete="off"
              autoCapitalize="off"
              type="text"
              className="form-control"
              placeholder="Business Name"
              value={state.businessName}
              onChange={(e) => {
                state.businessName = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group mt-5">
            <label>Business Address</label>

            <textarea
              name="businessAddress"
              autoComplete="off"
              autoCapitalize="off"
              className="form-control"
              placeholder="Business Address"
              value={state.businessAddress}
              onChange={(e) => {
                state.businessAddress = e.target.value;
                setState(state);
              }}
            />
          </div>

          <div className="form-group mt-5">
            <label>BEE Level</label>
            <select
              className="form-control"
              onChange={(e) =>
                setState({
                  beeLevel:
                    e.target.value.length > 0 ? parseInt(e.target.value) : 0,
                })
              }
            >
              <option value="0" selected={state.beeLevel === 0}>
                Not Applicable
              </option>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((b) => (
                <option value={b} selected={state.beeLevel === b}>
                  BEE Level {b}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mt-5">
            <label>Bio</label>

            <div id="bio" dangerouslySetInnerHTML={{ __html: state.bio! }} />

            <button
              type="button"
              className="btn btn-outline-primary mt-3 mb-5"
              onClick={() => {
                blockUi();
                put(
                  `user/update/${id}`,
                  userState().token!,
                  {
                    businessName: state.businessName,
                    businessAddress: state.businessAddress,
                    phoneNumbers: state.phoneNumbers,
                    beeLevel: parseInt(`${state.beeLevel}`),
                    bio: `${
                      document.getElementsByClassName("ql-editor").length > 0
                        ? `${
                            document.getElementsByClassName("ql-editor")[0]
                              .innerHTML
                          }`
                        : ""
                    }`,
                  },
                  null,
                  false
                )
                  .then((res) => {
                    unBlockUi();

                    getData();
                    notify(res.type, res.message);
                  })
                  .catch((err) => {
                    unBlockUi();
                    notify(err, `${err}`);
                  });
              }}
            >
              update <i data-feather="check-circle" />
            </button>
          </div>

          <h6 className="h text-capitalize mt-3">
            Services{" "}
            <button
              type="button"
              className="btn btn-primary mb-2 ml-3"
              onClick={() => {
                setState({ text: undefined });
                setTimeout(() => {
                  state.text = "User Services";
                  setState(state);
                  setTimeout(() => {
                    window.$("#modal").modal("show");
                  }, 100);
                }, 100);
              }}
            >
              <i data-feather="plus-circle" />
            </button>
          </h6>
          <div className="owl-carousel owl-theme mt-3">
            {state.services!.map((s) => (
              <div className="item" style={{ minWidth: "fit-content" }}>
                <span
                  className="badge badge-info text-capitalize"
                  style={{
                    width: "fit-content",
                  }}
                >
                  {s.name}
                </span>
              </div>
            ))}
          </div>

          <h6 className="h text-capitalize mt-3">
            Organizations{" "}
            <button
              type="button"
              className="btn btn-primary mb-2 ml-3"
              onClick={() => {
                setState({ text: undefined });
                setTimeout(() => {
                  state.text = "User Organizations";
                  setState(state);
                  setTimeout(() => {
                    window.$("#modal").modal("show");
                  }, 100);
                }, 100);
              }}
            >
              <i data-feather="plus-circle" />
            </button>
          </h6>
          <div className="owl-carousel owl-theme mt-3">
            {state.organizations!.map((o) => (
              <div className="item" style={{ minWidth: "fit-content" }}>
                <span
                  className="badge badge-primary text-capitalize"
                  style={{
                    width: "fit-content",
                  }}
                >
                  {o.name}
                </span>
              </div>
            ))}
          </div>

          {state.documents!.length > 0 ? (
            <>
              <h6 className="h text-capitalize mt-3">Documents</h6>
              <div className="owl-carousel owl-theme mt-3">
                {state.documents!.map((p) => (
                  <div className="item" style={{ width: "250px" }}>
                    <div
                      style={{
                        height: 250,
                        width: 250,
                        backgroundImage: `url("${p}")`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />

                    <a
                      href={p}
                      target="_blank"
                      className="btn btn-outline-primary mt-3"
                    >
                      <i data-feather="eye" />
                    </a>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {userState()._id === id || userState().role?.admin ? (
            <div className="form-group mt-5">
              <hr />
              <label>Change Password</label>

              <input
                id="password"
                name="password"
                autoComplete="off"
                autoCapitalize="off"
                type="text"
                className="form-control"
                placeholder="Password"
                value={state.password}
                onChange={(e) => {
                  state.password = e.target.value;
                  setState(state);
                }}
              />

              <button
                type="button"
                className="btn btn-outline-primary mt-3"
                onClick={() => {
                  blockUi();
                  put(
                    `user/change-password/${id}`,
                    userState().token!,
                    {
                      newPassword: state.password,
                    },
                    null,
                    false
                  )
                    .then((res) => {
                      unBlockUi();
                      notify(res.type, res.message);

                      if (res.type === "success") setState({ password: "" });
                    })
                    .catch((err) => {
                      unBlockUi();
                      notify(err, `${err}`);
                    });
                }}
              >
                <i data-feather="check-circle" />
              </button>
            </div>
          ) : (
            <></>
          )}

          {userState().role?.admin ? (
            <div className="form-group mt-5">
              <hr />
              <label>Change Reference NO</label>

              <input
                name="referenceNo"
                autoComplete="off"
                autoCapitalize="off"
                type="text"
                className="form-control"
                placeholder="Reference No"
                value={state.referenceNo}
                onChange={(e) => {
                  state.referenceNo = e.target.value;
                  setState(state);
                }}
              />

              <button
                type="button"
                className="btn btn-outline-primary mt-3"
                onClick={() => {
                  blockUi();
                  put(
                    `user/update/${id}`,
                    userState().token!,
                    {
                      referenceNo: state.referenceNo?.replace(/ /g, ""),
                    },
                    null,
                    false
                  )
                    .then((res) => {
                      unBlockUi();
                      notify(res.type, res.message);
                    })
                    .catch((err) => {
                      unBlockUi();
                      notify(err, `${err}`);
                    });
                }}
              >
                <i data-feather="check-circle" />
              </button>
            </div>
          ) : (
            <></>
          )}

          {userState().role?.admin ? (
            <>
              <hr />
              <h3 className="h mb-5">Roles</h3>
              <div className="mb-3">
                <p className="text-info">Admin</p>
                <label className="switch s-icons s-outline  s-outline-primary">
                  <input
                    type="checkbox"
                    checked={state.role!.admin}
                    onClick={() => {
                      state.role!.admin = !state.role!.admin;
                      setState(state);
                    }}
                  />
                  <span className="slider" />
                </label>
                <hr />
              </div>
              {!state.role?.admin ? (
                <>
                  <div className="mb-3">
                    <p className="text-info">Service Provider</p>
                    {state.businessName!.replace(/ /g, "").length > 1 &&
                    state.businessAddress!.replace(/ /g, "").length > 1 &&
                    state.phoneNumbers!.replace(/ /g, "").length > 1 ? (
                      <label className="switch s-icons s-outline  s-outline-primary">
                        <input
                          type="checkbox"
                          checked={state.role!.vendor}
                          onClick={() => {
                            state.role!.vendor = !state.role!.vendor;
                            setState(state);
                          }}
                        />
                        <span className="slider" />
                      </label>
                    ) : (
                      <p className="invalid-feedback">
                        Only users with Business Name, Business Address, and
                        Phone Numbers can become a service provider.
                      </p>
                    )}
                    <hr />
                  </div>
                  <div className="mb-3">
                    <p className="text-info">Settings</p>
                    <label className="switch s-icons s-outline  s-outline-primary">
                      <input
                        type="checkbox"
                        checked={state.role!.settings}
                        onClick={() => {
                          state.role!.settings = !state.role!.settings;
                          setState(state);
                        }}
                      />
                      <span className="slider" />
                    </label>
                    <hr />
                  </div>
                  <div className="mb-3">
                    <p className="text-info">Can View Dashboard</p>
                    <label className="switch s-icons s-outline  s-outline-primary">
                      <input
                        type="checkbox"
                        checked={state.role!.dashboard}
                        onClick={() => {
                          state.role!.dashboard = !state.role!.dashboard;
                          setState(state);
                        }}
                      />
                      <span className="slider" />
                    </label>
                    <hr />
                  </div>
                  <Crud name="country" />
                  <Crud name="state" />
                  <Crud name="city" />
                  <Crud name="categories" />
                  <Crud name="services" />
                  <Crud name="organizations" />
                  <Crud name="quote" />
                  <Crud name="submissions" />
                  <Crud name="blog" />
                  <Crud name="users" />
                </>
              ) : (
                <></>
              )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  blockUi();
                  put(
                    `user/update/${id}`,
                    userState().token!,
                    { role: state.role },
                    null,
                    false
                  )
                    .then((res) => {
                      unBlockUi();
                      if (res.type === "error") {
                        notify(res.type, res.message);
                      } else getData();
                    })
                    .catch((err) => {
                      unBlockUi();
                      notify(err, `${err}`);
                    });
                }}
              >
                <i data-feather="check-circle" />
              </button>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
