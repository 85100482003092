import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ManipulateSubmissionPageInterface } from "../../../utils/interfaces/pages/app/submission/submission-page-interface";
import { SocketContext } from "../../../utils/hooks/context/socket-context-hooks";
import { useApiService } from "../../../utils/hooks/custom/api-with-token-http-hook";
import { UserContext } from "../../../utils/hooks/context/user-context-hook";
import { blockUi, unBlockUi } from "../../../utils/functions/block-ui";
import { ApiResponseInterface } from "../../../utils/interfaces/shared-interface";
import { notify } from "../../../utils/functions/notification";
import { redirect } from "../../../utils/functions/redirect";
import { loaded } from "../../../utils/functions/loaded-function";
import { getSocketId } from "../../../utils/functions/upt-function";
import { formatNumbers } from "../../../utils/functions/format-function";

export const ManipulateSubmissionPage: FC<
  ManipulateSubmissionPageInterface
> = ({ callback }) => {
  const { pathname } = useLocation(),
    { replace } = useHistory(),
    { socketError, setSocketState, get, res } = useContext(SocketContext),
    { quoteId, id } = useParams<any>(),
    { post, put } = useApiService(),
    { userState } = useContext(UserContext),
    [state, setActualState] = useState<ManipulateSubmissionPageInterface>({
      loading: true,
      userId: "",
      minAmount: "0",
      maxAmount: "0",
      description: "",
      text: "Save",
      error: [],
      charges: undefined,
    }),
    setState = (newState: ManipulateSubmissionPageInterface) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      get(
        id ? "submission/id" : "settings/id",
        userState().token!,
        null,
        id ? id : "1"
      );
    },
    submit = () => {
      state.error = [];

      if (
        state.minAmount!.replace(/ /g, "").length === 0 &&
        isNaN(parseFloat(state.minAmount!))
      )
        state.error!.push({
          field: "minAmount",
          msg: "You minimum range is required",
        });

      if (
        state.maxAmount!.replace(/ /g, "").length === 0 &&
        isNaN(parseFloat(state.maxAmount!))
      )
        state.error!.push({
          field: "maxAmount",
          msg: "You maximum range is required",
        });

      if (document.getElementsByClassName("ql-editor")[0].innerHTML.length < 10)
        state.error!.push({
          field: "description",
          msg: "Description is required.",
        });

      setState(state);

      if (state.error.length === 0 && state.text === "Save") {
        setState({ text: "Processing..." });
        blockUi();

        const done = (res: ApiResponseInterface, id: string) => {
          unBlockUi();
          if (res.type === "error") {
            setState({ text: "Save" });

            notify(res.type, `${res.message}`);

            if (
              res?.message?.includes("section has expired") ||
              res?.message?.toLowerCase().includes("access denied")
            ) {
              redirect(replace, pathname);
            }
          } else {
            setState({ text: "Save" });
            notify(res.type, `${res.message}`);
            replace(`/app/quote/view-details/${id}`);
          }
        };

        const data = {
          ...(!id && {
            userId: userState()._id,
          }),
          quoteId,
          minAmount: parseFloat(state.minAmount!),
          maxAmount: parseFloat(state.maxAmount!),
          description: `${
            document.getElementsByClassName("ql-editor").length > 0
              ? `${document.getElementsByClassName("ql-editor")[0].innerHTML}`
              : ""
          }`,
        };

        if (id)
          put(`submission/update/${`${id}`}`, userState().token!, data)
            .then((res) => {
              done(res, state.quoteId!);
            })
            .catch((err) => {
              setState({ text: "Save" });
              unBlockUi();
              notify(err, `${err}`);
            });
        else
          post(`submission/add`, userState().token!, data)
            .then((res) => {
              done(res, quoteId);
            })
            .catch((err) => {
              setState({ text: "Save" });
              unBlockUi();
              notify(err, `${err}`);
            });
      }
    };

  useEffect(() => {
    if (callback) callback({ page: "Add Submission" });
    loaded(() => {
      if (userState()._id?.length == 0) redirect(replace, pathname);
      else if (
        userState().role?.admin ||
        (!id && userState().role?.submissions?.canCreate) ||
        (id && userState().role?.submissions?.canUpdate)
      ) {
        getData();
      } else {
        notify(
          "info",
          "You don't have the necessary permission to access the page"
        );
        replace(`/app/users/${userState()?._id}`);
      }
    });
  }, []);

  useEffect(() => {
    for (const e of document.getElementsByClassName("ql-toolbar")) e.remove();

    if (document.getElementById("description"))
      new window.Quill("#description", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blocksubmission"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Describe this submission...",
        theme: "snow", // or 'bubble'
      });
  }, [state.loading]);

  useEffect(() => {
    if (res.url === `${userState()._id}-${getSocketId()}-submission/${id}`) {
      setState({
        ...state,
        ...res.data,
        minAmount: `${res.data.minAmount}`,
        maxAmount: `${res.data.maxAmount}`,
        loading: false,
      });
    }

    if (res.url === `${userState()._id}-${getSocketId()}-settings/1`) {
      setState({
        charges: res.data,
        loading: false,
      });
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
      redirect(replace, pathname);
    }
  }, [socketError]);

  return (
    <div className="container pt-5 pb-5">
      <h4 className="h">{id ? "Edit" : "Add"} Submission</h4>
      {state.loading ? (
        <div className="d-flex justify-content-center mx-5 mt-3">
          <div className="spinner-grow text-info align-self-center loader-lg" />
        </div>
      ) : (
        <>
          <div className="form-group">
            <label>Whats Your Price Range</label>
            <div className="row">
              <div className="col">
                <label>Min Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={state.minAmount}
                  disabled={state.userId !== userState()._id && id}
                  onChange={(e) => {
                    state.error = state.error!.filter(
                      (e) => e.field !== "minAmount"
                    );
                    state.minAmount = e.target.value;
                    setState(state);
                  }}
                />
                {state.error!.map((e) => (
                  <div
                    className="invalid-feedback"
                    style={{
                      display: e.field === "minAmount" ? "block" : "none",
                    }}
                  >
                    {e.msg}
                  </div>
                ))}
              </div>
              <div className="col">
                <label>Max Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={state.maxAmount}
                  disabled={state.userId !== userState()._id && id}
                  onChange={(e) => {
                    state.error = state.error!.filter(
                      (e) => e.field !== "maxAmount"
                    );
                    state.maxAmount = e.target.value;
                    setState(state);
                  }}
                />
                {state.error!.map((e) => (
                  <div
                    className="invalid-feedback"
                    style={{
                      display: e.field === "maxAmount" ? "block" : "none",
                    }}
                  >
                    {e.msg}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <p className="text-danger">
            If you're awarded this quote your charges will be R
            {formatNumbers(
              state.charges.charge +
                (state.charges.percent / 100) * parseFloat(state.minAmount!)
            )}{" "}
            - R
            {formatNumbers(
              state.charges.charge +
                (state.charges.percent / 100) * parseFloat(state.maxAmount!)
            )}
          </p>

          <div className="form-group">
            <label>Kindly provide a detailed description of you quote</label>
            <div
              id="description"
              dangerouslySetInnerHTML={{ __html: state.description! }}
            />

            {state.error!.map((e) => (
              <div
                className="invalid-feedback"
                style={{
                  display: e.field === "description" ? "block" : "none",
                }}
              >
                {e.msg}
              </div>
            ))}
          </div>

          <button
            type="button"
            className="btn btn-primary mt-5"
            onClick={() => submit()}
          >
            <i data-feather="check-circle" />
          </button>
        </>
      )}
    </div>
  );
};
