import { Link, useParams } from "react-router-dom";
import { FC, useContext } from "react";
import { HeaderComponentInterface } from "../../utils/interfaces/components/header-component-interface";
import { UserContext } from "../../utils/hooks/context/user-context-hook";

export const HeaderComponent: FC<HeaderComponentInterface> = ({ page }) => {
  const { businessId } = useParams<any>(),
    { userState } = useContext(UserContext);
  return (
    <>
      <div className="header-container fixed-top">
        <header className="header navbar navbar-expand-sm">
          <ul className="navbar-item theme-brand flex-row  text-center">
            <li className="nav-item theme-logo">
              <a>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/logo-plain.png`}
                  style={{ width: 45, height: 45 }}
                  // src="/assets/img/90x90.jpg"
                  className="navbar-logo"
                  alt="logo"
                />
              </a>
            </li>
            <li className="nav-item theme-text">
              <a className="nav-link">Enviro Quotes</a>
            </li>
          </ul>

          <ul className="navbar-item flex-row ml-md-auto">
            <li className="nav-item mr-4">
              <Link to={`/app/users/${userState()._id}`}>
                <i data-feather="user" /> Profile
              </Link>
            </li>

            <li className="nav-item mr-4">
              <Link to={`/sign-in`}>
                <i data-feather="log-out" /> Sign Out
              </Link>
            </li>
          </ul>
        </header>
      </div>

      <div className="sub-header-container">
        <header className="header navbar navbar-expand-sm">
          <a href="#" className="sidebarCollapse" data-placement="bottom">
            <i data-feather="menu" />
          </a>

          <ul className="navbar-nav flex-row">
            <li>
              <div className="page-header">
                <nav className="breadcrumb-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-capitalize d-block">
                      <a href="#">{page}</a>
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      <span>{page?.toUpperCase()}</span>
                    </li> */}
                  </ol>
                </nav>
              </div>
            </li>
          </ul>
        </header>
      </div>
    </>
  );
};
