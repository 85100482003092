const $ = window.$;

export const displayCharts = (data: any, docId: string) => {
  if (
    JSON.stringify(data) !== "{}" &&
    document.getElementsByClassName("apexcharts-canvas").length === 0
  ) {
    if (window.ApexCharts) {
      new window.ApexCharts(document.getElementById(docId), data).render();
    } else {
      displayCharts(data, docId);
    }
  }
};

export function treeView() {
  const toggler = document.getElementsByClassName("caret");

  for (let i = 0; i < toggler.length; i++) {
    const t = toggler[i];
    if (t) {
      t.addEventListener("click", function () {
        t.parentElement?.querySelector(".nested")?.classList.toggle("active");

        t.classList.toggle("caret-down");
      });
    }
  }

  // Custom 2

  const folder = $(".file-tree li.file-tree-folder"),
    file = $(".file-tree li");

  folder.on("click", function (a: Event) {
    $(a.target)
      .children("ul")
      .slideToggle(400, function () {
        $(a.target).toggleClass("open");
        // $(a.target).parent("li").toggleClass("open");
      });
    a.stopPropagation();
  });

  file.on("click", function (b: Event) {
    b.stopPropagation();
  });
}

export function groupedSizes() {
  $("#simple_faq .collapse")
    .on("shown.bs.collapse", function (e: Event) {
      $(e.target)
        .parent()
        .find(".icons")
        .html(
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>'
        );
    })
    .on("hidden.bs.collapse", function (e: Event) {
      $(e.target)
        .parent()
        .find(".icons")
        .html(
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>'
        );
    });
}

export const getQuillContent = (html: string) =>
  html.replace(
    `</p></div><div class="ql-clipboard" contenteditable="true" tabindex="-1"></div><div class="ql-tooltip ql-hidden"><a class="ql-preview" target="_blank" href="about:blank"></a><input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL"><a class="ql-action"></a><a class="ql-remove"></a>`,
    ""
  );

export const displayOwlCarousel = (options = {}) => {
  $(".owl-carousel").owlCarousel({
    margin: 10,
    nav: true,
    autoWidth: true,
    dotsEach: true,
    items: 4,
    ...options,
  });

  const dots = document.getElementsByClassName("owl-dots");

  for (const d of dots)
    if (d.classList.contains("disabled")) d.classList.remove("disabled");
};

export const displaySelect = () => {
  $(".tagging").select2({
    tags: false,
  });
};
