import { formatNumbers } from "./format-function";

export const chartData = {
  chart: {
    height: 350,
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  series: [],
  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: "",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val: any) {
        return formatNumbers(parseInt(val));
      },
    },
  },
};

export const chartData2 = {
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  series: [],

  xaxis: {
    type: "datetime",
    categories: [],
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
};
